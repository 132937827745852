import {
	useQuery,
	gql,
	MutationTuple,
	useMutation,
	QueryResult,
	QueryHookOptions,
} from '@apollo/client';
import {
	CustomersScreen_GetUsers,
	CustomersScreen_GetUsersVariables,
	CustomersScreen_UpdateUser,
	CustomersScreen_UpdateUserVariables,
} from '@app/codegen';

// Get Users
export const CUSTOMERS_SCREEN_GET_USERS = gql`
	query CustomersScreen_GetUsers(
		$where: UserFilterInput
		$order: [UserSortInput!]
		$first: Int
		$last: Int
		$after: String
		$input: GetUsersInput
		$before: String
	) {
		users(
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			input: $input
			before: $before
		) {
			nodes {
				id
				firstName
				lastName
				email
				createdAt
				status
				phone
				accounts {
					id
				}
			}
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
			totalCount
		}
	}
`;

export type GetUsersQueryProps = QueryHookOptions<
	CustomersScreen_GetUsers,
	CustomersScreen_GetUsersVariables
>;

export type UsersQueryResult = QueryResult<
	CustomersScreen_GetUsers,
	CustomersScreen_GetUsersVariables
>;

export function useCustomersScreenUsersQuery(props: GetUsersQueryProps): UsersQueryResult {
	return useQuery<CustomersScreen_GetUsers, CustomersScreen_GetUsersVariables>(
		CUSTOMERS_SCREEN_GET_USERS,
		{
			...props,
			fetchPolicy: 'no-cache',
		}
	);
}

// Update User
export const CUSTOMERS_SCREEN_UPDATE_USER = gql`
	mutation CustomersScreen_UpdateUser($input: UpdateUserInput!) {
		updateUser(input: $input) {
			success
			errorMessage
		}
	}
`;

export type UserUpdateResult = MutationTuple<
	CustomersScreen_UpdateUser,
	CustomersScreen_UpdateUserVariables
>;

export function useCustomersScreenUserUpdateMutation(): UserUpdateResult {
	return useMutation<CustomersScreen_UpdateUser, CustomersScreen_UpdateUserVariables>(
		CUSTOMERS_SCREEN_UPDATE_USER
	);
}

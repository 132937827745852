import { gql, useMutation, useQuery } from '@apollo/client';
import {
	AccountStage,
	DocumentFilterInput,
	DocumentSortInput,
	DocumentStage,
	DocumentStatus,
	DocumentType,
	DocuSignTemplateType,
	EcsGetUserDetails_user_accounts as UserAccountDetails,
	EditAccountScreenGetAccountDetails_account as AccountDetails,
	EditFundingScreen_GetFunding_funding as FundingDetails,
	FileTemplateType,
	FundingDirection,
	FundingType,
	OrganismsDcGetDocuments,
	OrganismsDcGetDocumentsVariables,
	OrganismsDcGetDocuments_documents_nodes as DocumentNode,
	OrganismsDcSendDocument,
	OrganismsDcSendDocumentVariables,
	OrganismsDcUpdateDocumentStatus,
	OrganismsDcUpdateDocumentStatusVariables,
	RolloverType,
	SortEnumType,
} from '@app/codegen';
import { EDIT_FUNDING_SCREEN_GET_FUNDING } from '@app/screens';
import {
	CustomCard,
	CustomDataTable,
	DangerAssetSVG,
	ITrustAction,
	ITrustColumn,
	useTableState,
} from '@app/shared/components';
import {
	accountStageTypeHumanizer,
	documentTemplateTypeHumanizer,
	isFormEmpty,
} from '@app/shared/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	AppAlert,
	AppIcon,
	AppSelector,
	humanize,
	localDateTimeFormat,
	useAppState,
	useAppTheme,
} from '@itrustcapital/ui';
import { Button, Input, Text } from '@ui-kitten/components';
import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import Toast from 'react-native-toast-message';
import * as yup from 'yup';

import { CreateDocument } from './CreateDocument';
import { ViewDocument } from './ViewDocument';

export type DocumentsRef = {
	refetch: () => void;
	called: boolean;
};

export const ORGANISMS_DC_GET_DOCUMENTS = gql`
	query OrganismsDcGetDocuments(
		$where: DocumentFilterInput
		$order: [DocumentSortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
		$accountId: Int
	) {
		account(where: { id: { eq: $accountId } }) {
			id
			stage
		}
		documents(
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			before: $before
		) {
			nodes {
				id
				fileTemplate
				docuSignTemplate
				type
				stage
				status
				createdAt
				sentAt
				files {
					id
					url
					name
				}
				signedAt
				name
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
			totalCount
		}
	}
`;

export const ORGANISMS_DC_UPDATE_DOCUMENT_STATUS = gql`
	mutation OrganismsDcUpdateDocumentStatus($input: UpdateDocumentInput!) {
		updateDocument(input: $input) {
			success
			errorMessage
		}
	}
`;

export const ORGANISMS_DC_SEND_DOCUMENT = gql`
	mutation OrganismsDcSendDocument($input: SendDocumentInput!) {
		sendDocument(input: $input) {
			success
			errorMessage
			data {
				id
				lockedUntil
			}
		}
	}
`;

export interface DocumentsProps {
	style?: StyleProp<ViewStyle>;
	userId?: number; // required for "All Accounts option"
	accountDetails?: AccountDetails | UserAccountDetails; // required for account detail screen
	fundingDetails?: FundingDetails; // required for funding detail screen
}

const schema = yup
	.object({
		search: yup.string().default(''),
		status: yup
			.mixed<DocumentStatus>()
			.oneOf([...Object.values(DocumentStatus)])
			.default(undefined),
		currentAccountOnly: yup
			.mixed<'All' | 'Current Account' | 'Current Funding'>()
			.default('All'),
		template: yup
			.mixed<FileTemplateType | DocuSignTemplateType>()
			.oneOf([...Object.values(FileTemplateType), ...Object.values(DocuSignTemplateType)])
			.default(undefined),
	})
	.defined();

export type DocumentsFilterForm = yup.TypeOf<typeof schema>;

export function Documents(props: DocumentsProps) {
	const styles = useCustomStyles();

	const fundingWhere = props.fundingDetails?.id && {
		fundingId: {
			eq: props.fundingDetails?.id,
		},
	};
	const accountWhere = props.accountDetails?.id && {
		accountId: {
			eq: props.accountDetails?.id,
		},
	};
	const userWhere = props.userId
		? {
				userId: {
					eq: props.userId,
				},
		  }
		: undefined;

	const defaultWhere: DocumentFilterInput | undefined = fundingWhere || accountWhere || userWhere;

	const selectedRow = useAppState<DocumentNode | null>(null);
	const createModalVisible = useAppState(false);
	const viewModalVisible = useAppState(false);
	const sendModalVisible = useAppState(false);
	const createDocumentDisabled = useAppState(false);
	const documentStatusModalVisible = useAppState<DocumentStatus | null>(null);
	const documentLocked = useAppState<false | string>(false);
	const filteredData = useAppState<(DocumentNode | null)[] | null | undefined>([]);
	const tableState = useTableState<DocumentSortInput, DocumentFilterInput>({
		defaultSort: { createdAt: SortEnumType.DESC },
		defaultWhere,
		pagination: false,
		striped: true,
		disabledPaginationServer: true,
		disabledSortServer: true,
	});

	const documentForm = useForm({
		mode: 'all',
		criteriaMode: 'all',
		resolver: yupResolver(schema),
		defaultValues: {
			search: '',
			currentAccountOnly: props.fundingDetails?.id ? 'Current Funding' : 'Current Account',
			status: undefined,
			template: undefined,
		},
	});
	const watchForm = documentForm.watch();

	const documentsQuery = useQuery<OrganismsDcGetDocuments, OrganismsDcGetDocumentsVariables>(
		ORGANISMS_DC_GET_DOCUMENTS,
		{
			fetchPolicy: 'no-cache',
			skip: !props.accountDetails?.id,
			variables: {
				where: tableState.where.get,
				order: tableState.sort.get,
				first: 50, // 50 is the max for a query,
				accountId: props.accountDetails?.id || 0, // Tyler Hack
			},
			onCompleted: onFilterTable,
		}
	);

	const enabledAccountStages = [
		AccountStage.DOCUMENTS_PENDING,
		AccountStage.DOCUMENTS_SENT_TO_CLIENT,
		AccountStage.DOCUMENTS_SIGNED,
		AccountStage.VERIFIED,
	];

	const isDisabledDocumentTemplates = getIsDisabledDocumentTemplates();

	const isDisabledCreateDocument =
		isDisabledDocumentTemplates ||
		!watchForm.template ||
		watchForm.template === DocuSignTemplateType.NONE ||
		watchForm.template === FileTemplateType.NONE ||
		createDocumentDisabled.get;

	const [onUpdateDocumentStatus, updateDocumentStatusQuery] = useMutation<
		OrganismsDcUpdateDocumentStatus,
		OrganismsDcUpdateDocumentStatusVariables
	>(ORGANISMS_DC_UPDATE_DOCUMENT_STATUS, {
		refetchQueries: [
			'EcsGetUserDetails',
			'EditAccountScreenGetAccountDetails',
			EDIT_FUNDING_SCREEN_GET_FUNDING,
			ORGANISMS_DC_GET_DOCUMENTS,
		],
	});

	const [onSendDocument, sendDocumentQuery] = useMutation<
		OrganismsDcSendDocument,
		OrganismsDcSendDocumentVariables
	>(ORGANISMS_DC_SEND_DOCUMENT, {
		refetchQueries: [
			'EcsGetUserDetails',
			'EditAccountScreenGetAccountDetails',
			EDIT_FUNDING_SCREEN_GET_FUNDING,
			ORGANISMS_DC_GET_DOCUMENTS,
		],
	});

	const columns: ITrustColumn<DocumentNode>[] = [
		{
			key: 'fileTemplate',
			name: 'Template',
			minWidth: 200,
			sortable: false,
			selector: (row) =>
				row.fileTemplate === FileTemplateType.OTHER
					? row.name
					: documentTemplateTypeHumanizer(row.fileTemplate! || row.docuSignTemplate!),
		},
		{
			key: 'type',
			name: 'Type',
			maxWidth: 130,
			center: true,
			sortable: true,
			selector: (row) => humanize(row.type),
		},
		{
			key: 'stage',
			name: 'Stage',
			minWidth: 180,
			maxWidth: 200,
			center: true,
			selector: (row) => humanize(row.stage),
		},
		{
			key: 'status',
			name: 'Status',
			maxWidth: 150,
			center: true,
			selector: (row) => humanize(row.status),
		},
		{
			key: 'sentAt',
			name: 'Sent At',
			maxWidth: 150,
			selector: (row) => localDateTimeFormat(row.sentAt),
		},
	];

	const rowActions: ITrustAction<DocumentNode>[] = [
		{
			name: 'View',
			handler: (document) => {
				selectedRow.set(document);
				viewModalVisible.set(true);
			},
			iconLib: 'ion',
			iconName: 'eye',
		},
		{
			name: 'Void',
			handler: (document) => {
				selectedRow.set(document);
				documentStatusModalVisible.set(DocumentStatus.VOIDED);
			},
			isDisabled: (row) => row.stage === DocumentStage.VOIDED,
			iconLib: 'ion',
			iconName: 'trash',
		},
		{
			name: 'Resend',
			handler: (document) => {
				selectedRow.set(document);
				sendModalVisible.set(true);
			},
			isDisabled: (documentNode) =>
				documentNode.stage !== DocumentStage.DRAFT ||
				documentNode.status === DocumentStatus.DECLINED ||
				documentNode.status === DocumentStatus.VOIDED,
			iconLib: 'fa',
			iconName: 'send',
		},
		{
			name: 'Approve',
			handler: (document) => {
				selectedRow.set(document);
				documentStatusModalVisible.set(DocumentStatus.APPROVED);
			},
			isDisabled: (document) => {
				if (document.type === DocumentType.DOCU_SIGN) {
					return document.stage !== DocumentStage.SIGNED;
				}

				return document.stage !== DocumentStage.PENDING_APPROVAL;
			},
			iconLib: 'fa5',
			iconName: 'check',
		},
		{
			name: 'Decline',
			handler: (document) => {
				selectedRow.set(document);
				documentStatusModalVisible.set(DocumentStatus.DECLINED);
			},
			isDisabled: (document) =>
				document.stage === DocumentStage.SENT_TO_CLIENT ||
				document.stage === DocumentStage.DRAFT ||
				document.stage === DocumentStage.NONE ||
				document.stage === DocumentStage.VOIDED ||
				document.stage === DocumentStage.DECLINED,
			iconLib: 'matc',
			iconName: 'cancel',
		},
	];

	React.useEffect(() => {
		if (
			props.accountDetails?.id === tableState.where.get?.accountId?.eq ||
			props.userId === tableState.where.get?.userId?.eq
		) {
			return;
		}

		tableState.where.set(defaultWhere || undefined);
	}, [props.accountDetails?.id, props.userId]);

	React.useEffect(() => {
		// after updating the template, make sure there are no other documents
		// of the same type that are not voided/declined to disable new document creation
		if (watchForm.template) {
			const filtered =
				documentsQuery.data?.documents?.nodes?.filter(
					(docNode) =>
						docNode?.fileTemplate !== FileTemplateType.OTHER &&
						(docNode?.fileTemplate === watchForm.template ||
							docNode?.docuSignTemplate === watchForm.template) &&
						docNode?.status !== DocumentStatus.DECLINED &&
						docNode?.status !== DocumentStatus.VOIDED
				) || [];

			if (filtered.length) {
				createDocumentDisabled.set(true);
			} else {
				createDocumentDisabled.set(false);
			}
		}
	}, [watchForm.template, documentsQuery.data]);

	function getIsDisabledDocumentTemplates(): boolean {
		if (props.fundingDetails) {
			return documentsQuery.data?.account?.stage !== AccountStage.VERIFIED;
		}

		if (props.accountDetails) {
			return !enabledAccountStages.includes(
				documentsQuery.data?.account?.stage || AccountStage.NONE
			);
		}

		return false;
	}

	async function onSubmit(data: DocumentsFilterForm): Promise<void> {
		const where: DocumentFilterInput = {};

		// must ignore the template dropdown...
		delete data.template;

		if (isFormEmpty(data)) {
			filteredData.set(documentsQuery.data?.documents?.nodes);
		}

		if (props.fundingDetails?.id && data.currentAccountOnly === 'Current Funding') {
			where.fundingId = {
				eq: props.fundingDetails?.id,
			};
		} else if (props.accountDetails?.id && data.currentAccountOnly === 'Current Account') {
			where.accountId = {
				eq: props.accountDetails?.id,
			};
		} else if (props.userId && data.currentAccountOnly === 'All') {
			where.userId = {
				eq: props.userId,
			};
		}

		tableState.where.set(where);
	}

	function onFilterTable(): void {
		const formData = documentForm.getValues();

		delete formData.template;

		if (isFormEmpty(formData)) {
			filteredData.set(documentsQuery.data?.documents?.nodes);
		}

		const filtered = documentsQuery.data?.documents?.nodes?.filter((docNode) => {
			if (!formData.search && !formData.status) return true;

			if (
				formData.search &&
				(docNode?.fileTemplate?.toLowerCase()?.includes(formData.search.toLowerCase()) ||
					docNode?.docuSignTemplate
						?.toLowerCase()
						?.includes(formData.search.toLowerCase()))
			) {
				return true;
			}

			if (formData.status && docNode?.status === formData.status) {
				return true;
			}

			return false;
		});

		filteredData.set(filtered);
	}

	async function onUpdateStatusConfirm(): Promise<void> {
		// send the delete document mutation
		if (!selectedRow.get || !documentStatusModalVisible.get) return;

		try {
			await onUpdateDocumentStatus({
				variables: {
					input: {
						id: selectedRow.get.id,
						status: documentStatusModalVisible.get,
						stage: getMatchingStage(documentStatusModalVisible.get),
					},
				},
			});

			selectedRow.set(null);
			documentStatusModalVisible.set(null);
			Toast.show({
				type: 'success',
				text2: `Document has been updated to ${humanize(documentStatusModalVisible.get)}`,
			});
		} catch (error) {
			Toast.show({
				type: 'error',
				text2: 'Document has failed to update',
			});
		}
	}

	async function onSendConfirm(): Promise<void> {
		if (!selectedRow.get) return;
		try {
			const response = await onSendDocument({
				variables: {
					input: {
						id: selectedRow.get.id,
					},
				},
			});

			if (response.data?.sendDocument?.success) {
				if (response.data?.sendDocument?.data?.lockedUntil) {
					documentLocked.set(response.data?.sendDocument?.data?.lockedUntil);
				} else {
					sendModalVisible.set(false);

					Toast.show({
						type: 'success',
						text2: 'Document has been sent',
					});
				}
			}
		} catch (error) {
			Toast.show({
				type: 'error',
				text2: 'Document has failed to send',
			});
		}
	}

	function getMatchingStage(status: DocumentStatus): DocumentStage | undefined {
		switch (status) {
			case DocumentStatus.APPROVED:
				return DocumentStage.APPROVED;
			case DocumentStatus.VOIDED:
				return DocumentStage.VOIDED;
			case DocumentStatus.DECLINED:
				return DocumentStage.DECLINED;
			case DocumentStatus.NONE:
				return DocumentStage.NONE;
		}
	}

	function getTemplates(): string[] {
		let templates: string[] = [];

		// Account Templates
		if (props.accountDetails) {
			templates = [
				FileTemplateType.GOVERNMENT_ID,
				FileTemplateType.SSN_CARD,
				FileTemplateType.UTILITY_BILL,
				DocuSignTemplateType.ACCOUNT_CHANGES,
				DocuSignTemplateType.INDEMNIFICATION_LETTER,
				DocuSignTemplateType.IRA_ADOPTION_AGREEMENT,
				DocuSignTemplateType.KINGDOM_TRUST_INDEMNIFICATION_LETTER,
			];
		}

		// Funding Templates
		if (props.fundingDetails) {
			templates = [
				FileTemplateType.PREVIOUS_STATEMENT,
				DocuSignTemplateType.RECHARACTERIZATION_REQUEST,
			];

			// Outbound
			if (props.fundingDetails.direction === FundingDirection.OUTBOUND) {
				templates = [
					...templates,
					FileTemplateType.REFUND_CONTRIBUTION,
					DocuSignTemplateType.DISTRIBUTION,
					DocuSignTemplateType.INDEMNIFICATION_LETTER,
					DocuSignTemplateType.PRECIOUS_METALS_DISTRIBUTION,
					DocuSignTemplateType.CONVERSION,
					FileTemplateType.PREMIUM_CUSTODY_ACCOUNT_WITHDRAWAL,
				];
			}

			// Transfer - Inbound
			if (
				props.fundingDetails.type === FundingType.TRANSFER &&
				props.fundingDetails.direction === FundingDirection.INBOUND
			) {
				templates = [
					...templates,
					FileTemplateType.INHERITED_IRA_TRANSFER_FORM,
					FileTemplateType.TRANSFER_FORM,
				];
			}

			// Rollover
			if (props.fundingDetails.type === FundingType.ROLLOVER) {
				templates = [
					...templates,
					DocuSignTemplateType.ROTH60_DAY_ROLLOVER,
					DocuSignTemplateType.TRADITIONAL60_DAY_ROLLOVER,
				];

				// Rollover - Inbound - Cares Act
				if (
					props.fundingDetails.direction === FundingDirection.INBOUND &&
					props.fundingDetails.rolloverType === RolloverType.CARES_ACT_ROLLOVER
				) {
					templates = [...templates, DocuSignTemplateType.CARES_ACT];
				}
			}

			// Conversion
			if (props.fundingDetails.type === FundingType.CONVERSION) {
				templates = [...templates, DocuSignTemplateType.ROTH_CONVERSION];
			}
		}

		templates = [
			...templates,
			DocuSignTemplateType.SUPPLEMENTAL_CURRENCIES,
			FileTemplateType.OTHER,
		];

		return templates;
	}

	return (
		<CustomCard header="Documents" style={props.style}>
			<ViewDocument
				document={selectedRow.get}
				refetchDocuments={documentsQuery.refetch}
				toggleVisible={() => {
					selectedRow.set(null);
					viewModalVisible.set(false);
				}}
				visible={viewModalVisible.get}
				onSend={(success) => {
					if (success) {
						documentsQuery.refetch();
						viewModalVisible.set(false);
					}
					Toast.show({
						type: success ? 'success' : 'error',
						text2: `Document has ${success ? 'been sent' : 'failed to send'}`,
					});
				}}
				onUpdateName={() => {
					documentsQuery.refetch();
				}}
			/>
			{props.userId && watchForm.template && (
				<CreateDocument
					accountId={props.accountDetails?.id}
					fundingDetails={props.fundingDetails}
					template={watchForm.template!}
					toggleVisible={() => createModalVisible.set(false)}
					userId={props.userId}
					visible={createModalVisible.get}
					onSave={(success: boolean) => {
						if (success) {
							documentsQuery.refetch();
							documentForm.reset({
								...documentForm.getValues(),
								template: undefined,
							});
							createModalVisible.set(false);
						}
						Toast.show({
							type: success ? 'success' : 'error',
							text2: `Document has ${success ? 'been added' : 'failed to add'}`,
						});
					}}
				/>
			)}
			<AppAlert
				actions={[
					{
						title: 'Cancel',
						status: 'primary',
						appearance: 'outline',
						onPress: () => {
							selectedRow.set(null);
							documentStatusModalVisible.set(null);
						},
					},
					{
						title: 'Confirm',
						status:
							documentStatusModalVisible.get === DocumentStatus.DECLINED ||
							documentStatusModalVisible.get === DocumentStatus.VOIDED
								? 'danger'
								: 'primary',
						loading: updateDocumentStatusQuery.loading,
						onPress: onUpdateStatusConfirm,
					},
				]}
				message={`This action cannot be undone.\nSet document status to ${humanize(
					documentStatusModalVisible.get
				)}`}
				title="Are you sure?"
				visible={!!documentStatusModalVisible.get}
			/>
			<AppAlert
				actions={[
					{
						title: 'Cancel',
						status: 'primary',
						appearance: 'outline',
						onPress: () => {
							selectedRow.set(null);
							sendModalVisible.set(false);
						},
					},
					{
						title: 'Confirm',
						status: 'primary',
						loading: sendDocumentQuery.loading,
						onPress: onSendConfirm,
					},
				]}
				message="Send document to customer."
				title="Are you sure?"
				visible={sendModalVisible.get}
			/>
			<AppAlert
				actions={[
					{
						title: 'OK',
						status: 'danger',
						appearance: 'filled',
						onPress: () => {
							documentLocked.set(false);
						},
					},
				]}
				message={
					documentLocked.get
						? `Document is currently being viewed/signed by client/user. Please check back in ${formatDistanceToNow(
								new Date(documentLocked.get)
						  )}.`
						: ''
				}
				title="Request Failed"
				visible={!!documentLocked.get}
				xmlIcon={DangerAssetSVG}
			/>

			<View style={styles.filter.container}>
				<Controller
					control={documentForm.control}
					name="search"
					render={(control) => (
						<Input
							accessoryRight={() => (
								<TouchableOpacity
									testID="searchDocumentsBtn"
									onPress={onFilterTable}
								>
									<AppIcon lib="fe" name="search" />
								</TouchableOpacity>
							)}
							label=" "
							placeholder="Search..."
							style={styles.filter.search}
							testID="searchDocumentsField"
							textStyle={{ height: 26 }}
							value={control.field.value!}
							onChangeText={control.field.onChange}
							onSubmitEditing={onFilterTable}
						/>
					)}
				/>

				<Controller
					control={documentForm.control}
					name="status"
					render={(control) => (
						<View style={styles.filter.status}>
							<AppSelector
								closeOnSelect
								data={Object.values(DocumentStatus)}
								itemDisplay={humanize}
								label="Status"
								value={control.field.value}
								onSelect={(selected) => {
									control.field.onChange(selected);
									onFilterTable();
								}}
							/>
						</View>
					)}
				/>
				{(props.accountDetails?.id || props.fundingDetails?.id) && (
					<Controller
						control={documentForm.control}
						name="currentAccountOnly"
						render={(control) => (
							<View style={styles.filter.records}>
								<AppSelector
									closeOnSelect
									data={
										props.fundingDetails?.id
											? ['All', 'Current Account', 'Current Funding']
											: ['All', 'Current Account']
									}
									label="Records"
									value={control.field.value}
									onSelect={(selected) => {
										control.field.onChange(selected);
										documentForm.handleSubmit(onSubmit)();
									}}
								/>
							</View>
						)}
					/>
				)}
			</View>

			{(props.accountDetails?.id || props.fundingDetails?.id) && (
				<View style={styles.createActions.container}>
					<View style={styles.createActions.template}>
						<Controller
							control={documentForm.control}
							name="template"
							render={(control) => (
								<AppSelector
									closeOnSelect
									data={getTemplates()}
									disabled={isDisabledDocumentTemplates}
									itemDisplay={documentTemplateTypeHumanizer}
									label="Choose Template"
									value={control.field.value}
									onSelect={control.field.onChange}
								/>
							)}
						/>
					</View>

					<Button
						disabled={isDisabledCreateDocument}
						style={styles.createActions.button}
						onPress={() => createModalVisible.set(!createModalVisible.get)}
					>
						Create
					</Button>
				</View>
			)}

			{isDisabledDocumentTemplates && documentsQuery.data?.account?.stage && (
				<Text status="danger">
					Account can not create documents when stage is{' '}
					{`"${accountStageTypeHumanizer(documentsQuery.data?.account?.stage)}"`}
				</Text>
			)}
			<CustomDataTable
				{...tableState.props}
				fixedHeader
				columns={columns}
				data={filteredData.get as DocumentNode[]}
				fixedHeaderScrollHeight="500px"
				progressPending={documentsQuery.loading}
				refetch={(variables) => documentsQuery.refetch({ ...variables })}
				rowActions={rowActions}
			/>
		</CustomCard>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();

	return {
		card: StyleSheet.create({
			container: {
				marginBottom: 6,
				paddingVertical: 24,
			},
			header: {
				borderBottomWidth: 1,
				borderBottomColor: theme['border-basic-color-3'],
				paddingBottom: 16,
				paddingHorizontal: 16,
				fontSize: 16,
			},
			content: {
				paddingHorizontal: 16,
				paddingTop: 12,
			},
		}),
		filter: StyleSheet.create({
			container: {
				flexDirection: 'row',
			},
			search: {
				flex: 3,
			},
			status: {
				flex: 1,
				marginHorizontal: 16,
			},
			records: {
				flex: 1,
			},
		}),
		createActions: StyleSheet.create({
			container: {
				marginBottom: 4,
				marginTop: 24,
				flexDirection: 'row',
			},
			button: {
				height: 42,
				alignSelf: 'flex-end',
				marginLeft: 16,
			},
			template: {
				flex: 0.3,
			},
		}),
	};
}

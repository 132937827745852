import {
	FundingsScreen_GetFundings_fundings_nodes as Funding,
	FundingStage,
	CurrencyType,
	FundingStatus,
	FundingDirection,
} from '@app/codegen';
import { FundingsScreenLocators } from '@app/e2e/screens/Fundings';
import { EditFundingScreenParams } from '@app/navigations';
import { useFundingsInflowNavigation } from '@app/navigations/Main/FundingsInflow';
import { useFundingsOutflowNavigation } from '@app/navigations/Main/FundingsOutflow';
import {
	CustomDataTable,
	DangerAssetSVG,
	FundingStatusBubble,
	ITrustAction,
	ITrustColumn,
} from '@app/shared/components';
import { toCurrencyDisplay } from '@app/shared/helpers';
import {
	AppAlert,
	humanize,
	localDateFormat,
	localDateTimeFormat,
	useAppState,
} from '@itrustcapital/ui';
import { Text } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet } from 'react-native';
import Toast from 'react-native-toast-message';

import { useFundingsScreenCancelFundingMutation } from '../FundingsScreenGraphql';
import { useFundingsScreenContext } from '../FundingsScreenProvider';

export function FundingsScreenTable() {
	const state = useFundingsScreenContext();
	const styles = useCustomStyles();
	const fundingInflowStackNavigation = useFundingsInflowNavigation();
	const fundingOutflowStackNavigation = useFundingsOutflowNavigation();
	const selectedRow = useAppState<Funding | null>(null);
	const cancelVisible = useAppState(false);

	const [onCancelFunding, cancelFundingQuery] = useFundingsScreenCancelFundingMutation();

	function onViewFunding(funding: Funding): void {
		const params: EditFundingScreenParams = {
			fundingId: funding.id,
			fundingStage: state.filterForm.getValues('stage'),
			direction: state.isFundingsInflow
				? FundingDirection.INBOUND
				: FundingDirection.OUTBOUND,
		};

		return state.isFundingsInflow
			? fundingInflowStackNavigation.Edit(params)
			: fundingOutflowStackNavigation.Edit(params);
	}

	function getFundingAmount(funding: Funding): string {
		const fundingAssets = funding.fundingAssets;

		if (fundingAssets?.length! > 1) {
			return 'View Details';
		}

		return toCurrencyDisplay(
			Number(
				fundingAssets?.[0]?.amount > 0
					? fundingAssets?.[0]?.amount
					: fundingAssets?.[0]?.requestedAmount ?? 0
			),
			fundingAssets?.[0]?.currencyType!
		) as string;
	}

	function fundingAmountCell(funding: Funding): React.ReactNode {
		const fundingAmount = getFundingAmount(funding);

		if (fundingAmount === 'View Details') {
			return (
				<Text
					status="primary"
					style={styles.table.link}
					onPress={() => onViewFunding(funding)}
				>
					View Details
				</Text>
			);
		}

		return <Text>{getFundingAmount(funding)}</Text>;
	}

	async function onCancelSubmit() {
		try {
			await onCancelFunding({
				variables: {
					input: {
						fundingId: selectedRow.get?.id || 0,
					},
				},
			});

			Toast.show({
				text2: 'Funding has been canceled',
				type: 'success',
			});

			cancelVisible.set(false);
			state.fundingsQuery.refetch();
		} catch (error) {
			Toast.show({
				text2: 'Funding failed to cancel',
				type: 'error',
			});
		}
	}

	const actions: ITrustAction<Funding>[] = [
		{
			handler: onViewFunding,
			iconLib: 'matc',
			iconName: 'pencil',
			name: 'edit',
		},
		{
			name: 'Cancel',
			handler: (row) => {
				selectedRow.set(row);
				cancelVisible.set(true);
			},
			isDisabled: (_row) => state.isCancelDisabled || _row.stage === FundingStage.CANCELED,
			iconLib: 'matc',
			iconName: 'cancel',
		},
	];

	const columns: ITrustColumn<Funding>[] = [
		{
			key: 'createdAt',
			name: 'Created Date',
			sortable: true,
			selector: (row) => localDateTimeFormat(row.createdAt),
			width: '180px',
		},
		{
			key: 'fundedAt',
			name: 'Funded Date',
			sortable: true,
			selector: (row) => localDateFormat(row.fundedAt),
		},
		{
			key: 'documentFileUploadedAt',
			name: 'Uploaded Date',
			sortable: true,
			selector: (row) => localDateFormat(row.documentFileUploadedAt) || 'N/A',
		},
		{
			key: 'fromAccount',
			name: 'Customer Name',
			minWidth: 300,
			selector: (row) => {
				return state.isFundingsInflow
					? `${row?.toAccount?.user?.firstName} ${row?.toAccount?.user?.lastName}`
					: `${row?.fromAccount?.user?.firstName} ${row?.fromAccount?.user?.lastName}`;
			},
		},
		{
			key: 'fromAccount',
			name: 'Account #',
			minWidth: 130,
			selector: (row) => {
				return state.isFundingsInflow
					? row?.toAccount?.accountNumber
					: row?.fromAccount?.accountNumber;
			},
		},
		{
			key: 'fromAccount',
			name: 'Account Status',
			center: true,
			selector: (row) => {
				return state.isFundingsInflow
					? humanize(row?.toAccount?.status || '')
					: humanize(row?.fromAccount?.status || '');
			},
		},
		{
			key: 'type',
			name: 'Funding Type',
			minWidth: 200,
			sortable: true,
			center: true,
			selector: (row) => humanize(row.type),
		},
		{
			key: 'rolloverType',
			name: 'Rollover Type',
			minWidth: 200,
			sortable: true,
			center: true,
			selector: (row) => humanize(row.rolloverType),
		},
		{
			key: 'fundingAssets',
			name: 'Asset Type',
			center: true,
			selector: (row) =>
				row?.fundingAssets?.length! > 1
					? 'Multiple'
					: row?.fundingAssets?.[0]?.currencyType,
		},
		{
			key: 'fundingAssets',
			name: 'Funding Status',
			maxWidth: 200,
			center: true,
			selector: (row) => (
				<FundingStatusBubble
					status={
						row?.fundingAssets?.find(
							(fundingAsset) => fundingAsset?.currencyType === CurrencyType.USD
						)?.status || FundingStatus.NONE
					}
				/>
			),
		},
		{
			key: 'fundingAssets',
			name: 'Funding Amount',
			right: true,
			cell: fundingAmountCell,
		},
		{
			key: 'stage',
			name: 'Funding Stage',
			minWidth: 200,
			sortable: true,
			selector: (row) => humanize(row?.stage || ''),
		},
	];

	return (
		<>
			<AppAlert
				actions={[
					{
						testID: FundingsScreenLocators.deleteFundingModalCancelButton,
						disabled: state.isCancelDisabled,
						title: 'Cancel',
						status: 'primary',
						appearance: 'outline',
						onPress: () => {
							selectedRow.set(null);
							cancelVisible.set(false);
						},
					},
					{
						testID: FundingsScreenLocators.deleteFundingModalConfirmButton,
						title: 'Confirm',
						status: 'danger',
						onPress: onCancelSubmit,
						loading: cancelFundingQuery.loading,
					},
				]}
				message="Do you really want to cancel this funding?"
				title="Are you sure?"
				visible={cancelVisible.get}
				xmlIcon={DangerAssetSVG}
			/>
			<CustomDataTable
				{...state.tableState.props}
				columns={columns}
				data={state.fundingsQuery.data?.fundings?.nodes as Funding[]}
				progressPending={state.fundingsQuery.loading}
				refetch={(variables) => {
					state.fundingsQuery.refetch({
						...variables,
						where: state.tableState.where.get,
					});
				}}
				rowActions={actions}
			/>
		</>
	);
}

function useCustomStyles() {
	return {
		table: StyleSheet.create({
			link: {
				textDecorationLine: 'underline',
			},
		}),
	};
}

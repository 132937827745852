import { ApolloError, gql, useMutation } from '@apollo/client';
import {
	AccountType,
	ContributionType,
	CurrencyType,
	EditFundingScreen_EditFundingDetails,
	EditFundingScreen_EditFundingDetailsVariables,
	EditFundingScreen_GetFunding_funding as FundingDetailsType,
	FundingDirection,
	FundingStage,
	FundingType,
	PaymentType,
	RolloverFromType,
	RolloverType,
	UpdateFundingInput,
} from '@app/codegen';
import { useMaestroUserAtom } from '@app/core/atoms';
import { FundingDetailsLocators } from '@app/e2e/screens/EditAccount';
import { CustomCard, DangerAssetSVG } from '@app/shared/components';
import {
	accountTypeHumanizer,
	getCurrencyType,
	paymentTypeHumanizer,
	rolloverTypeHumanizer,
} from '@app/shared/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	AppAlert,
	AppSelector,
	humanize,
	numericStr,
	toCurrency,
	unMaskCurrency,
	useAppState,
} from '@itrustcapital/ui';
import { Button, Input, Spinner, Text } from '@ui-kitten/components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Toast from 'react-native-toast-message';
import * as yup from 'yup';
import { TypeOf } from 'yup';

import { EDIT_FUNDING_SCREEN_GET_FUNDING } from '../EditFundingScreen';

export interface FundingDetailsProps {
	style?: StyleProp<ViewStyle>;
	fundingId: number;
	fundingDetails?: FundingDetailsType | null;
	loading?: boolean;
}

export const EDIT_FUNDING_SCREEN_EDIT_FUNDINGS_DETAILS = gql`
	mutation EditFundingScreen_EditFundingDetails($input: UpdateFundingInput!) {
		updateFunding(input: $input) {
			success
			errorMessage
		}
	}
`;

const schema = yup
	.object({
		fundingType: yup.mixed<FundingType>().oneOf(Object.values(FundingType)).default(undefined),
		fundingDirectionType: yup
			.mixed<FundingDirection>()
			.oneOf(Object.values(FundingDirection))
			.default(FundingDirection.NONE),
		rolloverType: yup
			.mixed<RolloverType>()
			.when(['fundingType'], {
				is: (fundingType: FundingType) => fundingType === FundingType.ROLLOVER,
				then: yup
					.mixed<RolloverType>()
					.oneOf(Object.values(RolloverType))
					.required()
					.default(undefined),
			})
			.default(undefined),
		rolloverFrom: yup
			.mixed<RolloverFromType>()
			.when(['fundingType', 'fundingDirectionType'], {
				is: (fundingType: FundingType, fundingDirectionType: FundingDirection) =>
					fundingType === FundingType.ROLLOVER &&
					fundingDirectionType === FundingDirection.INBOUND,
				then: yup
					.mixed<RolloverFromType>()
					.oneOf(Object.values(RolloverFromType))
					.required()
					.default(undefined),
			})
			.default(undefined),
		currencyType: yup.mixed<CurrencyType | string>().default(undefined),
		fromAccountType: yup
			.mixed<AccountType>()
			.when(['fundingType', 'fundingDirectionType'], {
				is: (fundingType: FundingType, fundingDirectionType: FundingDirection) =>
					fundingType !== FundingType.CONTRIBUTION &&
					fundingType !== FundingType.PCA_USD &&
					fundingDirectionType === FundingDirection.INBOUND,
				then: yup
					.mixed<AccountType>()
					.oneOf(Object.values(AccountType))
					.required()
					.default(undefined),
			})
			.default(undefined),
		fromInstitution: yup
			.string()
			.when(['fundingType', 'fundingDirectionType'], {
				is: (fundingType: FundingType, fundingDirectionType: FundingDirection) =>
					fundingType !== FundingType.CONTRIBUTION &&
					fundingType !== FundingType.PCA_USD &&
					fundingDirectionType === FundingDirection.INBOUND,
				then: yup.string().required().default(''),
			})
			.default(''),
		fromAccountNumber: yup
			.string()
			.when(['fundingType', 'fundingDirectionType'], {
				is: (fundingType: FundingType, fundingDirectionType: FundingDirection) =>
					fundingType !== FundingType.CONTRIBUTION &&
					fundingType !== FundingType.PCA_USD &&
					fundingDirectionType === FundingDirection.INBOUND,
				then: yup.string().default(''),
			})
			.default(''),
		toAccountType: yup
			.mixed<AccountType>()
			.when(['fundingType', 'fundingDirectionType'], {
				is: (fundingType: FundingType, fundingDirectionType: FundingDirection) =>
					fundingDirectionType === FundingDirection.OUTBOUND &&
					fundingType !== FundingType.REFUND_CONTRIBUTION,
				then: yup
					.mixed<AccountType>()
					.oneOf(Object.values(AccountType))
					.required()
					.default(undefined),
			})
			.default(undefined),
		toInstitution: yup
			.string()
			.when(['fundingType', 'fundingDirectionType'], {
				is: (fundingType: FundingType, fundingDirectionType: FundingDirection) =>
					fundingDirectionType === FundingDirection.OUTBOUND &&
					fundingType !== FundingType.REFUND_CONTRIBUTION,
				then: yup.string().required().default(''),
			})
			.default(''),
		toAccountNumber: yup
			.string()
			.when(['fundingType', 'fundingDirectionType'], {
				is: (fundingType: FundingType, fundingDirectionType: FundingDirection) =>
					fundingDirectionType === FundingDirection.OUTBOUND &&
					fundingType !== FundingType.REFUND_CONTRIBUTION,
				then: yup.string().required().default(''),
			})
			.default(''),
		contributionYear: yup
			.string()
			.when(['fundingType'], {
				is: (fundingType: FundingType) => fundingType === FundingType.CONTRIBUTION,
				then: yup.string().required().default(''),
			})
			.default(''),
		contributionType: yup
			.mixed<ContributionType>()
			.when(['fundingType'], {
				is: (fundingType: FundingType) => fundingType === FundingType.CONTRIBUTION,
				then: yup
					.mixed<ContributionType>()
					.oneOf(Object.values(ContributionType))
					.default(undefined),
			})
			.default(undefined),
		transactionCode: yup
			.string()
			.max(2, 'Transaction code must be at most 2 characters')
			.nullable()
			.default(''),
		paymentType: yup
			.mixed<PaymentType>()
			.oneOf(Object.values(PaymentType))
			.required()
			.default(undefined),
		stateWithholdings: yup.string().default(''),
		federalWithholdings: yup.string().default(''),
		transactionId: yup.string().nullable().default(''),
		paymentId: yup.string().nullable().default(''),
		requestedAmount: yup.string().nullable().default(''),
		totalAmount: yup
			.string()
			.when(['fundingType'], {
				is: (fundingType: FundingType) => fundingType === FundingType.PCA_USD,
				then: yup.string().required('Total amount required').default(''),
			})
			.default(''),
		assetType: yup.string().default(''),
	})
	.defined();

export type FundingDetailsData = TypeOf<typeof schema>;

export function FundingDetails(props: FundingDetailsProps) {
	const styles = useCustomStyles();

	const isEditing = useAppState<boolean>(false);
	const showError = useAppState(false);
	const errorMessage = useAppState('');

	const defaultFailedMessage = 'Sorry, something went wrong. Please try again.';

	const funding = props.fundingDetails;

	const options = { shouldValidate: true };

	const form = useForm<FundingDetailsData>({
		mode: 'all',
		criteriaMode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});
	const formWatch = form.watch();
	const USDAsset = props.fundingDetails?.fundingAssets?.find(
		(fundingAsset) => fundingAsset?.currencyType === CurrencyType.USD
	);

	const maestroUserAtom = useMaestroUserAtom();

	const isAdmin = maestroUserAtom.isAdmin;
	const isFormDisabledBase = !isAdmin || disabledAfterStage();

	const showFromInputs =
		formWatch.fundingType !== FundingType.CONTRIBUTION &&
		formWatch.fundingType !== FundingType.PCA_USD;

	const showToInputs = funding?.type !== FundingType.REFUND_CONTRIBUTION;

	const [editFunding, editFundingQuery] = useMutation<
		EditFundingScreen_EditFundingDetails,
		EditFundingScreen_EditFundingDetailsVariables
	>(EDIT_FUNDING_SCREEN_EDIT_FUNDINGS_DETAILS, {
		refetchQueries: [EDIT_FUNDING_SCREEN_GET_FUNDING],
	});

	React.useEffect(() => {
		if (funding) {
			prefill(funding);
		}
	}, [funding]);

	function prefill(funding: FundingDetailsType) {
		form.setValue('fundingType', funding.type, options);
		form.setValue('fundingDirectionType', funding.direction, options);
		form.setValue('rolloverType', funding.rolloverType!, options);
		form.setValue('rolloverFrom', funding.rolloverFrom!, options);
		form.setValue('transactionCode', funding.transactionCode || '', options);
		form.setValue('contributionYear', funding.contributionYear?.toString()! || '', options);
		form.setValue('contributionType', funding.contributionType!, options);
		form.setValue(
			'assetType',
			humanize(USDAsset?.currencyType!)?.toUpperCase() ||
				funding.fundingAssets?.[0]?.currencyType ||
				'',
			options
		);

		form.setValue('paymentType', funding.paymentType);
		form.setValue(
			'currencyType',
			humanize(USDAsset?.currencyType!)?.toUpperCase() ||
				funding.fundingAssets?.[0]?.currencyType ||
				'',
			options
		);

		form.setValue(
			'stateWithholdings',
			funding?.stateWithholdings ? toCurrency(funding.stateWithholdings) : '$',
			options
		);
		form.setValue(
			'federalWithholdings',
			funding?.federalWithholdings ? toCurrency(funding.federalWithholdings) : '$',
			options
		);
		form.setValue('transactionId', funding.transactionId || '', options);
		form.setValue('paymentId', funding.paymentId || '', options);
		form.setValue(
			'requestedAmount',
			funding.fundingAssets?.[0]?.requestedAmount || '',
			options
		);
		form.setValue('totalAmount', funding.fundingAssets?.[0]?.amount || '', options);

		setFrom();
		setTo();
	}

	function rolloverFromRequired(
		fundingType?: FundingType,
		fundingDirectionType?: FundingDirection
	): boolean {
		return (
			fundingType === FundingType.ROLLOVER &&
			fundingDirectionType === FundingDirection.INBOUND
		);
	}

	function setFrom() {
		const isInternal = !!funding?.fromAccount;

		form.setValue(
			'fromAccountType',
			isInternal ? funding?.fromAccount?.type : funding?.fromExternalAccount?.accountType,
			options
		);
		form.setValue(
			'fromInstitution',
			funding?.direction === FundingDirection.OUTBOUND || funding?.fromAccount
				? funding?.fromAccount?.custodian || ''
				: funding?.fromExternalAccount?.institutionName! || '',
			options
		);
		form.setValue(
			'fromAccountNumber',
			isInternal
				? funding?.fromAccount?.accountNumber!
				: funding?.fromExternalAccount?.accountNumber! || '',
			options
		);
	}

	function setTo() {
		const isInternal = !!funding?.toAccount;

		form.setValue(
			'toAccountType',
			isInternal ? funding?.toAccount?.type : funding?.toExternalAccount?.accountType,
			options
		);
		form.setValue(
			'toInstitution',
			funding?.direction === FundingDirection.INBOUND || funding?.toAccount
				? 'Fortis'
				: funding?.toExternalAccount?.institutionName!,
			options
		);
		form.setValue(
			'toAccountNumber',
			isInternal
				? funding?.toAccount?.accountNumber!
				: funding?.toExternalAccount?.accountNumber!,
			options
		);
	}

	// Outbound
	function isFromDisabled(): boolean {
		return (
			isFormDisabledBase ||
			!!props.fundingDetails?.fromAccount ||
			formWatch.fundingDirectionType === FundingDirection.OUTBOUND ||
			funding?.type === FundingType.TPA_TRANSFER ||
			funding?.type === FundingType.PCA_USD
		);
	}

	// Inbound
	function isToDisabled(): boolean {
		return (
			isFormDisabledBase ||
			!!props.fundingDetails?.toAccount ||
			formWatch.fundingDirectionType === FundingDirection.INBOUND
		);
	}

	function disabledAfterStage(): boolean {
		return props.fundingDetails?.stage === FundingStage.ASSET_TRANSFER_COMPLETED;
	}

	function getContributionYear(): string[] {
		const now = new Date();
		const year = now.getFullYear();

		return [year.toString(), (year - 1).toString()];
	}

	async function onSubmit(data: FundingDetailsData) {
		const stateWithholdings = unMaskCurrency(data.stateWithholdings);
		const federalWithholdings = unMaskCurrency(data.federalWithholdings);

		const assetToUpdate = funding?.fundingAssets?.[0] || null;

		let fundingInput: UpdateFundingInput = {
			id: Number(props.fundingId),
			type: data.fundingType ? data.fundingType : undefined,
			paymentType: data.paymentType,
			rolloverType:
				data.rolloverType && data.fundingType === FundingType.ROLLOVER
					? data.rolloverType
					: null,
			rolloverFrom: rolloverFromRequired(data.fundingType, data.fundingDirectionType)
				? data.rolloverFrom
				: null,
			transactionCode: data.transactionCode ? data.transactionCode : undefined,
			fromExternalAccount:
				isToDisabled() && props.fundingDetails?.fromExternalAccount
					? {
							id: props.fundingDetails.fromExternalAccount.id,
							institutionName: data.fromInstitution || '',
							accountType: data.fromAccountType,
							accountNumber: data.fromAccountNumber || '',
					  }
					: undefined,
			toExternalAccount:
				isFromDisabled() && props.fundingDetails?.toExternalAccount?.id
					? {
							id: props.fundingDetails?.toExternalAccount?.id!,
							institutionName: data.toInstitution || '',
							accountType: data.toAccountType,
							accountNumber: data.toAccountNumber || '',
					  }
					: undefined,
			transactionId: data.transactionId || undefined,
			fundingAssets:
				assetToUpdate && data?.totalAmount
					? [{ id: assetToUpdate?.id, amount: unMaskCurrency(data?.totalAmount) }]
					: undefined,
		};

		if (data.contributionYear && data.fundingType === FundingType.CONTRIBUTION) {
			fundingInput = { ...fundingInput, contributionYear: Number(data.contributionYear) };
		}

		if (data.contributionType && data.fundingType === FundingType.CONTRIBUTION) {
			fundingInput = { ...fundingInput, contributionType: data.contributionType };
		}

		if (stateWithholdings) {
			fundingInput = {
				...fundingInput,
				stateWithholdings,
			};
		}

		if (federalWithholdings) {
			fundingInput = {
				...fundingInput,
				federalWithholdings,
			};
		}

		const input: UpdateFundingInput = fundingInput;

		try {
			const response = await editFunding({
				variables: { input },
			});
			isEditing.set(false);

			if (response.data?.updateFunding) {
				if (!response.data?.updateFunding.success) {
					errorMessage.set(
						response.data?.updateFunding.errorMessage || defaultFailedMessage
					);
					showError.set(true);
				} else {
					Toast.show({
						type: 'success',
						text2: 'Successfully saved funding',
					});
				}
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				Toast.show({
					type: 'error',
					text2: error.message,
				});
			}
		}
	}

	function onCancel() {
		if (props.fundingDetails) {
			prefill(props.fundingDetails);
		}
		isEditing.set(false);
	}

	function getPaymentType(): string[] {
		if (props.fundingDetails?.type === FundingType.PCA_USD) {
			return Object.values(PaymentType).filter(
				(type) => type === PaymentType.ACH || type === PaymentType.WIRE
			);
		}

		return Object.values(PaymentType).filter((type) => type !== PaymentType.NONE);
	}

	return (
		<CustomCard
			footer={() => (
				<View style={styles.actions.container}>
					{isEditing.get ? (
						<>
							<Button
								appearance="outline"
								style={styles.actions.leftButton}
								testID={FundingDetailsLocators.cancelButton}
								onPress={onCancel}
							>
								Cancel
							</Button>
							<Button
								testID={FundingDetailsLocators.saveButton}
								onPress={form.handleSubmit(onSubmit)}
							>
								{editFundingQuery?.loading ? (
									<View>
										<Spinner size="tiny" status="basic" />
									</View>
								) : (
									'Save'
								)}
							</Button>
						</>
					) : (
						<>
							<Button
								testID={FundingDetailsLocators.editButton}
								disabled={funding?.stage === FundingStage.CANCELED}
								onPress={() => isEditing.set(true)}
							>
								Edit
							</Button>
						</>
					)}
				</View>
			)}
			header={() => (
				<View style={styles.main.header}>
					<Text category="h6">Funding Details</Text>
				</View>
			)}
			loading={props.loading}
			style={props.style}
		>
			<View style={styles.main.row}>
				{/* Payment ID (ACH ONLY) */}
				{USDAsset?.paymentType === PaymentType.ACH && (
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name={'paymentId'}
							render={(control) => (
								<Input
									disabled
									label="Payment ID (ACH ONLY)"
									status={control.formState.errors.paymentId ? 'danger' : 'basic'}
									value={control.field.value!}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
				)}

				{/* transaction ID */}
				<View style={styles.main.col}>
					<Controller
						control={form.control}
						name="transactionId"
						render={(control) => (
							<Input
								disabled={!isEditing.get || disabledAfterStage()}
								label="Transaction ID"
								status={control.formState.errors.transactionId ? 'danger' : 'basic'}
								value={control.field.value!}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
				</View>

				{/* Funding Type */}
				<View style={styles.main.col}>
					<Controller
						control={form.control}
						name="fundingType"
						render={(control) => (
							<Input
								disabled
								label="Funding Type"
								value={humanize(control.field.value!)}
							/>
						)}
					/>
				</View>

				{/* Funding Direction */}
				<View style={styles.main.col}>
					<Controller
						control={form.control}
						name="fundingDirectionType"
						render={(control) => (
							<Input
								disabled
								label="Funding Direction"
								value={humanize(control.field.value!)}
							/>
						)}
					/>
				</View>

				{/* Payment Type */}
				<View style={styles.main.col}>
					<Controller
						control={form.control}
						name="paymentType"
						render={(control) => (
							<AppSelector
								closeOnSelect
								data={getPaymentType()}
								disabled={
									props.fundingDetails?.type !== FundingType.PCA_USD ||
									!isEditing.get ||
									disabledAfterStage()
								}
								itemDisplay={paymentTypeHumanizer}
								label="Payment Type"
								status={control.formState.errors.paymentType ? 'danger' : 'basic'}
								testID={FundingDetailsLocators.paymentTypeField}
								value={control.field.value}
								onSelect={control.field.onChange}
							/>
						)}
					/>
				</View>

				{/* Rollover Type */}
				{formWatch.fundingType === FundingType.ROLLOVER && (
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="rolloverType"
							render={(control) => (
								<AppSelector
									closeOnSelect
									data={Object.values(RolloverType).filter(
										(x) => x !== RolloverType.NONE
									)}
									disabled={isFormDisabledBase}
									itemDisplay={rolloverTypeHumanizer}
									label="Rollover Type *"
									status={
										control.formState.errors.rolloverType ? 'danger' : 'basic'
									}
									value={control.field.value}
									onSelect={control.field.onChange}
								/>
							)}
						/>
					</View>
				)}

				{/* Rollover From */}
				{formWatch.fundingType === FundingType.ROLLOVER &&
					formWatch.fundingDirectionType === FundingDirection.INBOUND && (
						<View style={styles.main.col}>
							<Controller
								control={form.control}
								name="rolloverFrom"
								render={(control) => (
									<AppSelector
										closeOnSelect
										data={Object.values(RolloverFromType).filter(
											(x) => x !== RolloverFromType.NONE
										)}
										disabled={isFormDisabledBase}
										itemDisplay={humanize}
										label="Rollover From *"
										status={control.fieldState.error ? 'danger' : 'basic'}
										testID={FundingDetailsLocators.rolloverFromType}
										value={control.field.value}
										onSelect={control.field.onChange}
									/>
								)}
							/>
						</View>
					)}
			</View>
			{showFromInputs && (
				<View style={styles.main.row}>
					{/* From Account Type */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="fromAccountType"
							render={(control) => (
								<AppSelector
									closeOnSelect
									data={Object.values(AccountType).filter(
										(x) => x !== AccountType.NONE
									)}
									disabled={isFromDisabled()}
									itemDisplay={accountTypeHumanizer}
									label="From Account Type *"
									status={
										control.formState.errors.fromAccountType
											? 'danger'
											: 'basic'
									}
									testID={FundingDetailsLocators.fromAccountTypeField}
									value={control.field.value}
									onSelect={control.field.onChange}
								/>
							)}
						/>
					</View>

					{/* From Custodian Institution */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							defaultValue=""
							name="fromInstitution"
							render={(control) => (
								<Input
									disabled={isFromDisabled()}
									label="From Custodian Institution *"
									status={
										control.formState.errors.fromInstitution
											? 'danger'
											: 'basic'
									}
									testID={FundingDetailsLocators.fromInstitutionField}
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>

					{/* From Account Number */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="fromAccountNumber"
							render={(control) => (
								<Input
									disabled={
										funding?.type === FundingType.TPA_TRANSFER ||
										funding?.type === FundingType.PCA_USD ||
										!isEditing.get ||
										disabledAfterStage()
									}
									label="From Custodian Account Number"
									status={
										control.formState.errors.fromAccountNumber
											? 'danger'
											: 'basic'
									}
									testID={FundingDetailsLocators.fromAccountNumberField}
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={(text) =>
										control.field.onChange(numericStr(text))
									}
								/>
							)}
						/>
					</View>
				</View>
			)}

			{showToInputs && (
				<>
					<View style={styles.main.row}>
						{/* To Account Type */}
						<View style={styles.main.col}>
							<Controller
								control={form.control}
								name="toAccountType"
								render={(control) => (
									<AppSelector
										closeOnSelect
										data={Object.values(AccountType).filter(
											(x) => x !== AccountType.NONE
										)}
										disabled={isToDisabled()}
										itemDisplay={accountTypeHumanizer}
										label="To Account Type *"
										status={
											control.formState.errors.toAccountType
												? 'danger'
												: 'basic'
										}
										testID={FundingDetailsLocators.toAccountTypeField}
										value={control.field.value}
										onSelect={control.field.onChange}
									/>
								)}
							/>
						</View>

						{/* To Custodian Institution */}
						<View style={styles.main.col}>
							<Controller
								control={form.control}
								name="toInstitution"
								render={(control) => (
									<Input
										disabled={isToDisabled()}
										label="To Custodian Institution *"
										status={
											control.formState.errors.toInstitution
												? 'danger'
												: 'basic'
										}
										testID={FundingDetailsLocators.toInstitutionField}
										value={control.field.value}
										onBlur={control.field.onBlur}
										onChangeText={control.field.onChange}
									/>
								)}
							/>
						</View>

						{/* To Account Number */}
						<View style={styles.main.col}>
							<Controller
								control={form.control}
								name="toAccountNumber"
								render={(control) => (
									<Input
										disabled={isToDisabled()}
										label="To Custodian Account Number *"
										status={
											control.formState.errors.toAccountNumber
												? 'danger'
												: 'basic'
										}
										testID={FundingDetailsLocators.toAccountNumberField}
										value={control.field.value}
										onBlur={control.field.onBlur}
										onChangeText={(text) =>
											control.field.onChange(numericStr(text))
										}
									/>
								)}
							/>
						</View>
					</View>
				</>
			)}

			<View style={styles.main.row}>
				{formWatch.fundingType === FundingType.CONTRIBUTION && (
					<>
						<View style={styles.main.col}>
							{/* Contribution Year */}
							<Controller
								control={form.control}
								name="contributionYear"
								render={(control) => (
									<AppSelector
										closeOnSelect
										data={getContributionYear()}
										disabled={!isAdmin || !isEditing.get}
										label="Contribution Year *"
										status={
											control.formState.errors.contributionYear
												? 'danger'
												: 'basic'
										}
										testID={FundingDetailsLocators.contributionYearField}
										value={control.field.value}
										onSelect={control.field.onChange}
									/>
								)}
							/>
						</View>
						{/* Contribution Type */}
						<View style={styles.main.col}>
							<Controller
								control={form.control}
								name="contributionType"
								render={(control) => (
									<AppSelector
										closeOnSelect
										data={Object.keys(ContributionType).filter(
											(x) => x !== ContributionType.NONE
										)}
										disabled={isFormDisabledBase || !isEditing.get}
										label="Contribution Type *"
										status={
											control.formState.errors.contributionYear
												? 'danger'
												: 'basic'
										}
										testID={FundingDetailsLocators.contributionYearField}
										value={control.field.value}
										onSelect={control.field.onChange}
									/>
								)}
							/>
						</View>
						<View style={styles.main.col} />
					</>
				)}
			</View>

			{funding?.fundingAssets?.length === 1 && (
				<View style={styles.main.row}>
					{funding?.type && (
						<View style={styles.main.col}>
							<Controller
								control={form.control}
								name="requestedAmount"
								render={(control) => (
									<Input
										disabled
										label="Requested Amount"
										value={control.field.value!}
									/>
								)}
							/>
						</View>
					)}

					{props?.fundingDetails?.type === FundingType?.REFUND_CONTRIBUTION && (
						<View style={styles.main.col}>
							<Input
								disabled
								label="Correct Funding ID"
								value={props?.fundingDetails?.associatedFundingId?.toString()}
							/>
						</View>
					)}

					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="totalAmount"
							render={(control) => (
								<Input
									disabled={!isEditing.get || disabledAfterStage()}
									label="Total Amount"
									value={control.field.value!}
									status={
										control.formState.errors.totalAmount ? 'danger' : 'basic'
									}
									onBlur={control.field.onBlur}
									onChange={control.field.onChange}
								/>
							)}
						/>
					</View>

					{funding?.type !== FundingType.TPA_TRANSFER && (
						<View style={styles.main.col}>
							<Controller
								control={form.control}
								name="assetType"
								render={(control) => (
									<Input
										disabled={
											funding?.type === FundingType.CONTRIBUTION ||
											funding?.type === FundingType.PCA_USD ||
											!isEditing.get ||
											disabledAfterStage()
										}
										label="Asset Type"
										value={control.field.value}
										onBlur={control.field.onBlur}
										onChangeText={(text: string) => {
											control.field.onChange(text.toUpperCase());
										}}
									/>
								)}
							/>
						</View>
					)}

					{funding?.type === FundingType.TPA_TRANSFER && (
						<>
							<View style={styles.main.col}>
								<Controller
									control={form.control}
									name="currencyType"
									render={(control) => (
										<AppSelector
											closeOnSelect
											disabled={
												funding?.type === FundingType.TPA_TRANSFER ||
												disabledAfterStage()
											}
											data={
												formWatch.fundingType === FundingType.REWARD ||
												formWatch.fundingType ===
													FundingType.REFUND_CONTRIBUTION
													? [CurrencyType.USD]
													: getCurrencyType()
											}
											itemDisplay={(data) => humanize(data, true)}
											label="Currency Type"
											status={
												control.formState.errors?.currencyType
													? 'danger'
													: 'basic'
											}
											testID={FundingDetailsLocators.currencyType}
											value={control.field.value}
											onSelect={control.field.onChange}
										/>
									)}
								/>
							</View>
						</>
					)}

					{funding?.type !== FundingType.REFUND_CONTRIBUTION &&
						funding?.type !== FundingType.TPA_TRANSFER && (
							<View style={styles.main.col}>
								<Controller
									control={form.control}
									name="transactionCode"
									render={(control) => (
										<Input
											disabled={
												props?.fundingDetails?.type ===
													FundingType.PCA_USD ||
												!isEditing.get ||
												disabledAfterStage()
											}
											label="Transaction Code"
											maxLength={2}
											status={
												control.formState.errors.transactionCode
													? 'danger'
													: 'basic'
											}
											testID={FundingDetailsLocators.transactionCodeField}
											value={control.field.value!}
											onBlur={control.field.onBlur}
											onChangeText={control.field.onChange}
										/>
									)}
								/>
							</View>
						)}
				</View>
			)}

			<AppAlert
				actions={[
					{
						title: 'OK',
						status: 'danger',
						appearance: 'filled',
						onPress: () => {
							showError.set(false);
						},
					},
				]}
				message={errorMessage.get}
				title="Invalid Entry"
				visible={showError.get}
				xmlIcon={DangerAssetSVG}
			/>
		</CustomCard>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			header: {
				flexDirection: 'row',
				alignItems: 'center',
			},
			separator: {
				paddingHorizontal: 24,
			},
			row: {
				flexDirection: 'row',
				alignItems: 'center',
				marginVertical: 8,
				marginHorizontal: -8,
			},
			col: {
				flex: 1,
				marginHorizontal: 8,
			},
		}),
		actions: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
			},
			leftButton: {
				marginRight: 16,
			},
		}),
	};
}

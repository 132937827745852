import { CustomersScreen_GetUsers_users_nodes as Customer, UserStatus } from '@app/codegen';
import { CustomerScreenLocators } from '@app/e2e/screens/Customers';
import { useCustomersNavigation } from '@app/navigations/Main/Customers';
import {
	CustomDataTable,
	ITrustColumn,
	ITrustAction,
	DangerAssetSVG,
} from '@app/shared/components';
import { AppAlert, maskPhoneNumber, localDateFormat } from '@itrustcapital/ui';
import React from 'react';
import Toast from 'react-native-toast-message';

import { useCustomersScreenUserUpdateMutation } from '../CustomersScreenGraphql';
import { useCustomersScreenContext } from '../CustomersScreenProvider';

export function CustomersTable() {
	const context = useCustomersScreenContext();
	const customersNavigation = useCustomersNavigation();
	const [onUpdateUser, updateUserQuery] = useCustomersScreenUserUpdateMutation();

	const dynamicColumns: ITrustColumn<Customer>[] =
		context.tableState.where.get?.status?.eq === UserStatus.LEAD
			? [
					{
						key: 'email',
						name: 'Email',
						minWidth: 300,
						sortable: true,
						selector: (row) => row.email,
					},
			  ]
			: [
					{
						key: 'firstName',
						name: 'First Name',
						minWidth: 200,
						sortable: true,
						selector: (row) => row.firstName,
					},
					{
						key: 'lastName',
						name: 'Last Name',
						minWidth: 200,
						sortable: true,
						selector: (row) => row.lastName,
					},
					{
						key: 'email',
						name: 'Email',
						minWidth: 300,
						sortable: true,
						selector: (row) => row.email,
					},
					{
						key: 'phone',
						name: 'Phone',
						maxWidth: 120,
						sortable: true,
						selector: (row) => maskPhoneNumber(row.phone),
					},
			  ];

	const columns: ITrustColumn<Customer>[] = [
		...dynamicColumns,
		{
			name: 'Accounts',
			key: 'accounts',
			maxWidth: 100,
			center: true,
			sortable: false,
			selector: (row) => row.accounts?.length || 0,
		},
		{
			name: 'Created Date',
			key: 'createdAt',
			maxWidth: 130,
			sortable: true,
			selector: (row) => localDateFormat(row.createdAt),
		},
	];

	const actions: ITrustAction<Customer>[] = [
		{
			name: 'Edit',
			iconLib: 'matc',
			iconName: 'pencil',
			handler: (row) => customersNavigation.Edit({ customerId: row.id }),
		},
		{
			name: 'Delete',
			iconLib: 'ion',
			iconName: 'trash',
			handler: (row) => {
				context.selectedRow.set(row);
				context.deleteVisible.set(!context.deleteVisible.get);
			},
		},
	];

	const customerName: string =
		context.selectedRow.get?.firstName && context.selectedRow.get?.lastName
			? ` ${context.selectedRow.get?.firstName} ${context.selectedRow.get?.lastName}`
			: '';

	async function onDeleteSubmit() {
		try {
			await onUpdateUser({
				variables: {
					input: {
						id: context.selectedRow.get?.id || 0,
						status: UserStatus.DELETED,
					},
				},
			});

			context.deleteVisible.set(false);
			context.customersQuery?.refetch();
			Toast.show({
				text2: 'Customer has been deleted',
				type: 'success',
			});
		} catch (error) {
			Toast.show({
				text2: 'Customer failed to delete',
				type: 'error',
			});
		}
	}

	return (
		<>
			<AppAlert
				actions={[
					{
						title: 'Cancel',
						onPress: () => {
							context.deleteVisible.set(false);
							context.selectedRow.set(null);
						},
						testID: CustomerScreenLocators.deleteCustomerModalCancelButton,
					},
					{
						title: 'Delete',
						status: 'danger',
						loading: updateUserQuery.loading,
						onPress: onDeleteSubmit,
						testID: CustomerScreenLocators.deleteCustomerModalConfirmButton,
					},
				]}
				message={`Customer${customerName} will be deleted`}
				title="Are you sure?"
				visible={context.deleteVisible.get}
				xmlIcon={DangerAssetSVG}
			/>
			<CustomDataTable
				columns={columns}
				data={context.customersQuery.data?.users?.nodes as Customer[]}
				progressPending={context.customersQuery.loading}
				refetch={(variables: any) => {
					context.customersQuery.refetch({
						...variables,
						where: context.tableState.where.get,
					});
				}}
				rowActions={actions}
				{...context.tableState.props}
			/>
		</>
	);
}

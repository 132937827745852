import { gql, useMutation } from '@apollo/client';
import {
	EditAccountScreenGetAccountDetails_account_user,
	OrganismCicUpdateUser,
	OrganismCicUpdateUserVariables,
	AddressType,
	EcsGetUserDetails_user,
	UserStatus,
	UpdateUserInput,
} from '@app/codegen';
import { CustomerInformationComponentLocators } from '@app/e2e/shared/CustomerInformation';
import { CustomCard } from '@app/shared/components';
import { States } from '@app/shared/models/States';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	AppAlert,
	AppIcon,
	AppSelector,
	maskDate,
	maskPhoneNumber,
	maskSocial,
	validatePhone,
	ScreenSizeEnum,
	useAppDevice,
	useAppState,
	formatSocial,
	useAppTheme,
	dateFormat,
	dateToUTC,
} from '@itrustcapital/ui';
import { Button, Text, CheckBox, Input } from '@ui-kitten/components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import Toast from 'react-native-toast-message';
import * as yup from 'yup';

export const ORGANISM_CIC_UPDATE_USER = gql`
	mutation OrganismCicUpdateUser($input: UpdateUserInput!) {
		updateUser(input: $input) {
			success
			errorMessage
		}
	}
`;

type UserInfo = EcsGetUserDetails_user | EditAccountScreenGetAccountDetails_account_user;

export interface CustomerInformationProps {
	userInfo?: UserInfo | null;
	loading?: boolean;
}

export const schema = yup
	.object({
		id: yup.string().default(''),
		fortressIdentityId: yup.string().default(''),
		firstName: yup.string().default(''),
		lastName: yup.string().default(''),
		middleName: yup.string().default(''),
		email: yup.string().email().default('').required(),
		dateOfBirth: yup.string().when('userInfo', {
			is: (userInfo: UserInfo) => !!userInfo?.dateOfBirth,
			then: yup.string().required(),
			otherwise: yup.string().default(''),
		}),
		socialSecurityNumber: yup.string().when('userInfo', {
			is: (userInfo: UserInfo) => !!userInfo?.socialSecurityNumber,
			then: yup.string().required(),
			otherwise: yup.string().default(''),
		}),
		street: yup.string().default(''),
		street2: yup.string().default(''),
		city: yup.string().default(''),
		state: yup.string().oneOf(Object.keys(States)).default(''),
		zipCode: yup.string().default(''),
		phone: validatePhone.required(),
		priorNames: yup.string().default(''),
	})
	.defined();

export type CustomerInformationForm = yup.TypeOf<typeof schema> & {
	userInfo: UserInfo;
};

export function CustomerInformation(props: CustomerInformationProps) {
	const styles = useCustomStyles();
	const isEditing = useAppState(false);
	const freezeModalVisible = useAppState(false);
	const socialSecurityVisible = useAppState(false);
	const defaultValues = schema.cast({
		userInfo: props?.userInfo,
	});

	const customerInformationForm = useForm<CustomerInformationForm>({
		mode: 'all',
		resolver: yupResolver(schema),
		defaultValues,
	});

	const [onUpdateUser, updateUserQuery] = useMutation<
		OrganismCicUpdateUser,
		OrganismCicUpdateUserVariables
	>(ORGANISM_CIC_UPDATE_USER, {
		refetchQueries: ['EcsGetUserDetails'],
	});

	const isFrozen = props.userInfo?.status === UserStatus.DISABLED;

	React.useEffect(() => {
		if (!props.userInfo) {
			customerInformationForm.reset(defaultValues);

			return;
		}

		prefillForm(props.userInfo);
	}, [props.userInfo]);

	React.useEffect(() => {
		customerInformationForm.trigger();
	}, [isEditing.get]);

	function prefillForm(userInfo: UserInfo) {
		if (!userInfo) {
			return;
		}

		const contactAddress = userInfo.addresses?.find(
			(address) => address?.type === AddressType.NONE
		);

		customerInformationForm.reset({
			id: userInfo.id ? userInfo.id.toString() : undefined,
			fortressIdentityId: userInfo.fortressIdentityId
				? userInfo.fortressIdentityId?.toString()
				: undefined,
			email: userInfo.email,
			firstName: userInfo.firstName || '',
			lastName: userInfo.lastName || '',
			middleName: userInfo.middleName || '',
			dateOfBirth: dateFormat(userInfo.dateOfBirth) || '',
			phone: maskPhoneNumber(userInfo.phone),
			socialSecurityNumber: formatSocial(userInfo.socialSecurityNumber!),
			city: contactAddress?.city || '',
			state: contactAddress?.state || '',
			street: contactAddress?.address || '',
			street2: contactAddress?.address2 || '',
			zipCode: contactAddress?.zipCode || '',
			priorNames: userInfo.priorNames || '',
			userInfo,
		});
	}

	function successToaster(success: boolean): void {
		Toast.show({
			type: success ? 'success' : 'error',
			text2: `Customer information ${success ? 'has been updated' : 'failed to update'}`,
		});
	}

	function freezeToaster(success: boolean, frozen: boolean): void {
		Toast.show({
			type: success ? 'success' : 'error',
			text2: `Customer information ${
				success
					? `has been ${frozen ? 'unfrozen' : 'frozen'}`
					: `failed to ${frozen ? 'unfreeze' : 'freeze'}`
			}`,
		});
	}

	async function onSubmit(data: CustomerInformationForm) {
		const {
			firstName: initialFristName,
			middleName: initialMiddleName,
			lastName: initialLastName,
			priorNames: initialPriorName,
			dateOfBirth: initialDateOfBirth,
			socialSecurityNumber: intialSecurityNumber,
			email: initialEmail,
			phone: initialMobilePhone,
			addresses: initialAddresses,
		} = props?.userInfo || {};

		const input: UpdateUserInput = {};

		const contactAddress = initialAddresses?.find(
			(address) => address?.type === AddressType.NONE
		);

		const {
			street: streetUpdate,
			street2: street2Update,
			city: cityUpdate,
			state: stateUpdate,
			zipCode: zipCodeUpdate,
		} = data;

		if (
			contactAddress &&
			(contactAddress.address !== streetUpdate ||
				contactAddress.address2 !== street2Update ||
				contactAddress.city !== cityUpdate ||
				contactAddress.state !== stateUpdate ||
				contactAddress.zipCode !== zipCodeUpdate)
		) {
			input.addresses = [
				{
					address: streetUpdate,
					address2: street2Update,
					type: AddressType.NONE,
					city: cityUpdate,
					state: stateUpdate,
					zipCode: zipCodeUpdate,
				},
			];
		}

		if (data?.firstName !== initialFristName) {
			input.firstName = data?.firstName;
		}

		if (data?.middleName !== initialMiddleName) {
			input.middleName = data?.middleName;
		}

		if (data?.lastName !== initialLastName) {
			input.lastName = data?.lastName;
		}

		if (data?.priorNames !== initialPriorName) {
			input.priorNames = data?.priorNames;
		}

		const dateUpdate = data?.dateOfBirth ? new Date(data?.dateOfBirth) : null;
		const initialDate = initialDateOfBirth ? new Date(initialDateOfBirth) : null;

		if (
			(dateUpdate && !initialDate) ||
			(initialDate && dateUpdate && dateUpdate.getTime() !== initialDate.getTime())
		) {
			input.dateOfBirth = dateUpdate ? dateToUTC(data.dateOfBirth) : null;
		}

		const ssnUpdate = data?.socialSecurityNumber?.replace(/\D/g, '');

		if (
			(ssnUpdate && !intialSecurityNumber) ||
			(intialSecurityNumber && ssnUpdate && ssnUpdate !== intialSecurityNumber)
		) {
			input.socialSecurityNumber = ssnUpdate;
		}

		if (data?.email !== initialEmail) {
			input.email = data?.email;
		}

		const mobilePhoneValue = data?.phone.replace(/\D/g, '');

		if (mobilePhoneValue !== initialMobilePhone) {
			input.phone = mobilePhoneValue;
		}

		if (Object.values(input)?.length) {
			input.id = props?.userInfo?.id;
		} else {
			return isEditing.set(false);
		}

		try {
			await onUpdateUser({ variables: { input } });

			isEditing.set(!isEditing.get);
			successToaster(true);
		} catch (error) {
			successToaster(false);
		}
	}

	async function onFreezeSubmit(): Promise<void> {
		try {
			await onUpdateUser({
				variables: {
					input: {
						id: props.userInfo?.id,
						status: isFrozen ? UserStatus.ACTIVE : UserStatus.DISABLED,
					},
				},
			});
			freezeModalVisible.set(false);
			freezeToaster(true, isFrozen);
		} catch (error) {
			freezeToaster(false, isFrozen);
		}
	}

	async function onToggleNotifications(): Promise<void> {
		try {
			await onUpdateUser({
				variables: {
					input: {
						id: props.userInfo?.id,
						enableNotifications: !props.userInfo?.enableNotifications,
					},
				},
			});

			successToaster(true);
		} catch (error) {
			successToaster(false);
		}
	}

	return (
		<>
			<CustomCard
				footer={() => (
					<View style={styles.actions.row}>
						<View style={styles.actions.notifications}>
							<CheckBox
								checked={props.userInfo?.enableNotifications}
								onChange={onToggleNotifications}
							/>
							<Text category="s2" style={styles.actions.text}>
								Receive Email/SMS notifications?
							</Text>
						</View>
					</View>
				)}
				header={() => (
					<View style={styles.main.header}>
						<Text category="h6">Customer Information</Text>
						<View>
							{isEditing.get ? (
								<View style={styles.actions.container}>
									<Button
										appearance="outline"
										style={styles.actions.leftButton}
										testID={
											CustomerInformationComponentLocators.customerCancelButton
										}
										onPress={() => {
											isEditing.set(false);
											customerInformationForm.reset();
											// TODO: remove timeout hack
											setTimeout(() => {
												if (props.userInfo) {
													prefillForm(props.userInfo);
												}
											});
										}}
									>
										Cancel
									</Button>
									<Button
										style={styles.actions.save}
										testID={
											CustomerInformationComponentLocators.customerSaveButton
										}
										onPress={
											updateUserQuery.loading
												? undefined
												: customerInformationForm.handleSubmit(onSubmit)
										}
										// disabled={!customerInformationForm.formState.isValid}
									>
										Save
									</Button>
								</View>
							) : (
								<>
									<View style={styles.actions.container}>
										<Button
											status="warning"
											style={styles.actions.leftButton}
											testID={
												CustomerInformationComponentLocators.customerFreezeButton
											}
											onPress={() => freezeModalVisible.set(true)}
										>
											{isFrozen ? 'Unfreeze Customer' : 'Freeze Customer'}
										</Button>
										<Button
											testID={
												CustomerInformationComponentLocators.customerEditButton
											}
											onPress={() => isEditing.set(!isEditing.get)}
										>
											Edit
										</Button>
									</View>
								</>
							)}
						</View>
					</View>
				)}
				loading={props.loading || updateUserQuery.loading}
			>
				<AppAlert
					actions={[
						{
							testID: CustomerInformationComponentLocators.customerFreezeCancelButton,
							title: 'Cancel',
							status: 'basic',
							onPress: () => freezeModalVisible.set(false),
						},
						{
							testID: CustomerInformationComponentLocators.customerFreezeSaveButton,
							title: isFrozen ? 'Unfreeze' : 'Freeze',
							loading: updateUserQuery.loading,
							onPress: onFreezeSubmit,
							status: isFrozen ? 'primary' : 'danger',
						},
					]}
					message={`Are you sure you want to ${
						isFrozen ? 'unfreeze' : 'freeze'
					} this customer?`}
					title={`${isFrozen ? 'Unfreeze' : 'Freeze'} customer?`}
					visible={freezeModalVisible.get}
				/>

				<View style={styles.main.content}>
					<View style={styles.main.row}>
						<View style={styles.main.col}>
							<Text category="s2" style={styles.main.subtitle}>
								PERSONAL INFORMATION
							</Text>
						</View>
					</View>
					<View style={styles.main.row}>
						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="fortressIdentityId"
								render={(control) => (
									<Input
										disabled
										label="Fortress Identity ID"
										testID={
											CustomerInformationComponentLocators.customerFortressIdentityIdField
										}
										value={control.field.value}
									/>
								)}
							/>
						</View>
						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="id"
								render={(control) => (
									<Input
										disabled
										label="User ID"
										testID={
											CustomerInformationComponentLocators.customerIdField
										}
										value={control.field.value}
									/>
								)}
							/>
						</View>
					</View>
					<View style={styles.main.row}>
						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="firstName"
								render={(control) => (
									<Input
										disabled={!isEditing.get}
										label="Legal First Name"
										status={control.fieldState.error && 'danger'}
										testID={
											CustomerInformationComponentLocators.customerFirstNameField
										}
										textContentType="givenName"
										value={control.field.value}
										onBlur={control.field.onBlur}
										onChangeText={control.field.onChange}
									/>
								)}
							/>
						</View>
						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="middleName"
								render={(control) => (
									<Input
										disabled={!isEditing.get}
										label="Legal Middle Name"
										status={control.fieldState.error && 'danger'}
										testID={
											CustomerInformationComponentLocators.customerMiddleNameField
										}
										textContentType="middleName"
										value={control.field.value}
										onBlur={control.field.onBlur}
										onChangeText={control.field.onChange}
									/>
								)}
							/>
						</View>
						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="lastName"
								render={(control) => (
									<Input
										disabled={!isEditing.get}
										label="Legal Last Name"
										status={control.fieldState.error && 'danger'}
										testID={
											CustomerInformationComponentLocators.customerLastNameField
										}
										textContentType="familyName"
										value={control.field.value}
										onBlur={control.field.onBlur}
										onChangeText={control.field.onChange}
									/>
								)}
							/>
						</View>
						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="priorNames"
								render={(control) => (
									<Input
										disabled={!isEditing.get}
										label="Prior Name or Alias"
										status={control.fieldState.error && 'danger'}
										testID={
											CustomerInformationComponentLocators.customerPriorNameField
										}
										value={control.field.value}
										onBlur={control.field.onBlur}
										onChangeText={control.field.onChange}
									/>
								)}
							/>
						</View>
					</View>

					<View style={styles.main.row}>
						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="dateOfBirth"
								render={(control) => (
									<View>
										<Input
											disabled={!isEditing.get}
											label="Date of Birth *"
											placeholder="mm/dd/yyyy"
											status={control.fieldState.error && 'danger'}
											testID={
												CustomerInformationComponentLocators.customerDateOfBirthField
											}
											value={control.field.value}
											onBlur={control.field.onBlur}
											onChangeText={(text) =>
												control.field.onChange(maskDate(text))
											}
										/>
									</View>
								)}
							/>
						</View>

						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="socialSecurityNumber"
								render={(control) => (
									<Input
										accessoryRight={() => (
											<TouchableOpacity
												onPress={() =>
													socialSecurityVisible.set(
														!socialSecurityVisible.get
													)
												}
											>
												<AppIcon
													lib="ion"
													name={
														socialSecurityVisible.get
															? 'eye-off'
															: 'eye-outline'
													}
													size={20}
													style={styles.main.icon}
												/>
											</TouchableOpacity>
										)}
										disabled={!isEditing.get}
										label="Social Security Number"
										placeholder="***-**-****"
										secureTextEntry={!socialSecurityVisible.get}
										status={control.fieldState.error && 'danger'}
										testID={
											CustomerInformationComponentLocators.customerSocialSecurityNumberField
										}
										textStyle={styles.main.inputSsn}
										value={control.field.value}
										onBlur={control.field.onBlur}
										onChangeText={(text) => {
											control.field.onChange(maskSocial(text));
										}}
									/>
								)}
							/>
						</View>
					</View>

					<View style={styles.main.row}>
						<View style={styles.main.col}>
							<Text category="s2" style={styles.main.subtitle}>
								CONTACT INFORMATION
							</Text>
						</View>
					</View>

					<View style={styles.main.row}>
						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="email"
								render={(control) => (
									<Input
										disabled={!isEditing.get}
										label="Email"
										status={control.fieldState.error && 'danger'}
										testID={
											CustomerInformationComponentLocators.customerEmailField
										}
										textContentType="emailAddress"
										value={control.field.value}
										onBlur={control.field.onBlur}
										onChangeText={control.field.onChange}
									/>
								)}
							/>
						</View>
						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="phone"
								render={(control) => (
									<Input
										disabled={!isEditing.get}
										label="Mobile Phone"
										status={control.formState.errors.phone ? 'danger' : 'basic'}
										testID={
											CustomerInformationComponentLocators.customerMobilePhoneField
										}
										value={control.field.value}
										onBlur={control.field.onBlur}
										onChange={(e) => {
											const formatted = maskPhoneNumber(e);
											control.field.onChange(formatted);
										}}
									/>
								)}
							/>
						</View>
						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="street"
								render={(control) => (
									<Input
										disabled={!isEditing.get}
										label="Address #1"
										status={control.fieldState.error && 'danger'}
										testID={
											CustomerInformationComponentLocators.customerStreetField
										}
										textContentType="streetAddressLine1"
										value={control.field.value}
										onBlur={control.field.onBlur}
										onChangeText={control.field.onChange}
									/>
								)}
							/>
						</View>
					</View>
					<View style={styles.main.row}>
						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="street2"
								render={(control) => (
									<Input
										disabled={!isEditing.get}
										label="Address #2"
										status={control.fieldState.error && 'danger'}
										testID={
											CustomerInformationComponentLocators.customerStreet2Field
										}
										textContentType="streetAddressLine2"
										value={control.field.value}
										onBlur={control.field.onBlur}
										onChangeText={control.field.onChange}
									/>
								)}
							/>
						</View>
						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="city"
								render={(control) => (
									<Input
										disabled={!isEditing.get}
										label="City"
										status={control.fieldState.error && 'danger'}
										testID={
											CustomerInformationComponentLocators.customerCityField
										}
										textContentType="addressCity"
										value={control.field.value}
										onBlur={control.field.onBlur}
										onChangeText={control.field.onChange}
									/>
								)}
							/>
						</View>
						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="state"
								render={(control) => (
									<AppSelector
										closeOnSelect
										data={Object.keys(States)}
										disabled={!isEditing.get}
										label="State/Territory"
										status={control.fieldState.error && 'danger'}
										testID={
											CustomerInformationComponentLocators.customerStateField
										}
										value={control.field.value!}
										onSelect={control.field.onChange}
									/>
								)}
							/>
						</View>
						<View style={styles.main.col}>
							<Controller
								control={customerInformationForm.control}
								name="zipCode"
								render={(control) => (
									<Input
										disabled={!isEditing.get}
										label="Zip Code"
										status={control.fieldState.error && 'danger'}
										testID={
											CustomerInformationComponentLocators.customerZipCodeField
										}
										textContentType="postalCode"
										value={control.field.value}
										onBlur={control.field.onBlur}
										onChangeText={control.field.onChange}
									/>
								)}
							/>
						</View>
					</View>
				</View>
			</CustomCard>
		</>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();
	const appDevice = useAppDevice();

	const isScreenMd = appDevice.width >= ScreenSizeEnum.md;

	return {
		main: StyleSheet.create({
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
			content: {
				marginBottom: 12,
				flex: 1,
			},
			row: {
				flexDirection: 'row',
				alignItems: 'center',
				marginVertical: 8,
				marginHorizontal: -8,
			},
			col: {
				flex: 1,
				marginHorizontal: 8,
			},
			input: {
				width: isScreenMd ? '33%' : '100%',
				paddingTop: 6,
				paddingHorizontal: 6,
			},
			icon: {
				color: theme['color-basic-600'],
			},
			subtitle: {
				marginVertical: 4,
				color: theme['color-basic-600'],
			},
			inputSsn: {
				width: '100%',
			},
		}),
		actions: StyleSheet.create({
			row: {
				flexDirection: 'row',
			},
			container: {
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'flex-end',
				alignItems: 'center',
				paddingRight: 6,
			},
			notifications: {
				flex: 1,
				flexDirection: 'row',
				alignItems: 'flex-start',
				marginTop: 10,
			},
			text: {
				marginLeft: 4,
			},
			leftButton: {
				marginRight: 12,
			},
			icon: {
				color: theme['text-alternate-color'],
			},
			save: {
				minWidth: 70,
			},
		}),
	};
}

import {
	CustomersScreen_GetUsers_users_nodes as Customer,
	UserFilterInput,
	UserSortInput,
	SystemRole,
	GetUsersInput,
	SortEnumType,
	UserStatus,
	CustomersScreen_GetUsersVariables,
} from '@app/codegen';
import { useTableState } from '@app/shared/components';
import { isFormEmpty } from '@app/shared/helpers';
import { useAppState } from '@itrustcapital/ui';
import { endOfDay } from 'date-fns';
import React from 'react';

import { CustomersScreenFilterForm, useCustomersScreenFilterForm } from './CustomersScreenForms';
import { useCustomersScreenUsersQuery } from './CustomersScreenGraphql';

export type CustomersScreenState = ReturnType<typeof useCustomersScreenState>;

const CustomersScreenContext = React.createContext<CustomersScreenState | undefined>(undefined);

export function CustomersScreenProvider(props: CustomersScreenProviderProps | null) {
	return (
		<CustomersScreenContext.Provider value={props?.value}>
			{props?.children}
		</CustomersScreenContext.Provider>
	);
}

export function useCustomersScreenContext() {
	const context = React.useContext(CustomersScreenContext);

	if (context === undefined) {
		throw new Error('useCounterContext must be used within a CustomersScreenProvider');
	}

	return context;
}

export type CustomersScreenProviderProps = {
	value: CustomersScreenState | undefined;
	children: React.ReactNode;
};

export function useCustomersScreenState() {
	const filterCustomersForm = useCustomersScreenFilterForm();
	const { setError } = filterCustomersForm;

	const defaultWhere: UserFilterInput = {
		role: { eq: SystemRole.CUSTOMER },
		status: { eq: UserStatus.ACTIVE },
	};
	const pauseQuery = useAppState(false);

	const inputState = useAppState<GetUsersInput | null>(null);
	const tableState = useTableState<UserSortInput, UserFilterInput>({
		defaultWhere,
		defaultSort: { createdAt: SortEnumType.DESC },
		pagination: true,
		striped: true,
	});
	const deleteVisible = useAppState(false);
	const selectedRow = useAppState<Customer | null>(null);

	const variables: CustomersScreen_GetUsersVariables = {
		where: tableState.where.get,
		order: tableState.sort.get,
		first: tableState.rowsPerPage.get,
		input: inputState.get,
	};

	const customersQuery = useCustomersScreenUsersQuery({
		variables,
		onCompleted: (data) => {
			tableState.onCompleted(data.users?.totalCount, data.users?.pageInfo);
		},
		skip: pauseQuery.get,
	});

	const resetTable = () => {
		tableState.where.set(undefined);
		inputState.set(null);
		pauseQuery.set(false);
	};

	function onSubmit(data: CustomersScreenFilterForm) {
		pauseQuery.set(true);
		const where: UserFilterInput = defaultWhere;
		const input: GetUsersInput = {};

		tableState.reset();

		if (isFormEmpty(data)) {
			resetTable();

			return;
		}

		if (!data.search) {
			resetTable();

			return;
		}

		if (data.search) {
			const searchTrimmed = data.search.trim();
			const andConditions: any[] = [];

			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
			const numericalIdRegex = /^\d{1,7}$/;
			const nameRegex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/; // Matches names with optional spaces.

			if (emailRegex.test(searchTrimmed)) {
				andConditions.push({
					email: {
						eq: searchTrimmed,
					},
				});
			} else if (phoneRegex.test(searchTrimmed)) {
				const digitOnlySearch = searchTrimmed.replaceAll(/\D/g, '');
				andConditions.push({
					phone: {
						eq: digitOnlySearch,
					},
				});
			} else if (numericalIdRegex.test(searchTrimmed)) {
				const digitOnlySearch = searchTrimmed.replaceAll(/\D/g, '');
				andConditions.push({
					id: {
						eq: +digitOnlySearch,
					},
				});
			} else if (nameRegex.test(searchTrimmed)) {
				searchTrimmed.split(' ').forEach((term) => {
					andConditions.push({
						or: [
							{
								firstName: {
									contains: term,
								},
							},
							{
								lastName: {
									contains: term,
								},
							},
						],
					});
				});
			}

			if (andConditions.length > 0) {
				where.and = andConditions;
			} else {
				setError('search', {
					message: 'Invalid search text',
				});

				pauseQuery.set(true);

				return;
			}

			filterCustomersForm.setValue('search', searchTrimmed);
		}

		if (data.createdTo) {
			where.createdAt = {
				lte: endOfDay(data.createdTo).toISOString(),
			};
		}

		if (data.createdFrom) {
			where.createdAt = {
				...where.createdAt,
				gte: data.createdFrom.toISOString(),
			};
		}

		if (data.phone) {
			where.phone = {
				eq: data.phone.replaceAll(/[-()\s]/g, ''),
			};
		}

		if (data.status) {
			if (data.status === 'ALL') {
				where.status = {
					in: [
						UserStatus.ACTIVE,
						UserStatus.DISABLED,
						UserStatus.LEAD,
						UserStatus.AUTH_VERIFY,
						UserStatus.EMAIL_VERIFY,
					],
				};
			} else {
				where.status = {
					eq: data.status,
				};
			}
		}

		if (data.fundedDateFrom) {
			where.accounts = {
				some: {
					fundedAt: {
						gte: data.fundedDateFrom.toISOString(),
					},
				},
			};
		}

		if (data.fundedDateTo) {
			where.accounts = {
				some: {
					fundedAt: {
						...where.accounts?.some?.fundedAt,
						lte: endOfDay(data.fundedDateTo).toISOString(),
					},
				},
			};
		}

		inputState.set(input);
		tableState.where.set(where);
		pauseQuery.set(false);
	}

	return {
		tableState,
		customersQuery,
		deleteVisible,
		selectedRow,
		filterCustomersForm,
		pauseQuery,
		onSubmit,
	};
}

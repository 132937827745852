/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountOrder {
  IRA = "IRA",
  NONE = "NONE",
  PCA = "PCA",
  TPA = "TPA",
}

export enum AccountStage {
  ACCOUNT_ACTIVATED = "ACCOUNT_ACTIVATED",
  CLOSED = "CLOSED",
  DOCUMENTS_PENDING = "DOCUMENTS_PENDING",
  DOCUMENTS_SENT_TO_CLIENT = "DOCUMENTS_SENT_TO_CLIENT",
  DOCUMENTS_SENT_TO_CUSTODIAN = "DOCUMENTS_SENT_TO_CUSTODIAN",
  DOCUMENTS_SIGNED = "DOCUMENTS_SIGNED",
  NONE = "NONE",
  ONBOARDING_ACCOUNT_TYPE = "ONBOARDING_ACCOUNT_TYPE",
  ONBOARDING_BENEFICIARIES = "ONBOARDING_BENEFICIARIES",
  ONBOARDING_CLIENT_AGREEMENTS = "ONBOARDING_CLIENT_AGREEMENTS",
  ONBOARDING_CONTACT_INFORMATION = "ONBOARDING_CONTACT_INFORMATION",
  ONBOARDING_FUNDING = "ONBOARDING_FUNDING",
  ONBOARDING_FUNDING_TYPE = "ONBOARDING_FUNDING_TYPE",
  ONBOARDING_GOVERNMENT_ID = "ONBOARDING_GOVERNMENT_ID",
  ONBOARDING_KYC = "ONBOARDING_KYC",
  ONBOARDING_PERSONAL_INFORMATION = "ONBOARDING_PERSONAL_INFORMATION",
  VERIFIED = "VERIFIED",
  WIZARD_STARTED = "WIZARD_STARTED",
}

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  DELINQUENT = "DELINQUENT",
  DISABLED = "DISABLED",
  INTERNAL = "INTERNAL",
  IN_REVIEW = "IN_REVIEW",
  NONE = "NONE",
  PENDING = "PENDING",
}

export enum AccountType {
  ANNUITY = "ANNUITY",
  CHECKING = "CHECKING",
  CRYPTO_WALLET = "CRYPTO_WALLET",
  DEVELOPER_TEST = "DEVELOPER_TEST",
  INHERITED_ROTH_IRA = "INHERITED_ROTH_IRA",
  INHERITED_TRADITIONAL_IRA = "INHERITED_TRADITIONAL_IRA",
  NONE = "NONE",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  OTHER = "OTHER",
  PENSION = "PENSION",
  PREMIUM_CUSTODY_ACCOUNT = "PREMIUM_CUSTODY_ACCOUNT",
  ROTH401_K = "ROTH401_K",
  ROTH403_B = "ROTH403_B",
  ROTH457 = "ROTH457",
  ROTH_IRA = "ROTH_IRA",
  ROTH_TSP = "ROTH_TSP",
  SAVINGS = "SAVINGS",
  SEP_IRA = "SEP_IRA",
  SIMPLE_IRA = "SIMPLE_IRA",
  TPA401_K = "TPA401_K",
  TPA_PCA = "TPA_PCA",
  TPA_ROTH_IRA = "TPA_ROTH_IRA",
  TPA_SEP_IRA = "TPA_SEP_IRA",
  TPA_TRADITIONAL_IRA = "TPA_TRADITIONAL_IRA",
  TRADITIONAL401_K = "TRADITIONAL401_K",
  TRADITIONAL403_B = "TRADITIONAL403_B",
  TRADITIONAL457 = "TRADITIONAL457",
  TRADITIONAL_IRA = "TRADITIONAL_IRA",
  TRADITIONAL_TSP = "TRADITIONAL_TSP",
}

export enum AddressType {
  BILLING = "BILLING",
  BIRTH = "BIRTH",
  EMPLOYER = "EMPLOYER",
  NONE = "NONE",
  SHIPPING = "SHIPPING",
}

export enum BeneficiaryDirective {
  NONE = "NONE",
  OPTION_A = "OPTION_A",
  OPTION_B = "OPTION_B",
}

export enum BeneficiaryRelationshipType {
  NONE = "NONE",
  SPOUSE = "SPOUSE",
}

export enum BeneficiaryType {
  CONTINGENT = "CONTINGENT",
  NONE = "NONE",
  PRIMARY = "PRIMARY",
}

export enum ChurnReason {
  BULK_ACCOUNT_CLOSURE = "BULK_ACCOUNT_CLOSURE",
  CLIENT_DECEASED = "CLIENT_DECEASED",
  DISSATISFIED = "DISSATISFIED",
  INCOMPLETE_FUNDING = "INCOMPLETE_FUNDING",
  INCORRECT_ACCOUNT_TYPE = "INCORRECT_ACCOUNT_TYPE",
  INELIGIBLE = "INELIGIBLE",
  LOW_BALANCE = "LOW_BALANCE",
  MARKET_UNCERTAINTY = "MARKET_UNCERTAINTY",
  NONE = "NONE",
  OTHER = "OTHER",
  OVER_CONTRIBUTED = "OVER_CONTRIBUTED",
  RETIREMENT_INCOME = "RETIREMENT_INCOME",
}

export enum ConditionalTransactionStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  EXECUTED = "EXECUTED",
  EXECUTING = "EXECUTING",
  FAILED = "FAILED",
  NONE = "NONE",
}

export enum ConditionalTransactionTriggerPoint {
  HIGHER = "HIGHER",
  LOWER = "LOWER",
  NONE = "NONE",
}

export enum ContributionType {
  NONE = "NONE",
  SELF = "SELF",
  SPOUSE = "SPOUSE",
}

export enum CrmUserStatus {
  ACTIVE = "ACTIVE",
  AUTH_LOGIN = "AUTH_LOGIN",
  AUTH_VERIFY = "AUTH_VERIFY",
  DELETED = "DELETED",
  DISABLED = "DISABLED",
  EMAIL_VERIFY = "EMAIL_VERIFY",
  NONE = "NONE",
}

export enum CurrencyType {
  AAVE = "AAVE",
  ADA = "ADA",
  ALGO = "ALGO",
  APE = "APE",
  APT = "APT",
  ARB = "ARB",
  ATOM = "ATOM",
  AVAX = "AVAX",
  AXS = "AXS",
  BAT = "BAT",
  BCH = "BCH",
  BONK = "BONK",
  BTC = "BTC",
  CHZ = "CHZ",
  COMP = "COMP",
  CRV = "CRV",
  DOGE = "DOGE",
  DOT = "DOT",
  ENJ = "ENJ",
  EOS = "EOS",
  ETH = "ETH",
  FET = "FET",
  FIL = "FIL",
  FLOKI = "FLOKI",
  FLOW = "FLOW",
  FLR = "FLR",
  FTM = "FTM",
  GALA = "GALA",
  GRT = "GRT",
  HBAR = "HBAR",
  HNT = "HNT",
  ICP = "ICP",
  IMX = "IMX",
  INJ = "INJ",
  KMG = "KMG",
  KMS = "KMS",
  LDO = "LDO",
  LINK = "LINK",
  LRC = "LRC",
  LTC = "LTC",
  MANA = "MANA",
  MATIC = "MATIC",
  METIS = "METIS",
  MKR = "MKR",
  MOO_DENG = "MOO_DENG",
  NEAR = "NEAR",
  NONE = "NONE",
  ONE_INCH = "ONE_INCH",
  OP = "OP",
  ORCA = "ORCA",
  PEPE = "PEPE",
  POL = "POL",
  PRIME = "PRIME",
  QNT = "QNT",
  REN = "REN",
  RENDER = "RENDER",
  S = "S",
  SAND = "SAND",
  SEI = "SEI",
  SHIB = "SHIB",
  SOL = "SOL",
  STX = "STX",
  SUI = "SUI",
  SUSHI = "SUSHI",
  TON = "TON",
  TRUMP = "TRUMP",
  UNI = "UNI",
  USD = "USD",
  VET = "VET",
  WIF = "WIF",
  XLM = "XLM",
  XRP = "XRP",
  XTZ = "XTZ",
  YFI = "YFI",
}

export enum CustodianType {
  EXTERNAL = "EXTERNAL",
  FORTRESS = "FORTRESS",
  I_TRUST = "I_TRUST",
  M2 = "M2",
  MADISON = "MADISON",
  NONE = "NONE",
}

export enum DocuSignStatus {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTO_RESPONDED = "AUTO_RESPONDED",
  COMPLETED = "COMPLETED",
  CORRECT = "CORRECT",
  CREATED = "CREATED",
  DECLINED = "DECLINED",
  DELETED = "DELETED",
  DELIVERED = "DELIVERED",
  PROCESSING = "PROCESSING",
  SENT = "SENT",
  SIGNED = "SIGNED",
  TEMPLATE = "TEMPLATE",
  VOIDED = "VOIDED",
}

export enum DocuSignTemplateType {
  ACCOUNT_CHANGES = "ACCOUNT_CHANGES",
  CARES_ACT = "CARES_ACT",
  CONVERSION = "CONVERSION",
  DISTRIBUTION = "DISTRIBUTION",
  INDEMNIFICATION_LETTER = "INDEMNIFICATION_LETTER",
  IRA_ADOPTION_AGREEMENT = "IRA_ADOPTION_AGREEMENT",
  KINGDOM_TRUST_INDEMNIFICATION_LETTER = "KINGDOM_TRUST_INDEMNIFICATION_LETTER",
  NONE = "NONE",
  PRECIOUS_METALS_DISTRIBUTION = "PRECIOUS_METALS_DISTRIBUTION",
  RECHARACTERIZATION_REQUEST = "RECHARACTERIZATION_REQUEST",
  ROTH60_DAY_ROLLOVER = "ROTH60_DAY_ROLLOVER",
  ROTH_CONVERSION = "ROTH_CONVERSION",
  SUPPLEMENTAL_CURRENCIES = "SUPPLEMENTAL_CURRENCIES",
  TRADITIONAL60_DAY_ROLLOVER = "TRADITIONAL60_DAY_ROLLOVER",
}

export enum DocumentStage {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  DRAFT = "DRAFT",
  NONE = "NONE",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  SENT_TO_CLIENT = "SENT_TO_CLIENT",
  SENT_TO_CUSTODIAN = "SENT_TO_CUSTODIAN",
  SIGNED = "SIGNED",
  VOIDED = "VOIDED",
}

export enum DocumentStatus {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  NONE = "NONE",
  PENDING = "PENDING",
  VOIDED = "VOIDED",
}

export enum DocumentType {
  DOCU_SIGN = "DOCU_SIGN",
  FILE = "FILE",
  NONE = "NONE",
  OTHER = "OTHER",
  PDF = "PDF",
  WIZARD_PDF = "WIZARD_PDF",
}

export enum EmailAddressType {
  BCC = "BCC",
  CC = "CC",
  FROM = "FROM",
  NONE = "NONE",
  TO = "TO",
}

export enum EmailTemplateType {
  ACCOUNT_ACTIVE = "ACCOUNT_ACTIVE",
  ACCOUNT_CREATION = "ACCOUNT_CREATION",
  ACH_TRANSFER_COMPLETED_EXTERNAL = "ACH_TRANSFER_COMPLETED_EXTERNAL",
  ACH_TRANSFER_FAILED_EXTERNAL = "ACH_TRANSFER_FAILED_EXTERNAL",
  ACH_TRANSFER_FAILED_INTERNAL = "ACH_TRANSFER_FAILED_INTERNAL",
  ACH_TRANSFER_PROCESSING_EXTERNAL = "ACH_TRANSFER_PROCESSING_EXTERNAL",
  ALGO_MANA_ENABLED_UPDATE = "ALGO_MANA_ENABLED_UPDATE",
  APEX_TRANSFER_FORM_FURTHER_STEPS = "APEX_TRANSFER_FORM_FURTHER_STEPS",
  ASSET_TRANSFER_PENDING = "ASSET_TRANSFER_PENDING",
  ASTRA_ACH_COMPLETED = "ASTRA_ACH_COMPLETED",
  ASTRA_ACH_FAILED = "ASTRA_ACH_FAILED",
  ASTRA_ACH_STARTED = "ASTRA_ACH_STARTED",
  CASH_CONTRIBUTION_TRANSFER_PENDING = "CASH_CONTRIBUTION_TRANSFER_PENDING",
  CHECK_RECEIVED = "CHECK_RECEIVED",
  CONDITIONAL_TRANSACTION_CANCELED = "CONDITIONAL_TRANSACTION_CANCELED",
  CONDITIONAL_TRANSACTION_CREATED = "CONDITIONAL_TRANSACTION_CREATED",
  CONDITIONAL_TRANSACTION_ERROR = "CONDITIONAL_TRANSACTION_ERROR",
  CONDITIONAL_TRANSACTION_FAILED = "CONDITIONAL_TRANSACTION_FAILED",
  DOCUMENTS_PENDING = "DOCUMENTS_PENDING",
  DOCUMENTS_SENT_TO_CLIENT = "DOCUMENTS_SENT_TO_CLIENT",
  FILE_NOTICE = "FILE_NOTICE",
  FLR_DISTRIBUTION_NOTICE = "FLR_DISTRIBUTION_NOTICE",
  FORTRESS_ANNOUNCEMENT = "FORTRESS_ANNOUNCEMENT",
  FORTRESS_FUNDING_CONTRIBUTION = "FORTRESS_FUNDING_CONTRIBUTION",
  FORTRESS_FUNDING_ROLLOVER = "FORTRESS_FUNDING_ROLLOVER",
  FORTRESS_INTEGRATION = "FORTRESS_INTEGRATION",
  FORTRESS_INTEGRATION_UPDATE = "FORTRESS_INTEGRATION_UPDATE",
  FUNDING_APPROVED = "FUNDING_APPROVED",
  FUNDING_CONTRIBUTION = "FUNDING_CONTRIBUTION",
  FUNDING_ROLLOVER = "FUNDING_ROLLOVER",
  GOOGLE_REVIEWS = "GOOGLE_REVIEWS",
  INACTIVE_ACCOUNT = "INACTIVE_ACCOUNT",
  INSUFFICIENT_FUNDS_ACH = "INSUFFICIENT_FUNDS_ACH",
  INVITATION_VIA_REFERRAL = "INVITATION_VIA_REFERRAL",
  IRA_ADOPTION_AGREEMENT = "IRA_ADOPTION_AGREEMENT",
  IRA_CUSTODIAN_CHANGES = "IRA_CUSTODIAN_CHANGES",
  ITC_FUNDING_CONTRIBUTION = "ITC_FUNDING_CONTRIBUTION",
  ITC_FUNDING_ROLLOVER = "ITC_FUNDING_ROLLOVER",
  ITC_FUNDING_TRANSFER = "ITC_FUNDING_TRANSFER",
  ITC_SSN_VERIFICATION = "ITC_SSN_VERIFICATION",
  ITC_TRANSFER_RECEIPT = "ITC_TRANSFER_RECEIPT",
  MARKET_ORDER_CONFIRMATION = "MARKET_ORDER_CONFIRMATION",
  MARKET_ORDER_FAILED = "MARKET_ORDER_FAILED",
  MERGED_ACCOUNTS = "MERGED_ACCOUNTS",
  MERGE_ACCOUNTS_ANNOUNCEMENT1 = "MERGE_ACCOUNTS_ANNOUNCEMENT1",
  MERGE_ACCOUNTS_ANNOUNCEMENT2 = "MERGE_ACCOUNTS_ANNOUNCEMENT2",
  MERGE_ACCOUNTS_ANNOUNCEMENT3 = "MERGE_ACCOUNTS_ANNOUNCEMENT3",
  NONE = "NONE",
  POST_FUNDING_VIA_AFFILIATE = "POST_FUNDING_VIA_AFFILIATE",
  POST_FUNDING_VIA_REFERRAL = "POST_FUNDING_VIA_REFERRAL",
  POST_SIGN_UP_VIA_AFFILIATE = "POST_SIGN_UP_VIA_AFFILIATE",
  POST_SIGN_UP_VIA_REFERRAL = "POST_SIGN_UP_VIA_REFERRAL",
  RECURRING_PURCHASE_CANCELED = "RECURRING_PURCHASE_CANCELED",
  RECURRING_PURCHASE_CREATED = "RECURRING_PURCHASE_CREATED",
  RECURRING_PURCHASE_FAILED = "RECURRING_PURCHASE_FAILED",
  RECURRING_PURCHASE_HISTORY_FAILED = "RECURRING_PURCHASE_HISTORY_FAILED",
  REVIEW_REQUEST = "REVIEW_REQUEST",
  REVIEW_THANK_YOU = "REVIEW_THANK_YOU",
  RIA_CLIENT_CREATED = "RIA_CLIENT_CREATED",
  ROLLOVER_ELIGIBILITY = "ROLLOVER_ELIGIBILITY",
  ROLLOVER_TRANSFER_PENDING = "ROLLOVER_TRANSFER_PENDING",
  ROTH_ROLLOVER_CONVERSION_ELIGIBILITY = "ROTH_ROLLOVER_CONVERSION_ELIGIBILITY",
  ROTH_TRAD_CONVERSION_ELIGIBILITY = "ROTH_TRAD_CONVERSION_ELIGIBILITY",
  STAKING_ENROLLMENT_CANCEL = "STAKING_ENROLLMENT_CANCEL",
  STAKING_ENROLLMENT_START = "STAKING_ENROLLMENT_START",
  STAKING_REWARD_DISBURSEMENT = "STAKING_REWARD_DISBURSEMENT",
  STAKING_TERM_COMPLETE = "STAKING_TERM_COMPLETE",
  STAKING_TERM_START = "STAKING_TERM_START",
  TEST_ACCOUNT_ADDED = "TEST_ACCOUNT_ADDED",
  TPA_CLIENT_CREATED = "TPA_CLIENT_CREATED",
  TRADE_CONFIRMATION = "TRADE_CONFIRMATION",
  TRANSFER_FORM_FOLLOW_UP = "TRANSFER_FORM_FOLLOW_UP",
  TRANSFER_FORM_FURTHER_STEPS = "TRANSFER_FORM_FURTHER_STEPS",
  UPDATE_ON_ALGO_AND_MANA = "UPDATE_ON_ALGO_AND_MANA",
  UPDATE_REGARDING_ALGO_AND_MANA = "UPDATE_REGARDING_ALGO_AND_MANA",
  VERIFICATION = "VERIFICATION",
  VERIFICATION_ADDRESS = "VERIFICATION_ADDRESS",
  VERIFICATION_DRIVERS_LICENSE = "VERIFICATION_DRIVERS_LICENSE",
  VERIFICATION_SSN = "VERIFICATION_SSN",
  WIZARD_COMPLETED = "WIZARD_COMPLETED",
  WIZARD_COMPLETION_CLIENT = "WIZARD_COMPLETION_CLIENT",
}

export enum EmploymentStatus {
  EMPLOYED = "EMPLOYED",
  NONE = "NONE",
  RETIRED = "RETIRED",
  UNEMPLOYED = "UNEMPLOYED",
}

export enum FileTemplateType {
  BENEFICIARY_DESIGNATION_SPOUSAL_CONSENT_FORM = "BENEFICIARY_DESIGNATION_SPOUSAL_CONSENT_FORM",
  CASH_CONTRIBUTION_FUNDING_INSTRUCTIONS = "CASH_CONTRIBUTION_FUNDING_INSTRUCTIONS",
  GOVERNMENT_ID = "GOVERNMENT_ID",
  GOVERNMENT_ID_BACK = "GOVERNMENT_ID_BACK",
  INHERITED_IRA_TRANSFER_FORM = "INHERITED_IRA_TRANSFER_FORM",
  MONTHLY_ACCOUNT_STATEMENT = "MONTHLY_ACCOUNT_STATEMENT",
  NONE = "NONE",
  OTHER = "OTHER",
  PASSPORT = "PASSPORT",
  PASSPORT_BACK = "PASSPORT_BACK",
  PREMIUM_CUSTODY_ACCOUNT_WITHDRAWAL = "PREMIUM_CUSTODY_ACCOUNT_WITHDRAWAL",
  PREVIOUS_STATEMENT = "PREVIOUS_STATEMENT",
  REFUND_CONTRIBUTION = "REFUND_CONTRIBUTION",
  ROLLOVER_FUNDING_INSTRUCTIONS = "ROLLOVER_FUNDING_INSTRUCTIONS",
  SSN_CARD = "SSN_CARD",
  TRANSFER_FORM = "TRANSFER_FORM",
  UTILITY_BILL = "UTILITY_BILL",
}

export enum FundingDirection {
  INBOUND = "INBOUND",
  NONE = "NONE",
  OUTBOUND = "OUTBOUND",
}

export enum FundingInstructionType {
  CRYPTO = "CRYPTO",
  FIAT = "FIAT",
  NONE = "NONE",
}

export enum FundingStage {
  ACCOUNT_PENDING = "ACCOUNT_PENDING",
  ASSET_TRANSFER_COMPLETED = "ASSET_TRANSFER_COMPLETED",
  ASSET_TRANSFER_PENDING = "ASSET_TRANSFER_PENDING",
  ASSET_TRANSFER_READY = "ASSET_TRANSFER_READY",
  CANCELED = "CANCELED",
  DECLINED = "DECLINED",
  DOCUMENTS_PENDING = "DOCUMENTS_PENDING",
  DOCUMENTS_SENT_TO_CLIENT = "DOCUMENTS_SENT_TO_CLIENT",
  DOCUMENTS_SENT_TO_CUSTODIAN = "DOCUMENTS_SENT_TO_CUSTODIAN",
  DOCUMENTS_SIGNED = "DOCUMENTS_SIGNED",
  DOCUMENT_REVIEW = "DOCUMENT_REVIEW",
  NONE = "NONE",
  PENDING_REVIEW = "PENDING_REVIEW",
  WAITING_TO_PROCESS = "WAITING_TO_PROCESS",
}

export enum FundingStatus {
  APPROVED = "APPROVED",
  APPROVED_THEN_DECLINED = "APPROVED_THEN_DECLINED",
  CANCELED = "CANCELED",
  DECLINED = "DECLINED",
  IN_REVIEW = "IN_REVIEW",
  NONE = "NONE",
  PENDING = "PENDING",
}

export enum FundingType {
  ACCOUNT_CLOSURE = "ACCOUNT_CLOSURE",
  CHARGEBACK = "CHARGEBACK",
  CONTRIBUTION = "CONTRIBUTION",
  CONVERSION = "CONVERSION",
  CORRECTION = "CORRECTION",
  DISTRIBUTION = "DISTRIBUTION",
  FORTRESS_EXIT_FEE = "FORTRESS_EXIT_FEE",
  FORTRESS_FEE_REFUND = "FORTRESS_FEE_REFUND",
  LOW_BALANCE = "LOW_BALANCE",
  NONE = "NONE",
  OTHER = "OTHER",
  PCA_USD = "PCA_USD",
  RECHARACTERIZATION = "RECHARACTERIZATION",
  REFUND_CONTRIBUTION = "REFUND_CONTRIBUTION",
  RETURN_OF_FUNDS = "RETURN_OF_FUNDS",
  REWARD = "REWARD",
  RIA_TRANSFER = "RIA_TRANSFER",
  ROLLOVER = "ROLLOVER",
  TOKEN_MIGRATION = "TOKEN_MIGRATION",
  TPA_TRANSFER = "TPA_TRANSFER",
  TRANSFER = "TRANSFER",
}

export enum IdentificationStatus {
  APPROVED = "APPROVED",
  NEEDS_APPROVAL = "NEEDS_APPROVAL",
  NONE = "NONE",
  REJECTED = "REJECTED",
}

export enum IraTransferInstruction {
  CASH_TRANSFER = "CASH_TRANSFER",
  IN_KIND_AND_CASH_TRANSFER = "IN_KIND_AND_CASH_TRANSFER",
  IN_KIND_TRANSFER = "IN_KIND_TRANSFER",
  NONE = "NONE",
}

export enum KycStatus {
  APPROVED = "APPROVED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  L0 = "L0",
  L1 = "L1",
  L2 = "L2",
  MANUAL_REVIEW = "MANUAL_REVIEW",
  NONE = "NONE",
  NOT_PASSED = "NOT_PASSED",
  PASSED = "PASSED",
  PENDING_ACTION = "PENDING_ACTION",
  REVIEW = "REVIEW",
  STEP_UP = "STEP_UP",
  VERIFICATION = "VERIFICATION",
  VERIFICATION_ADDRESS = "VERIFICATION_ADDRESS",
  VERIFICATION_DRIVERS_LICENSE = "VERIFICATION_DRIVERS_LICENSE",
  VERIFICATION_SSN = "VERIFICATION_SSN",
}

export enum LimitType {
  NATIVE = "NATIVE",
  NONE = "NONE",
  USD = "USD",
}

export enum MarketOrderStatus {
  COMPLETED = "COMPLETED",
  CREATED = "CREATED",
  EXECUTED = "EXECUTED",
  EXECUTING = "EXECUTING",
  FAILED = "FAILED",
  LIQUIDITY_PROVIDER_SELECTED = "LIQUIDITY_PROVIDER_SELECTED",
  NONE = "NONE",
  RETRY = "RETRY",
  WALLET_DEBITED = "WALLET_DEBITED",
}

export enum MarketOrderTrigger {
  CONDITIONAL_TRANSACTION = "CONDITIONAL_TRANSACTION",
  NONE = "NONE",
  RECURRING_PURCHASE = "RECURRING_PURCHASE",
  USER = "USER",
}

export enum MarketingEventType {
  ACCOUNT_WIZARD_COMPLETE = "ACCOUNT_WIZARD_COMPLETE",
  INITIAL_ACCOUNT_WIZARD_COMPLETE = "INITIAL_ACCOUNT_WIZARD_COMPLETE",
  LOGIN = "LOGIN",
  NONE = "NONE",
  USER_CREATION = "USER_CREATION",
}

export enum NoteStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  NONE = "NONE",
}

export enum NotificationSettingType {
  DOCUMENTS_PENDING_CLIENT = "DOCUMENTS_PENDING_CLIENT",
  FUNDING_CONTRIBUTION_INSTRUCTIONS = "FUNDING_CONTRIBUTION_INSTRUCTIONS",
  FUNDS_RELEASED = "FUNDS_RELEASED",
  IRA_ADOPTION_AGREEMENT = "IRA_ADOPTION_AGREEMENT",
  NONE = "NONE",
  PENDING_CLIENT_DOCS_REMINDER = "PENDING_CLIENT_DOCS_REMINDER",
  ROLLOVER5_BUS_DAYS = "ROLLOVER5_BUS_DAYS",
  TRANSFER_AND_CASH2_BUS_DAYS = "TRANSFER_AND_CASH2_BUS_DAYS",
  TRANSFER_FORM_FOLLOW_UP = "TRANSFER_FORM_FOLLOW_UP",
  TRANSFER_PENDING = "TRANSFER_PENDING",
  VERIFICATION = "VERIFICATION",
  VERIFICATION_ADDRESS = "VERIFICATION_ADDRESS",
  VERIFICATION_DRIVERS_LICENSE = "VERIFICATION_DRIVERS_LICENSE",
  VERIFICATION_SSN = "VERIFICATION_SSN",
  WIZARD_COMPLETED = "WIZARD_COMPLETED",
}

export enum NotificationType {
  ALL = "ALL",
  DEVICE_PUSH = "DEVICE_PUSH",
  EMAIL = "EMAIL",
  NONE = "NONE",
  SMS = "SMS",
}

export enum OrderType {
  BUY = "BUY",
  SELL = "SELL",
}

export enum PaymentType {
  ACH = "ACH",
  ACH_DEPOSIT = "ACH_DEPOSIT",
  ACH_DEPOSIT_RETURN = "ACH_DEPOSIT_RETURN",
  ACH_DEPOSIT_REVERSE = "ACH_DEPOSIT_REVERSE",
  ACH_WITHDRAWAL = "ACH_WITHDRAWAL",
  ACH_WITHDRAWAL_RETURN = "ACH_WITHDRAWAL_RETURN",
  ACH_WITHDRAWAL_REVERSE = "ACH_WITHDRAWAL_REVERSE",
  BUY_CRYPTO = "BUY_CRYPTO",
  BUY_PRECIOUS_METAL = "BUY_PRECIOUS_METAL",
  CARD_DEPOSIT = "CARD_DEPOSIT",
  CARD_WITHDRAWAL = "CARD_WITHDRAWAL",
  CASH = "CASH",
  CHECK = "CHECK",
  CHECK_DEPOSIT = "CHECK_DEPOSIT",
  DEBIT = "DEBIT",
  DEPOSIT_CRYPTO = "DEPOSIT_CRYPTO",
  DEPOSIT_PRECIOUS_METAL = "DEPOSIT_PRECIOUS_METAL",
  FORTRESS_EXIT_FEE = "FORTRESS_EXIT_FEE",
  FORTRESS_FEE_REFUND = "FORTRESS_FEE_REFUND",
  INCOMING_ACH_DEPOSIT = "INCOMING_ACH_DEPOSIT",
  INCOMING_ACH_WITHDRAWAL = "INCOMING_ACH_WITHDRAWAL",
  INITIAL_DEPOSIT = "INITIAL_DEPOSIT",
  INTERNAL = "INTERNAL",
  INTERNAL_CRYPTO = "INTERNAL_CRYPTO",
  INTERNATIONAL_WIRE_WITHDRAWAL = "INTERNATIONAL_WIRE_WITHDRAWAL",
  IN_KIND = "IN_KIND",
  LEDGER = "LEDGER",
  MANUALLY_PROCESSED = "MANUALLY_PROCESSED",
  NA = "NA",
  NONE = "NONE",
  NTA_ACH_DEPOSIT = "NTA_ACH_DEPOSIT",
  NTA_ACH_WITHDRAWAL = "NTA_ACH_WITHDRAWAL",
  NTA_CRYPTO_DEPOSIT = "NTA_CRYPTO_DEPOSIT",
  NTA_CRYPTO_WITHDRAWAL = "NTA_CRYPTO_WITHDRAWAL",
  NTA_WIRE_DEPOSIT = "NTA_WIRE_DEPOSIT",
  NTA_WIRE_WITHDRAWAL = "NTA_WIRE_WITHDRAWAL",
  PRIVATE_SECURITY_DEPOSIT = "PRIVATE_SECURITY_DEPOSIT",
  PRIVATE_SECURITY_INTERNAL = "PRIVATE_SECURITY_INTERNAL",
  PRIVATE_SECURITY_WITHDRAWAL = "PRIVATE_SECURITY_WITHDRAWAL",
  RIA_DEPOSIT = "RIA_DEPOSIT",
  RIA_WITHDRAWAL = "RIA_WITHDRAWAL",
  SELL_CRYPTO = "SELL_CRYPTO",
  SELL_PRECIOUS_METAL = "SELL_PRECIOUS_METAL",
  TOKEN_MIGRATION = "TOKEN_MIGRATION",
  TPA_DEPOSIT = "TPA_DEPOSIT",
  TPA_WITHDRAWAL = "TPA_WITHDRAWAL",
  TRANSFER_FEE = "TRANSFER_FEE",
  WIRE = "WIRE",
  WIRE_DEPOSIT = "WIRE_DEPOSIT",
  WIRE_DEPOSIT_PARTIAL_RETURN = "WIRE_DEPOSIT_PARTIAL_RETURN",
  WIRE_DEPOSIT_RETURN = "WIRE_DEPOSIT_RETURN",
  WIRE_DEPOSIT_REVERSE = "WIRE_DEPOSIT_REVERSE",
  WIRE_WITHDRAWAL = "WIRE_WITHDRAWAL",
  WIRE_WITHDRAWAL_RETURN = "WIRE_WITHDRAWAL_RETURN",
  WIRE_WITHDRAWAL_REVERSE = "WIRE_WITHDRAWAL_REVERSE",
  WITHDRAWAL_CRYPTO = "WITHDRAWAL_CRYPTO",
  WITHDRAWAL_PRECIOUS_METAL = "WITHDRAWAL_PRECIOUS_METAL",
}

export enum RecurringPurchaseHistoryStatus {
  EXECUTED = "EXECUTED",
  EXECUTING = "EXECUTING",
  FAILED = "FAILED",
  NONE = "NONE",
  PENDING = "PENDING",
}

export enum RecurringPurchaseScheduledTrigger {
  FIFTEENTH = "FIFTEENTH",
  FIRST = "FIRST",
  MONDAY = "MONDAY",
  NONE = "NONE",
}

export enum RecurringPurchaseStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  NONE = "NONE",
}

export enum ReferralSource {
  EMAIL = "EMAIL",
  LINK = "LINK",
  NONE = "NONE",
}

export enum RolloverFromType {
  CURRENT_EMPLOYER = "CURRENT_EMPLOYER",
  NONE = "NONE",
  PREVIOUS_EMPLOYER = "PREVIOUS_EMPLOYER",
}

export enum RolloverType {
  CARES_ACT_ROLLOVER = "CARES_ACT_ROLLOVER",
  NONE = "NONE",
  ROLLOVER = "ROLLOVER",
  _60_DAY_ROLLOVER = "_60_DAY_ROLLOVER",
}

export enum SmsTemplateType {
  ACCOUNT_ACTIVATION = "ACCOUNT_ACTIVATION",
  ACCOUNT_DOCUMENTS_DRAFTED = "ACCOUNT_DOCUMENTS_DRAFTED",
  ASSET_TRANSFER_PENDING = "ASSET_TRANSFER_PENDING",
  DISTRIBUTION_VERIFICATION_REQUIRED = "DISTRIBUTION_VERIFICATION_REQUIRED",
  DOCUMENTS_PENDING = "DOCUMENTS_PENDING",
  DOCUMENTS_SENT_TO_CLIENT = "DOCUMENTS_SENT_TO_CLIENT",
  DOCU_SIGN = "DOCU_SIGN",
  FOLLOW_UP_AFTER_TRANSFER_FORM_SENT_TO_PREVIOUS_CUSTODIAN = "FOLLOW_UP_AFTER_TRANSFER_FORM_SENT_TO_PREVIOUS_CUSTODIAN",
  FUNDING_APPROVED = "FUNDING_APPROVED",
  FUNDING_INSTRUCTIONS_SENT = "FUNDING_INSTRUCTIONS_SENT",
  INACTIVE_ACCOUNT = "INACTIVE_ACCOUNT",
  IRA_ADOPTION_AGREEMENT = "IRA_ADOPTION_AGREEMENT",
  NONE = "NONE",
  NOTIFICATION_OF_EMAIL_ADDRESS = "NOTIFICATION_OF_EMAIL_ADDRESS",
  NOTIFICATION_OF_EMAIL_IRA = "NOTIFICATION_OF_EMAIL_IRA",
  NOTIFICATION_OF_EMAIL_NEW_IRA = "NOTIFICATION_OF_EMAIL_NEW_IRA",
  NOTIFICATION_OF_EMAIL_PHOTO = "NOTIFICATION_OF_EMAIL_PHOTO",
  RESENT_FUNDING_CASH_CONTRIBUTION = "RESENT_FUNDING_CASH_CONTRIBUTION",
  RESENT_FUNDING_ROLLOVER = "RESENT_FUNDING_ROLLOVER",
  RESENT_FUNDING_TRANSFER_FORM = "RESENT_FUNDING_TRANSFER_FORM",
  SECONDARY_CASH_CONTRIBUTION = "SECONDARY_CASH_CONTRIBUTION",
  TRANSFER_FORM_CONFIRMED_RECEIVED = "TRANSFER_FORM_CONFIRMED_RECEIVED",
  TRANSFER_FORM_FOLLOW_UP = "TRANSFER_FORM_FOLLOW_UP",
  VERIFICATION = "VERIFICATION",
  VERIFICATION_ADDRESS = "VERIFICATION_ADDRESS",
  VERIFICATION_DRIVERS_LICENSE = "VERIFICATION_DRIVERS_LICENSE",
  VERIFICATION_SSN = "VERIFICATION_SSN",
  WIZARD_COMPLETE = "WIZARD_COMPLETE",
}

export enum SortEnumType {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SpousalConsent {
  NO = "NO",
  NONE = "NONE",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  YES = "YES",
}

export enum StakingEnrollmentStatus {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  ENROLLED = "ENROLLED",
  NONE = "NONE",
  STAKED = "STAKED",
}

export enum StakingMovementDirection {
  INBOUND = "INBOUND",
  NONE = "NONE",
  OUTBOUND = "OUTBOUND",
}

export enum StakingMovementType {
  NONE = "NONE",
  PRINCIPLE = "PRINCIPLE",
  REWARD = "REWARD",
}

export enum StakingSource {
  COINBASE = "COINBASE",
  NONE = "NONE",
}

export enum StakingTermStatus {
  COMPLETE = "COMPLETE",
  ENROLLMENT = "ENROLLMENT",
  NONE = "NONE",
  PENDING = "PENDING",
  PENDING_REWARDS = "PENDING_REWARDS",
  STAKED = "STAKED",
}

export enum SubscriptionBillingFrequency {
  ANNUAL = "ANNUAL",
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  NONE = "NONE",
}

export enum SubscriptionBillingMethod {
  CREDIT_CARD = "CREDIT_CARD",
  NONE = "NONE",
}

export enum SubscriptionBillingStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  DELINQUENT = "DELINQUENT",
  NONE = "NONE",
}

export enum Suffix {
  II = "II",
  III = "III",
  IV = "IV",
  JR = "JR",
  NONE = "NONE",
  SR = "SR",
  V = "V",
  VI = "VI",
}

export enum SystemRole {
  ADMIN = "ADMIN",
  ADMIN_GROUP = "ADMIN_GROUP",
  CRM_GROUP = "CRM_GROUP",
  CRM_WRITE_GROUP = "CRM_WRITE_GROUP",
  CUSTODIAN = "CUSTODIAN",
  CUSTOMER = "CUSTOMER",
  FORTRESS = "FORTRESS",
  FUNCTION = "FUNCTION",
  FUNDING = "FUNDING",
  GENERAL = "GENERAL",
  NONE = "NONE",
  PRIMARY_CRM_WRITE_GROUP = "PRIMARY_CRM_WRITE_GROUP",
  PROCESSING = "PROCESSING",
  PROCESSING_T1 = "PROCESSING_T1",
  RIA = "RIA",
  SUPER_ADMIN = "SUPER_ADMIN",
  TPA = "TPA",
}

export enum Theme {
  DARK = "DARK",
  LIGHT = "LIGHT",
  NONE = "NONE",
}

export enum TourType {
  DASHBOARD_NEW_UI = "DASHBOARD_NEW_UI",
  NONE = "NONE",
  OPEN_TRANSACTIONS = "OPEN_TRANSACTIONS",
}

export enum UserStage {
  ACCOUNT = "ACCOUNT",
  CLOSED = "CLOSED",
  KYC = "KYC",
  NONE = "NONE",
  VERIFIED = "VERIFIED",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  AUTH_CREATE_USER_FAILED = "AUTH_CREATE_USER_FAILED",
  AUTH_LOGIN = "AUTH_LOGIN",
  AUTH_VERIFY = "AUTH_VERIFY",
  DELETED = "DELETED",
  DISABLED = "DISABLED",
  EMAIL_VERIFY = "EMAIL_VERIFY",
  LEAD = "LEAD",
  NONE = "NONE",
}

export enum WalletUpdateType {
  FUNDING = "FUNDING",
  MARKET_ORDER = "MARKET_ORDER",
  NONE = "NONE",
  STAKING_MOVEMENT = "STAKING_MOVEMENT",
}

export interface AccountBeneficiaryFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  and?: AccountBeneficiaryFilterInput[] | null;
  beneficiary?: BeneficiaryFilterInput | null;
  beneficiaryId?: IntOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: AccountBeneficiaryFilterInput[] | null;
  percentage?: DecimalOperationFilterInput | null;
  type?: BeneficiaryTypeOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface AccountBeneficiarySortInput {
  account?: AccountSortInput | null;
  accountId?: SortEnumType | null;
  beneficiary?: BeneficiarySortInput | null;
  beneficiaryId?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  percentage?: SortEnumType | null;
  type?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface AccountFilterInput {
  accountBeneficiaries?: ListFilterInputTypeOfAccountBeneficiaryFilterInput | null;
  accountNumber?: StringOperationFilterInput | null;
  accountPurpose?: StringOperationFilterInput | null;
  accountStageHistories?: ListFilterInputTypeOfAccountStageHistoryFilterInput | null;
  activatedAt?: DateTimeOperationFilterInput | null;
  and?: AccountFilterInput[] | null;
  churnReason?: NullableOfChurnReasonOperationFilterInput | null;
  closedAt?: DateTimeOperationFilterInput | null;
  closedByRole?: NullableOfSystemRoleOperationFilterInput | null;
  closedByUserId?: IntOperationFilterInput | null;
  conditionalTransactions?: ListFilterInputTypeOfConditionalTransactionFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  custodian?: CustodianTypeOperationFilterInput | null;
  custodianAccount?: CustodianAccountFilterInput | null;
  device?: StringOperationFilterInput | null;
  displayName?: StringOperationFilterInput | null;
  documents?: ListFilterInputTypeOfDocumentFilterInput | null;
  documentSentAt?: DateTimeOperationFilterInput | null;
  documentSignedAt?: DateTimeOperationFilterInput | null;
  fortressTransferWizardComplete?: BooleanOperationFilterInput | null;
  fundedAt?: DateTimeOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  inboundFundings?: ListFilterInputTypeOfFundingFilterInput | null;
  inboundFundingSumValue?: DecimalOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  irsInfos?: ListFilterInputTypeOfIrsInfoFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  kycEvaluations?: ListFilterInputTypeOfKycEvaluationFilterInput | null;
  marketOrders?: ListFilterInputTypeOfMarketOrderFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  needsUpdatedIraAgreement?: BooleanOperationFilterInput | null;
  or?: AccountFilterInput[] | null;
  outboundFundings?: ListFilterInputTypeOfFundingFilterInput | null;
  outboundFundingSumValue?: DecimalOperationFilterInput | null;
  recurringPurchases?: ListFilterInputTypeOfRecurringPurchaseFilterInput | null;
  regeneratedAt?: DateTimeOperationFilterInput | null;
  regeneratedByUserId?: IntOperationFilterInput | null;
  regeneratedReason?: StringOperationFilterInput | null;
  signedUpdatedIraAgreementAt?: DateTimeOperationFilterInput | null;
  sourceOfFunds?: StringOperationFilterInput | null;
  sparkTokenOwned?: DecimalOperationFilterInput | null;
  stage?: AccountStageOperationFilterInput | null;
  stakingEnrollments?: ListFilterInputTypeOfStakingEnrollmentFilterInput | null;
  stakingMovements?: ListFilterInputTypeOfStakingMovementFilterInput | null;
  status?: AccountStatusOperationFilterInput | null;
  subscriptionBillings?: ListFilterInputTypeOfSubscriptionBillingFilterInput | null;
  tradeFeeCredit?: TradeFeeCreditFilterInput | null;
  type?: AccountTypeOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
  walletHistories?: ListFilterInputTypeOfWalletHistoryFilterInput | null;
  wallets?: ListFilterInputTypeOfWalletFilterInput | null;
  wizardCompletedAt?: DateTimeOperationFilterInput | null;
}

export interface AccountOrderOperationFilterInput {
  eq?: AccountOrder | null;
  in?: AccountOrder[] | null;
  neq?: AccountOrder | null;
  nin?: AccountOrder[] | null;
}

export interface AccountSortInput {
  accountNumber?: SortEnumType | null;
  accountPurpose?: SortEnumType | null;
  activatedAt?: SortEnumType | null;
  churnReason?: SortEnumType | null;
  closedAt?: SortEnumType | null;
  closedByRole?: SortEnumType | null;
  closedByUserId?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  custodian?: SortEnumType | null;
  custodianAccount?: CustodianAccountSortInput | null;
  device?: SortEnumType | null;
  displayName?: SortEnumType | null;
  documentSentAt?: SortEnumType | null;
  documentSignedAt?: SortEnumType | null;
  fortressTransferWizardComplete?: SortEnumType | null;
  fundedAt?: SortEnumType | null;
  id?: SortEnumType | null;
  inboundFundingSumValue?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  needsUpdatedIraAgreement?: SortEnumType | null;
  outboundFundingSumValue?: SortEnumType | null;
  regeneratedAt?: SortEnumType | null;
  regeneratedByUserId?: SortEnumType | null;
  regeneratedReason?: SortEnumType | null;
  signedUpdatedIraAgreementAt?: SortEnumType | null;
  sourceOfFunds?: SortEnumType | null;
  sparkTokenOwned?: SortEnumType | null;
  stage?: SortEnumType | null;
  status?: SortEnumType | null;
  tradeFeeCredit?: TradeFeeCreditSortInput | null;
  type?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
  user?: UserSortInput | null;
  userId?: SortEnumType | null;
  wizardCompletedAt?: SortEnumType | null;
}

export interface AccountStageHistoryFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  and?: AccountStageHistoryFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: AccountStageHistoryFilterInput[] | null;
  stage?: AccountStageOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface AccountStageOperationFilterInput {
  eq?: AccountStage | null;
  in?: AccountStage[] | null;
  neq?: AccountStage | null;
  nin?: AccountStage[] | null;
}

export interface AccountStatusOperationFilterInput {
  eq?: AccountStatus | null;
  in?: AccountStatus[] | null;
  neq?: AccountStatus | null;
  nin?: AccountStatus[] | null;
}

export interface AccountTypeOperationFilterInput {
  eq?: AccountType | null;
  in?: AccountType[] | null;
  neq?: AccountType | null;
  nin?: AccountType[] | null;
}

export interface AddAccountInput {
  displayName?: string | null;
  status?: AccountStatus | null;
  type: AccountType;
  userId?: number | null;
}

export interface AddBeneficiaryAddressInput {
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  country?: string | null;
  state?: string | null;
  zipCode?: string | null;
}

export interface AddBeneficiaryInput {
  accountHolderRelationship?: string | null;
  accountId?: number | null;
  addresses?: (AddBeneficiaryAddressInput | null)[] | null;
  directive?: BeneficiaryDirective | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  percentage?: any | null;
  phoneNumber?: string | null;
  relationship?: BeneficiaryRelationshipType | null;
  spousalConsent?: SpousalConsent | null;
  spousalConsentFormDocumentId?: number | null;
  type?: BeneficiaryType | null;
  userId?: number | null;
}

export interface AddCrmUserInput {
  email: string;
  firstName: string;
  lastName: string;
  role: SystemRole;
}

export interface AddDocumentFileInput {
  name?: string | null;
  url?: string | null;
}

export interface AddDocumentInput {
  accountId?: number | null;
  displayName?: string | null;
  docuSignStatus?: DocuSignStatus | null;
  docuSignTemplate?: DocuSignTemplateType | null;
  files?: (AddDocumentFileInput | null)[] | null;
  fileTemplate?: FileTemplateType | null;
  fundingId?: number | null;
  name?: string | null;
  stage?: DocumentStage | null;
  type: DocumentType;
  userId?: number | null;
}

export interface AddExternalAccountTempInput {
  accountNumber?: string | null;
  accountType: AccountType;
  institutionAddress?: string | null;
  institutionId?: string | null;
  institutionName?: string | null;
  isPrimary: boolean;
  otherAccountType?: string | null;
  userId?: number | null;
}

export interface AddFundingAssetInput {
  currency?: string | null;
  currencyType?: CurrencyType | null;
  originalBalance: any;
  paymentType?: PaymentType | null;
  requestedAmount: any;
  transferEntireBalance?: boolean | null;
}

export interface AddFundingInput {
  associatedFundingId?: number | null;
  contributionType?: ContributionType | null;
  contributionYear?: number | null;
  currency?: string | null;
  direction: FundingDirection;
  distributionCode?: string | null;
  federalWithholdings?: any | null;
  fromAccountId?: number | null;
  fromExternalAccount?: AddExternalAccountTempInput | null;
  fromExternalAccountId?: number | null;
  fundingAssets?: (AddFundingAssetInput | null)[] | null;
  isFullTransfer?: boolean | null;
  paymentType?: PaymentType | null;
  preventInternalAccount?: boolean | null;
  promoCode?: string | null;
  requestedAmount?: any | null;
  rolloverFrom?: RolloverFromType | null;
  rolloverType?: RolloverType | null;
  signatureBase64?: string | null;
  stateWithholdings?: any | null;
  status?: FundingStatus | null;
  toAccountId?: number | null;
  toExternalAccount?: AddExternalAccountTempInput | null;
  toExternalAccountId?: number | null;
  transactionCode?: string | null;
  transferInstruction?: IraTransferInstruction | null;
  type: FundingType;
}

export interface AddNoteInput {
  accountId?: number | null;
  createdByUserId?: number | null;
  fundingId?: number | null;
  subject?: string | null;
  text: string;
  userId?: number | null;
}

export interface AddNotificationSettingInput {
  notificationTypes?: NotificationType[] | null;
  type: NotificationSettingType;
  userId?: number | null;
}

export interface AddressTypeOperationFilterInput {
  eq?: AddressType | null;
  in?: AddressType[] | null;
  neq?: AddressType | null;
  nin?: AddressType[] | null;
}

export interface AssignBeneficiaryToAccountInput {
  accountId: number;
  beneficiaryId: number;
  percentage: any;
  type: BeneficiaryType;
}

export interface BeneficiaryAddressFilterInput {
  address?: StringOperationFilterInput | null;
  address2?: StringOperationFilterInput | null;
  and?: BeneficiaryAddressFilterInput[] | null;
  beneficiary?: BeneficiaryFilterInput | null;
  beneficiaryId?: IntOperationFilterInput | null;
  city?: StringOperationFilterInput | null;
  country?: StringOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: BeneficiaryAddressFilterInput[] | null;
  state?: StringOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  zipCode?: StringOperationFilterInput | null;
}

export interface BeneficiaryFilterInput {
  accountBeneficiaries?: ListFilterInputTypeOfAccountBeneficiaryFilterInput | null;
  accountHolderRelationship?: StringOperationFilterInput | null;
  addresses?: ListFilterInputTypeOfBeneficiaryAddressFilterInput | null;
  and?: BeneficiaryFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  directive?: NullableOfBeneficiaryDirectiveOperationFilterInput | null;
  email?: StringOperationFilterInput | null;
  firstName?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  lastName?: StringOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: BeneficiaryFilterInput[] | null;
  phoneNumber?: StringOperationFilterInput | null;
  relationship?: BeneficiaryRelationshipTypeOperationFilterInput | null;
  spousalConsent?: NullableOfSpousalConsentOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface BeneficiaryRelationshipTypeOperationFilterInput {
  eq?: BeneficiaryRelationshipType | null;
  in?: BeneficiaryRelationshipType[] | null;
  neq?: BeneficiaryRelationshipType | null;
  nin?: BeneficiaryRelationshipType[] | null;
}

export interface BeneficiarySortInput {
  accountHolderRelationship?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  directive?: SortEnumType | null;
  email?: SortEnumType | null;
  firstName?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  lastName?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  phoneNumber?: SortEnumType | null;
  relationship?: SortEnumType | null;
  spousalConsent?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
  user?: UserSortInput | null;
  userId?: SortEnumType | null;
}

export interface BeneficiaryTypeOperationFilterInput {
  eq?: BeneficiaryType | null;
  in?: BeneficiaryType[] | null;
  neq?: BeneficiaryType | null;
  nin?: BeneficiaryType[] | null;
}

export interface BooleanOperationFilterInput {
  eq?: boolean | null;
  neq?: boolean | null;
}

export interface CancelFundingInput {
  fundingId: number;
}

export interface CloseAccountInput {
  accountId: number;
  churnReason: ChurnReason;
  isInactive?: boolean | null;
  note?: string | null;
}

export interface CompleteFundingV2Input {
  id: number;
}

export interface ConditionalTransactionFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  and?: ConditionalTransactionFilterInput[] | null;
  attempts?: IntOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  failureReason?: StringOperationFilterInput | null;
  fromAmount?: DecimalOperationFilterInput | null;
  fromAmountExecuted?: DecimalOperationFilterInput | null;
  fromCurrency?: CurrencyFilterInput | null;
  fromCurrencyId?: CurrencyTypeOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  marketOrders?: ListFilterInputTypeOfMarketOrderFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: ConditionalTransactionFilterInput[] | null;
  orderType?: OrderTypeOperationFilterInput | null;
  quotePrice?: DecimalOperationFilterInput | null;
  status?: ConditionalTransactionStatusOperationFilterInput | null;
  toCurrency?: CurrencyFilterInput | null;
  toCurrencyId?: CurrencyTypeOperationFilterInput | null;
  triggeredPrice?: DecimalOperationFilterInput | null;
  triggerPoint?: ConditionalTransactionTriggerPointOperationFilterInput | null;
  triggerPrice?: DecimalOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface ConditionalTransactionSortInput {
  account?: AccountSortInput | null;
  accountId?: SortEnumType | null;
  attempts?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  failureReason?: SortEnumType | null;
  fromAmount?: SortEnumType | null;
  fromAmountExecuted?: SortEnumType | null;
  fromCurrency?: CurrencySortInput | null;
  fromCurrencyId?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  orderType?: SortEnumType | null;
  quotePrice?: SortEnumType | null;
  status?: SortEnumType | null;
  toCurrency?: CurrencySortInput | null;
  toCurrencyId?: SortEnumType | null;
  triggeredPrice?: SortEnumType | null;
  triggerPoint?: SortEnumType | null;
  triggerPrice?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface ConditionalTransactionStatusOperationFilterInput {
  eq?: ConditionalTransactionStatus | null;
  in?: ConditionalTransactionStatus[] | null;
  neq?: ConditionalTransactionStatus | null;
  nin?: ConditionalTransactionStatus[] | null;
}

export interface ConditionalTransactionTriggerPointOperationFilterInput {
  eq?: ConditionalTransactionTriggerPoint | null;
  in?: ConditionalTransactionTriggerPoint[] | null;
  neq?: ConditionalTransactionTriggerPoint | null;
  nin?: ConditionalTransactionTriggerPoint[] | null;
}

export interface CreditTradeFeesInput {
  accountIds?: number[] | null;
  amount: any;
  reason?: string | null;
}

export interface CrmPasswordHistoryFilterInput {
  and?: CrmPasswordHistoryFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  crmUser?: CrmUserFilterInput | null;
  crmUserId?: IntOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: CrmPasswordHistoryFilterInput[] | null;
  password?: StringOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface CrmUserFilterInput {
  and?: CrmUserFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  crmUserLoginHistories?: ListFilterInputTypeOfCrmUserLoginHistoryFilterInput | null;
  device?: StringOperationFilterInput | null;
  email?: StringOperationFilterInput | null;
  firstName?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  lastName?: StringOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  notes?: ListFilterInputTypeOfNoteFilterInput | null;
  or?: CrmUserFilterInput[] | null;
  password?: StringOperationFilterInput | null;
  passwordChangedOn?: DateTimeOperationFilterInput | null;
  passwordHistories?: ListFilterInputTypeOfCrmPasswordHistoryFilterInput | null;
  role?: SystemRoleOperationFilterInput | null;
  smsMessages?: ListFilterInputTypeOfSmsMessageFilterInput | null;
  status?: CrmUserStatusOperationFilterInput | null;
  trustPilotInvitations?: ListFilterInputTypeOfTrustPilotInvitationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface CrmUserLoginHistoryFilterInput {
  and?: CrmUserLoginHistoryFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  crmUser?: CrmUserFilterInput | null;
  crmUserId?: IntOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: CrmUserLoginHistoryFilterInput[] | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface CrmUserSortInput {
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  email?: SortEnumType | null;
  firstName?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  lastName?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  password?: SortEnumType | null;
  passwordChangedOn?: SortEnumType | null;
  role?: SortEnumType | null;
  status?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface CrmUserStatusOperationFilterInput {
  eq?: CrmUserStatus | null;
  in?: CrmUserStatus[] | null;
  neq?: CrmUserStatus | null;
  nin?: CrmUserStatus[] | null;
}

export interface CurrencyFilterInput {
  and?: CurrencyFilterInput[] | null;
  description?: StringOperationFilterInput | null;
  enabled?: BooleanOperationFilterInput | null;
  fromConditionalTransactions?: ListFilterInputTypeOfConditionalTransactionFilterInput | null;
  id?: CurrencyTypeOperationFilterInput | null;
  logoId?: IntOperationFilterInput | null;
  logoUrl?: StringOperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  or?: CurrencyFilterInput[] | null;
  recurringPurchases?: ListFilterInputTypeOfRecurringPurchaseFilterInput | null;
  sortIndex?: IntOperationFilterInput | null;
  subtitle?: StringOperationFilterInput | null;
  toConditionalTransactions?: ListFilterInputTypeOfConditionalTransactionFilterInput | null;
  tradeInMarketOrders?: ListFilterInputTypeOfMarketOrderFilterInput | null;
  tradeOutMarketOrders?: ListFilterInputTypeOfMarketOrderFilterInput | null;
  unit?: StringOperationFilterInput | null;
  whitePaperUrl?: StringOperationFilterInput | null;
}

export interface CurrencySortInput {
  description?: SortEnumType | null;
  enabled?: SortEnumType | null;
  id?: SortEnumType | null;
  logoId?: SortEnumType | null;
  logoUrl?: SortEnumType | null;
  name?: SortEnumType | null;
  sortIndex?: SortEnumType | null;
  subtitle?: SortEnumType | null;
  unit?: SortEnumType | null;
  whitePaperUrl?: SortEnumType | null;
}

export interface CurrencyTypeOperationFilterInput {
  eq?: CurrencyType | null;
  in?: CurrencyType[] | null;
  neq?: CurrencyType | null;
  nin?: CurrencyType[] | null;
}

export interface CurrencyV2FilterInput {
  and?: CurrencyV2FilterInput[] | null;
  avgPrice?: DecimalOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  enabled?: BooleanOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  logoId?: IntOperationFilterInput | null;
  logoUrl?: StringOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  or?: CurrencyV2FilterInput[] | null;
  percentage?: DecimalOperationFilterInput | null;
  sortIndex?: IntOperationFilterInput | null;
  subtitle?: StringOperationFilterInput | null;
  ticker?: StringOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  whitePaperUrl?: StringOperationFilterInput | null;
}

export interface CurrencyV2SortInput {
  createdAt?: SortEnumType | null;
  description?: SortEnumType | null;
  device?: SortEnumType | null;
  enabled?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  logoId?: SortEnumType | null;
  logoUrl?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  name?: SortEnumType | null;
  sortIndex?: SortEnumType | null;
  subtitle?: SortEnumType | null;
  ticker?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
  whitePaperUrl?: SortEnumType | null;
}

export interface CustodianAccountFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  and?: CustodianAccountFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  custodian?: CustodianTypeOperationFilterInput | null;
  custodianAccountId?: StringOperationFilterInput | null;
  custodianAccountStatus?: StringOperationFilterInput | null;
  custodianAccountType?: StringOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: CustodianAccountFilterInput[] | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface CustodianAccountSortInput {
  account?: AccountSortInput | null;
  accountId?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  custodian?: SortEnumType | null;
  custodianAccountId?: SortEnumType | null;
  custodianAccountStatus?: SortEnumType | null;
  custodianAccountType?: SortEnumType | null;
  device?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
  user?: UserSortInput | null;
  userId?: SortEnumType | null;
}

export interface CustodianTypeOperationFilterInput {
  eq?: CustodianType | null;
  in?: CustodianType[] | null;
  neq?: CustodianType | null;
  nin?: CustodianType[] | null;
}

export interface DateTimeOperationFilterInput {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: (any | null)[] | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: (any | null)[] | null;
  nlt?: any | null;
  nlte?: any | null;
}

export interface DecimalOperationFilterInput {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: (any | null)[] | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: (any | null)[] | null;
  nlt?: any | null;
  nlte?: any | null;
}

export interface DeclineFundingInput {
  fundingId: number;
}

export interface DeleteAccountBeneficiaryInput {
  id: number;
}

export interface DeleteBeneficiaryInput {
  id: number;
}

export interface DocumentFileFilterInput {
  and?: DocumentFileFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  document?: DocumentFilterInput | null;
  documentId?: IntOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  or?: DocumentFileFilterInput[] | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  url?: StringOperationFilterInput | null;
}

export interface DocumentFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  alloyId?: StringOperationFilterInput | null;
  and?: DocumentFilterInput[] | null;
  approvedAt?: DateTimeOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  custodianSignedAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  displayName?: StringOperationFilterInput | null;
  docuSignId?: StringOperationFilterInput | null;
  docuSignTemplate?: NullableOfDocuSignTemplateTypeOperationFilterInput | null;
  files?: ListFilterInputTypeOfDocumentFileFilterInput | null;
  fileTemplate?: NullableOfFileTemplateTypeOperationFilterInput | null;
  funding?: FundingFilterInput | null;
  fundingId?: IntOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  or?: DocumentFilterInput[] | null;
  sentAt?: DateTimeOperationFilterInput | null;
  signedAt?: DateTimeOperationFilterInput | null;
  stage?: DocumentStageOperationFilterInput | null;
  status?: DocumentStatusOperationFilterInput | null;
  type?: DocumentTypeOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
  voidedAt?: DateTimeOperationFilterInput | null;
}

export interface DocumentSortInput {
  account?: AccountSortInput | null;
  accountId?: SortEnumType | null;
  alloyId?: SortEnumType | null;
  approvedAt?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  custodianSignedAt?: SortEnumType | null;
  device?: SortEnumType | null;
  displayName?: SortEnumType | null;
  docuSignId?: SortEnumType | null;
  docuSignTemplate?: SortEnumType | null;
  fileTemplate?: SortEnumType | null;
  funding?: FundingSortInput | null;
  fundingId?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  name?: SortEnumType | null;
  sentAt?: SortEnumType | null;
  signedAt?: SortEnumType | null;
  stage?: SortEnumType | null;
  status?: SortEnumType | null;
  type?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
  user?: UserSortInput | null;
  userId?: SortEnumType | null;
  voidedAt?: SortEnumType | null;
}

export interface DocumentStageOperationFilterInput {
  eq?: DocumentStage | null;
  in?: DocumentStage[] | null;
  neq?: DocumentStage | null;
  nin?: DocumentStage[] | null;
}

export interface DocumentStatusOperationFilterInput {
  eq?: DocumentStatus | null;
  in?: DocumentStatus[] | null;
  neq?: DocumentStatus | null;
  nin?: DocumentStatus[] | null;
}

export interface DocumentTypeOperationFilterInput {
  eq?: DocumentType | null;
  in?: DocumentType[] | null;
  neq?: DocumentType | null;
  nin?: DocumentType[] | null;
}

export interface EmailAddressInput {
  address?: string | null;
  type: EmailAddressType;
}

export interface EmailFilterInput {
  accountId?: IntOperationFilterInput | null;
  and?: EmailFilterInput[] | null;
  bcc?: StringOperationFilterInput | null;
  body?: StringOperationFilterInput | null;
  cc?: StringOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  from?: StringOperationFilterInput | null;
  fundingId?: IntOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: EmailFilterInput[] | null;
  sentByRole?: NullableOfSystemRoleOperationFilterInput | null;
  sentByUserId?: IntOperationFilterInput | null;
  sentDate?: DateTimeOperationFilterInput | null;
  subject?: StringOperationFilterInput | null;
  template?: EmailTemplateTypeOperationFilterInput | null;
  templateId?: StringOperationFilterInput | null;
  to?: StringOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface EmailSortInput {
  accountId?: SortEnumType | null;
  bcc?: SortEnumType | null;
  body?: SortEnumType | null;
  cc?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  from?: SortEnumType | null;
  fundingId?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  sentByRole?: SortEnumType | null;
  sentByUserId?: SortEnumType | null;
  sentDate?: SortEnumType | null;
  subject?: SortEnumType | null;
  template?: SortEnumType | null;
  templateId?: SortEnumType | null;
  to?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
  userId?: SortEnumType | null;
}

export interface EmailTemplateTypeOperationFilterInput {
  eq?: EmailTemplateType | null;
  in?: EmailTemplateType[] | null;
  neq?: EmailTemplateType | null;
  nin?: EmailTemplateType[] | null;
}

export interface ExternalAccountFilterInput {
  accountNumber?: StringOperationFilterInput | null;
  accountType?: AccountTypeOperationFilterInput | null;
  and?: ExternalAccountFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  inboundFundings?: ListFilterInputTypeOfFundingFilterInput | null;
  institutionAddress?: StringOperationFilterInput | null;
  institutionId?: StringOperationFilterInput | null;
  institutionName?: StringOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  isDeleted?: BooleanOperationFilterInput | null;
  isPrimary?: BooleanOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: ExternalAccountFilterInput[] | null;
  otherAccountType?: StringOperationFilterInput | null;
  outboundFundings?: ListFilterInputTypeOfFundingFilterInput | null;
  plaidAccountId?: StringOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface ExternalAccountSortInput {
  accountNumber?: SortEnumType | null;
  accountType?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  id?: SortEnumType | null;
  institutionAddress?: SortEnumType | null;
  institutionId?: SortEnumType | null;
  institutionName?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  isDeleted?: SortEnumType | null;
  isPrimary?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  otherAccountType?: SortEnumType | null;
  plaidAccountId?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
  user?: UserSortInput | null;
  userId?: SortEnumType | null;
}

export interface FileTemplateTypeOperationFilterInput {
  eq?: FileTemplateType | null;
  in?: FileTemplateType[] | null;
  neq?: FileTemplateType | null;
  nin?: FileTemplateType[] | null;
}

export interface FundingAssetFilterInput {
  amount?: DecimalOperationFilterInput | null;
  and?: FundingAssetFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  currencyType?: CurrencyTypeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  fairMarketValue?: DecimalOperationFilterInput | null;
  fairMarketValueTimeStamp?: DateTimeOperationFilterInput | null;
  funding?: FundingFilterInput | null;
  fundingId?: IntOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: FundingAssetFilterInput[] | null;
  originalBalance?: DecimalOperationFilterInput | null;
  paymentType?: NullableOfPaymentTypeOperationFilterInput | null;
  requestedAmount?: DecimalOperationFilterInput | null;
  salesForceOpportunityId?: StringOperationFilterInput | null;
  status?: FundingStatusOperationFilterInput | null;
  transferEntireBalance?: BooleanOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface FundingAssetSortInput {
  amount?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  currencyType?: SortEnumType | null;
  device?: SortEnumType | null;
  fairMarketValue?: SortEnumType | null;
  fairMarketValueTimeStamp?: SortEnumType | null;
  funding?: FundingSortInput | null;
  fundingId?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  originalBalance?: SortEnumType | null;
  paymentType?: SortEnumType | null;
  requestedAmount?: SortEnumType | null;
  salesForceOpportunityId?: SortEnumType | null;
  status?: SortEnumType | null;
  transferEntireBalance?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface FundingDirectionOperationFilterInput {
  eq?: FundingDirection | null;
  in?: FundingDirection[] | null;
  neq?: FundingDirection | null;
  nin?: FundingDirection[] | null;
}

export interface FundingFilterInput {
  amount?: DecimalOperationFilterInput | null;
  and?: FundingFilterInput[] | null;
  associatedFunding?: FundingFilterInput | null;
  associatedFundingId?: IntOperationFilterInput | null;
  canceledAt?: DateTimeOperationFilterInput | null;
  contributionType?: NullableOfContributionTypeOperationFilterInput | null;
  contributionYear?: IntOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  currency?: StringOperationFilterInput | null;
  custodian?: NullableOfCustodianTypeOperationFilterInput | null;
  declinedAt?: DateTimeOperationFilterInput | null;
  destinationCryptoAddress?: StringOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  direction?: FundingDirectionOperationFilterInput | null;
  distributedToClient?: DecimalOperationFilterInput | null;
  distributedTotalAmount?: DecimalOperationFilterInput | null;
  distributionCode?: StringOperationFilterInput | null;
  documentFileUploadedAt?: DateTimeOperationFilterInput | null;
  documents?: ListFilterInputTypeOfDocumentFilterInput | null;
  fairMarketValue?: DecimalOperationFilterInput | null;
  fairMarketValueTimeStamp?: DateTimeOperationFilterInput | null;
  federalWithholdings?: DecimalOperationFilterInput | null;
  federalWithholdingsTransactionId?: StringOperationFilterInput | null;
  fromAccount?: AccountFilterInput | null;
  fromAccountId?: IntOperationFilterInput | null;
  fromExternalAccount?: ExternalAccountFilterInput | null;
  fromExternalAccountId?: IntOperationFilterInput | null;
  fundedAt?: DateTimeOperationFilterInput | null;
  fundingAssets?: ListFilterInputTypeOfFundingAssetFilterInput | null;
  fundingStageHistories?: ListFilterInputTypeOfFundingStageHistoryFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iraTransferInstruction?: NullableOfIraTransferInstructionOperationFilterInput | null;
  isFullTransfer?: BooleanOperationFilterInput | null;
  isInitialFunding?: BooleanOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  marketingEvent?: MarketingEventFilterInput | null;
  microTestAmount?: DecimalOperationFilterInput | null;
  microTestAmountConfirmed?: BooleanOperationFilterInput | null;
  microTestTransactionId?: StringOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: FundingFilterInput[] | null;
  originalBalance?: DecimalOperationFilterInput | null;
  paymentId?: StringOperationFilterInput | null;
  paymentType?: PaymentTypeOperationFilterInput | null;
  promo?: PromoFilterInput | null;
  promoCode?: StringOperationFilterInput | null;
  requestedAmount?: DecimalOperationFilterInput | null;
  rolloverFrom?: NullableOfRolloverFromTypeOperationFilterInput | null;
  rolloverType?: NullableOfRolloverTypeOperationFilterInput | null;
  signatureBase64?: StringOperationFilterInput | null;
  sourceCryptoAddress?: StringOperationFilterInput | null;
  stage?: FundingStageOperationFilterInput | null;
  stateWithholdings?: DecimalOperationFilterInput | null;
  stateWithholdingsTransactionId?: StringOperationFilterInput | null;
  status?: FundingStatusOperationFilterInput | null;
  toAccount?: AccountFilterInput | null;
  toAccountId?: IntOperationFilterInput | null;
  toExternalAccount?: ExternalAccountFilterInput | null;
  toExternalAccountId?: IntOperationFilterInput | null;
  transactionCode?: StringOperationFilterInput | null;
  transactionId?: StringOperationFilterInput | null;
  transactionStatus?: StringOperationFilterInput | null;
  type?: FundingTypeOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface FundingSortInput {
  amount?: SortEnumType | null;
  associatedFunding?: FundingSortInput | null;
  associatedFundingId?: SortEnumType | null;
  canceledAt?: SortEnumType | null;
  contributionType?: SortEnumType | null;
  contributionYear?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  currency?: SortEnumType | null;
  custodian?: SortEnumType | null;
  declinedAt?: SortEnumType | null;
  destinationCryptoAddress?: SortEnumType | null;
  device?: SortEnumType | null;
  direction?: SortEnumType | null;
  distributedToClient?: SortEnumType | null;
  distributedTotalAmount?: SortEnumType | null;
  distributionCode?: SortEnumType | null;
  documentFileUploadedAt?: SortEnumType | null;
  fairMarketValue?: SortEnumType | null;
  fairMarketValueTimeStamp?: SortEnumType | null;
  federalWithholdings?: SortEnumType | null;
  federalWithholdingsTransactionId?: SortEnumType | null;
  fromAccount?: AccountSortInput | null;
  fromAccountId?: SortEnumType | null;
  fromExternalAccount?: ExternalAccountSortInput | null;
  fromExternalAccountId?: SortEnumType | null;
  fundedAt?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iraTransferInstruction?: SortEnumType | null;
  isFullTransfer?: SortEnumType | null;
  isInitialFunding?: SortEnumType | null;
  iteration?: SortEnumType | null;
  marketingEvent?: MarketingEventSortInput | null;
  microTestAmount?: SortEnumType | null;
  microTestAmountConfirmed?: SortEnumType | null;
  microTestTransactionId?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  originalBalance?: SortEnumType | null;
  paymentId?: SortEnumType | null;
  paymentType?: SortEnumType | null;
  promo?: PromoSortInput | null;
  promoCode?: SortEnumType | null;
  requestedAmount?: SortEnumType | null;
  rolloverFrom?: SortEnumType | null;
  rolloverType?: SortEnumType | null;
  signatureBase64?: SortEnumType | null;
  sourceCryptoAddress?: SortEnumType | null;
  stage?: SortEnumType | null;
  stateWithholdings?: SortEnumType | null;
  stateWithholdingsTransactionId?: SortEnumType | null;
  status?: SortEnumType | null;
  toAccount?: AccountSortInput | null;
  toAccountId?: SortEnumType | null;
  toExternalAccount?: ExternalAccountSortInput | null;
  toExternalAccountId?: SortEnumType | null;
  transactionCode?: SortEnumType | null;
  transactionId?: SortEnumType | null;
  transactionStatus?: SortEnumType | null;
  type?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface FundingStageHistoryFilterInput {
  and?: FundingStageHistoryFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  funding?: FundingFilterInput | null;
  fundingId?: IntOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: FundingStageHistoryFilterInput[] | null;
  stage?: FundingStageOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface FundingStageOperationFilterInput {
  eq?: FundingStage | null;
  in?: FundingStage[] | null;
  neq?: FundingStage | null;
  nin?: FundingStage[] | null;
}

export interface FundingStatusOperationFilterInput {
  eq?: FundingStatus | null;
  in?: FundingStatus[] | null;
  neq?: FundingStatus | null;
  nin?: FundingStatus[] | null;
}

export interface FundingTypeOperationFilterInput {
  eq?: FundingType | null;
  in?: FundingType[] | null;
  neq?: FundingType | null;
  nin?: FundingType[] | null;
}

export interface GenerateDepositInstructionsInput {
  accountId: number;
  type: FundingInstructionType;
}

export interface GenerateDocumentPreviewUrlInput {
  id: number;
  returnUrl: string;
}

export interface GenerateDocumentSignerUrlInput {
  id: number;
  returnUrl: string;
}

export interface GenerateDocumentUploadTokenInput {
  extension?: string | null;
  template: FileTemplateType;
  type: DocumentType;
}

export interface GenerateFundingsReportInput {
  createdFrom: any;
  createdTo: any;
  currencyType: CurrencyType;
}

export interface GenerateServicePinInput {
  userId: number;
}

export interface GetAccountHistoriesInput {
  accountId: number;
}

export interface GetAccountsInput {
  documentStage: DocumentStage;
  docuSignTemplateTypes?: (DocuSignTemplateType | null)[] | null;
}

export interface GetEmailTemplateInput {
  accountId?: number | null;
  templateType: EmailTemplateType;
  userId: number;
}

export interface GetFundingHistoriesInput {
  fundingId: number;
}

export interface GetFundingsInput {
  maxAmount?: any | null;
  minAmount?: any | null;
}

export interface GetUserHistoriesInput {
  userId: number;
}

export interface GetUsersInput {
  numberFundingsPendingFrom?: number | null;
  numberFundingsPendingTo?: number | null;
}

export interface HistoricalQuoteFilterInput {
  and?: HistoricalQuoteFilterInput[] | null;
  buyPrice?: DecimalOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  currencyType?: CurrencyTypeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  liquidityProvider?: LiquidityProviderFilterInput | null;
  liquidityProviderId?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: HistoricalQuoteFilterInput[] | null;
  sellPrice?: DecimalOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface IdentificationFilterInput {
  and?: IdentificationFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  fileTemplate?: FileTemplateTypeOperationFilterInput | null;
  fileUrl?: StringOperationFilterInput | null;
  fileUrls?: ListStringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  or?: IdentificationFilterInput[] | null;
  reviewedAt?: DateTimeOperationFilterInput | null;
  status?: IdentificationStatusOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface IdentificationSortInput {
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  fileTemplate?: SortEnumType | null;
  fileUrl?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  name?: SortEnumType | null;
  reviewedAt?: SortEnumType | null;
  status?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
  user?: UserSortInput | null;
  userId?: SortEnumType | null;
}

export interface IdentificationStatusOperationFilterInput {
  eq?: IdentificationStatus | null;
  in?: IdentificationStatus[] | null;
  neq?: IdentificationStatus | null;
  nin?: IdentificationStatus[] | null;
}

export interface IntOperationFilterInput {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: (number | null)[] | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
  ngt?: number | null;
  ngte?: number | null;
  nin?: (number | null)[] | null;
  nlt?: number | null;
  nlte?: number | null;
}

export interface IrsInfoFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  and?: IrsInfoFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  lifeExpectancyValue?: DecimalOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: IrsInfoFilterInput[] | null;
  requiredMinimumDistribution?: DecimalOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  year?: IntOperationFilterInput | null;
}

export interface KitcoFeeAuditFilterInput {
  and?: KitcoFeeAuditFilterInput[] | null;
  clientFee?: DecimalOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  currencyType?: CurrencyTypeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  effectiveCost?: DecimalOperationFilterInput | null;
  estimatedClientFee?: DecimalOperationFilterInput | null;
  estimatedItcFee?: DecimalOperationFilterInput | null;
  estimatedKitcoFee?: DecimalOperationFilterInput | null;
  estimatedOunces?: DecimalOperationFilterInput | null;
  estimatedTradeCost?: DecimalOperationFilterInput | null;
  executedMarketPrice?: DecimalOperationFilterInput | null;
  executedSpotPrice?: DecimalOperationFilterInput | null;
  feePercentage?: DecimalOperationFilterInput | null;
  feePerOunce?: DecimalOperationFilterInput | null;
  fromAmountExecuted?: DecimalOperationFilterInput | null;
  fromAmountInput?: DecimalOperationFilterInput | null;
  fromAmountRequested?: DecimalOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  itcFee?: DecimalOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  kitcoFee?: DecimalOperationFilterInput | null;
  marketOrder?: MarketOrderFilterInput | null;
  marketOrderId?: IntOperationFilterInput | null;
  marketPrice?: DecimalOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: KitcoFeeAuditFilterInput[] | null;
  orderType?: OrderTypeOperationFilterInput | null;
  spotPrice?: DecimalOperationFilterInput | null;
  toAmountExecuted?: DecimalOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface KitcoFeeAuditSortInput {
  clientFee?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  currencyType?: SortEnumType | null;
  device?: SortEnumType | null;
  effectiveCost?: SortEnumType | null;
  estimatedClientFee?: SortEnumType | null;
  estimatedItcFee?: SortEnumType | null;
  estimatedKitcoFee?: SortEnumType | null;
  estimatedOunces?: SortEnumType | null;
  estimatedTradeCost?: SortEnumType | null;
  executedMarketPrice?: SortEnumType | null;
  executedSpotPrice?: SortEnumType | null;
  feePercentage?: SortEnumType | null;
  feePerOunce?: SortEnumType | null;
  fromAmountExecuted?: SortEnumType | null;
  fromAmountInput?: SortEnumType | null;
  fromAmountRequested?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  itcFee?: SortEnumType | null;
  iteration?: SortEnumType | null;
  kitcoFee?: SortEnumType | null;
  marketOrder?: MarketOrderSortInput | null;
  marketOrderId?: SortEnumType | null;
  marketPrice?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  orderType?: SortEnumType | null;
  spotPrice?: SortEnumType | null;
  toAmountExecuted?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface KycEvaluationFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  alloyId?: StringOperationFilterInput | null;
  and?: KycEvaluationFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: KycEvaluationFilterInput[] | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  upgradeRequirements?: StringOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface KycStatusOperationFilterInput {
  eq?: KycStatus | null;
  in?: KycStatus[] | null;
  neq?: KycStatus | null;
  nin?: KycStatus[] | null;
}

export interface LimitTypeOperationFilterInput {
  eq?: LimitType | null;
  in?: LimitType[] | null;
  neq?: LimitType | null;
  nin?: LimitType[] | null;
}

export interface LiquidityProviderConfigurationFilterInput {
  and?: LiquidityProviderConfigurationFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  currency?: CurrencyFilterInput | null;
  currencyId?: CurrencyTypeOperationFilterInput | null;
  currencyV2?: CurrencyV2FilterInput | null;
  currencyV2Id?: IntOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  enabled?: BooleanOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  limitType?: LimitTypeOperationFilterInput | null;
  liquidityProvider?: LiquidityProviderFilterInput | null;
  liquidityProviderId?: IntOperationFilterInput | null;
  maxOrder?: DecimalOperationFilterInput | null;
  minOrder?: DecimalOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: LiquidityProviderConfigurationFilterInput[] | null;
  precision?: IntOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface LiquidityProviderFilterInput {
  and?: LiquidityProviderFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  enabled?: BooleanOperationFilterInput | null;
  historicalQuotes?: ListFilterInputTypeOfHistoricalQuoteFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  liquidityProviderConfigurations?: ListFilterInputTypeOfLiquidityProviderConfigurationFilterInput | null;
  liquidityProviderOutages?: ListFilterInputTypeOfLiquidityProviderOutageFilterInput | null;
  liquidityRequests?: ListFilterInputTypeOfLiquidityRequestFilterInput | null;
  marketOrders?: ListFilterInputTypeOfMarketOrderFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  or?: LiquidityProviderFilterInput[] | null;
  priceSpreads?: ListFilterInputTypeOfPriceSpreadFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface LiquidityProviderOutageFilterInput {
  and?: LiquidityProviderOutageFilterInput[] | null;
  completed?: BooleanOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  endDate?: DateTimeOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  liquidityProvider?: LiquidityProviderFilterInput | null;
  liquidityProviderId?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: LiquidityProviderOutageFilterInput[] | null;
  startDate?: DateTimeOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface LiquidityProviderSortInput {
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  enabled?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  name?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface LiquidityRequestFilterInput {
  and?: LiquidityRequestFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  durationMilliseconds?: IntOperationFilterInput | null;
  httpStatusCode?: IntOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  liquidityProvider?: LiquidityProviderFilterInput | null;
  liquidityProviderId?: IntOperationFilterInput | null;
  marketOrder?: MarketOrderFilterInput | null;
  marketOrderId?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: LiquidityRequestFilterInput[] | null;
  rawRequest?: StringOperationFilterInput | null;
  rawResponse?: StringOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface ListAccountOrderOperationFilterInput {
  all?: AccountOrderOperationFilterInput | null;
  any?: boolean | null;
  none?: AccountOrderOperationFilterInput | null;
  some?: AccountOrderOperationFilterInput | null;
}

export interface ListFilterInputTypeOfAccountBeneficiaryFilterInput {
  all?: AccountBeneficiaryFilterInput | null;
  any?: boolean | null;
  none?: AccountBeneficiaryFilterInput | null;
  some?: AccountBeneficiaryFilterInput | null;
}

export interface ListFilterInputTypeOfAccountFilterInput {
  all?: AccountFilterInput | null;
  any?: boolean | null;
  none?: AccountFilterInput | null;
  some?: AccountFilterInput | null;
}

export interface ListFilterInputTypeOfAccountStageHistoryFilterInput {
  all?: AccountStageHistoryFilterInput | null;
  any?: boolean | null;
  none?: AccountStageHistoryFilterInput | null;
  some?: AccountStageHistoryFilterInput | null;
}

export interface ListFilterInputTypeOfBeneficiaryAddressFilterInput {
  all?: BeneficiaryAddressFilterInput | null;
  any?: boolean | null;
  none?: BeneficiaryAddressFilterInput | null;
  some?: BeneficiaryAddressFilterInput | null;
}

export interface ListFilterInputTypeOfBeneficiaryFilterInput {
  all?: BeneficiaryFilterInput | null;
  any?: boolean | null;
  none?: BeneficiaryFilterInput | null;
  some?: BeneficiaryFilterInput | null;
}

export interface ListFilterInputTypeOfConditionalTransactionFilterInput {
  all?: ConditionalTransactionFilterInput | null;
  any?: boolean | null;
  none?: ConditionalTransactionFilterInput | null;
  some?: ConditionalTransactionFilterInput | null;
}

export interface ListFilterInputTypeOfCrmPasswordHistoryFilterInput {
  all?: CrmPasswordHistoryFilterInput | null;
  any?: boolean | null;
  none?: CrmPasswordHistoryFilterInput | null;
  some?: CrmPasswordHistoryFilterInput | null;
}

export interface ListFilterInputTypeOfCrmUserLoginHistoryFilterInput {
  all?: CrmUserLoginHistoryFilterInput | null;
  any?: boolean | null;
  none?: CrmUserLoginHistoryFilterInput | null;
  some?: CrmUserLoginHistoryFilterInput | null;
}

export interface ListFilterInputTypeOfCustodianAccountFilterInput {
  all?: CustodianAccountFilterInput | null;
  any?: boolean | null;
  none?: CustodianAccountFilterInput | null;
  some?: CustodianAccountFilterInput | null;
}

export interface ListFilterInputTypeOfDocumentFileFilterInput {
  all?: DocumentFileFilterInput | null;
  any?: boolean | null;
  none?: DocumentFileFilterInput | null;
  some?: DocumentFileFilterInput | null;
}

export interface ListFilterInputTypeOfDocumentFilterInput {
  all?: DocumentFilterInput | null;
  any?: boolean | null;
  none?: DocumentFilterInput | null;
  some?: DocumentFilterInput | null;
}

export interface ListFilterInputTypeOfExternalAccountFilterInput {
  all?: ExternalAccountFilterInput | null;
  any?: boolean | null;
  none?: ExternalAccountFilterInput | null;
  some?: ExternalAccountFilterInput | null;
}

export interface ListFilterInputTypeOfFundingAssetFilterInput {
  all?: FundingAssetFilterInput | null;
  any?: boolean | null;
  none?: FundingAssetFilterInput | null;
  some?: FundingAssetFilterInput | null;
}

export interface ListFilterInputTypeOfFundingFilterInput {
  all?: FundingFilterInput | null;
  any?: boolean | null;
  none?: FundingFilterInput | null;
  some?: FundingFilterInput | null;
}

export interface ListFilterInputTypeOfFundingStageHistoryFilterInput {
  all?: FundingStageHistoryFilterInput | null;
  any?: boolean | null;
  none?: FundingStageHistoryFilterInput | null;
  some?: FundingStageHistoryFilterInput | null;
}

export interface ListFilterInputTypeOfHistoricalQuoteFilterInput {
  all?: HistoricalQuoteFilterInput | null;
  any?: boolean | null;
  none?: HistoricalQuoteFilterInput | null;
  some?: HistoricalQuoteFilterInput | null;
}

export interface ListFilterInputTypeOfIrsInfoFilterInput {
  all?: IrsInfoFilterInput | null;
  any?: boolean | null;
  none?: IrsInfoFilterInput | null;
  some?: IrsInfoFilterInput | null;
}

export interface ListFilterInputTypeOfKycEvaluationFilterInput {
  all?: KycEvaluationFilterInput | null;
  any?: boolean | null;
  none?: KycEvaluationFilterInput | null;
  some?: KycEvaluationFilterInput | null;
}

export interface ListFilterInputTypeOfLiquidityProviderConfigurationFilterInput {
  all?: LiquidityProviderConfigurationFilterInput | null;
  any?: boolean | null;
  none?: LiquidityProviderConfigurationFilterInput | null;
  some?: LiquidityProviderConfigurationFilterInput | null;
}

export interface ListFilterInputTypeOfLiquidityProviderOutageFilterInput {
  all?: LiquidityProviderOutageFilterInput | null;
  any?: boolean | null;
  none?: LiquidityProviderOutageFilterInput | null;
  some?: LiquidityProviderOutageFilterInput | null;
}

export interface ListFilterInputTypeOfLiquidityRequestFilterInput {
  all?: LiquidityRequestFilterInput | null;
  any?: boolean | null;
  none?: LiquidityRequestFilterInput | null;
  some?: LiquidityRequestFilterInput | null;
}

export interface ListFilterInputTypeOfMarketOrderFilterInput {
  all?: MarketOrderFilterInput | null;
  any?: boolean | null;
  none?: MarketOrderFilterInput | null;
  some?: MarketOrderFilterInput | null;
}

export interface ListFilterInputTypeOfMarketingEventFilterInput {
  all?: MarketingEventFilterInput | null;
  any?: boolean | null;
  none?: MarketingEventFilterInput | null;
  some?: MarketingEventFilterInput | null;
}

export interface ListFilterInputTypeOfNoteFilterInput {
  all?: NoteFilterInput | null;
  any?: boolean | null;
  none?: NoteFilterInput | null;
  some?: NoteFilterInput | null;
}

export interface ListFilterInputTypeOfNotificationSettingFilterInput {
  all?: NotificationSettingFilterInput | null;
  any?: boolean | null;
  none?: NotificationSettingFilterInput | null;
  some?: NotificationSettingFilterInput | null;
}

export interface ListFilterInputTypeOfPriceSpreadFilterInput {
  all?: PriceSpreadFilterInput | null;
  any?: boolean | null;
  none?: PriceSpreadFilterInput | null;
  some?: PriceSpreadFilterInput | null;
}

export interface ListFilterInputTypeOfRecurringPurchaseFilterInput {
  all?: RecurringPurchaseFilterInput | null;
  any?: boolean | null;
  none?: RecurringPurchaseFilterInput | null;
  some?: RecurringPurchaseFilterInput | null;
}

export interface ListFilterInputTypeOfRecurringPurchaseHistoryFilterInput {
  all?: RecurringPurchaseHistoryFilterInput | null;
  any?: boolean | null;
  none?: RecurringPurchaseHistoryFilterInput | null;
  some?: RecurringPurchaseHistoryFilterInput | null;
}

export interface ListFilterInputTypeOfReferralFilterInput {
  all?: ReferralFilterInput | null;
  any?: boolean | null;
  none?: ReferralFilterInput | null;
  some?: ReferralFilterInput | null;
}

export interface ListFilterInputTypeOfRiaRelationshipFilterInput {
  all?: RiaRelationshipFilterInput | null;
  any?: boolean | null;
  none?: RiaRelationshipFilterInput | null;
  some?: RiaRelationshipFilterInput | null;
}

export interface ListFilterInputTypeOfSecurityAnswerFilterInput {
  all?: SecurityAnswerFilterInput | null;
  any?: boolean | null;
  none?: SecurityAnswerFilterInput | null;
  some?: SecurityAnswerFilterInput | null;
}

export interface ListFilterInputTypeOfSmsMessageFilterInput {
  all?: SmsMessageFilterInput | null;
  any?: boolean | null;
  none?: SmsMessageFilterInput | null;
  some?: SmsMessageFilterInput | null;
}

export interface ListFilterInputTypeOfSmsPhoneNumberFilterInput {
  all?: SmsPhoneNumberFilterInput | null;
  any?: boolean | null;
  none?: SmsPhoneNumberFilterInput | null;
  some?: SmsPhoneNumberFilterInput | null;
}

export interface ListFilterInputTypeOfStakingEnrollmentFilterInput {
  all?: StakingEnrollmentFilterInput | null;
  any?: boolean | null;
  none?: StakingEnrollmentFilterInput | null;
  some?: StakingEnrollmentFilterInput | null;
}

export interface ListFilterInputTypeOfStakingMovementFilterInput {
  all?: StakingMovementFilterInput | null;
  any?: boolean | null;
  none?: StakingMovementFilterInput | null;
  some?: StakingMovementFilterInput | null;
}

export interface ListFilterInputTypeOfSubscriptionBillingChargeFilterInput {
  all?: SubscriptionBillingChargeFilterInput | null;
  any?: boolean | null;
  none?: SubscriptionBillingChargeFilterInput | null;
  some?: SubscriptionBillingChargeFilterInput | null;
}

export interface ListFilterInputTypeOfSubscriptionBillingFilterInput {
  all?: SubscriptionBillingFilterInput | null;
  any?: boolean | null;
  none?: SubscriptionBillingFilterInput | null;
  some?: SubscriptionBillingFilterInput | null;
}

export interface ListFilterInputTypeOfTradeFeeCreditHistoryFilterInput {
  all?: TradeFeeCreditHistoryFilterInput | null;
  any?: boolean | null;
  none?: TradeFeeCreditHistoryFilterInput | null;
  some?: TradeFeeCreditHistoryFilterInput | null;
}

export interface ListFilterInputTypeOfTrustPilotInvitationFilterInput {
  all?: TrustPilotInvitationFilterInput | null;
  any?: boolean | null;
  none?: TrustPilotInvitationFilterInput | null;
  some?: TrustPilotInvitationFilterInput | null;
}

export interface ListFilterInputTypeOfUserAddressFilterInput {
  all?: UserAddressFilterInput | null;
  any?: boolean | null;
  none?: UserAddressFilterInput | null;
  some?: UserAddressFilterInput | null;
}

export interface ListFilterInputTypeOfUserFilterInput {
  all?: UserFilterInput | null;
  any?: boolean | null;
  none?: UserFilterInput | null;
  some?: UserFilterInput | null;
}

export interface ListFilterInputTypeOfUserStageHistoryFilterInput {
  all?: UserStageHistoryFilterInput | null;
  any?: boolean | null;
  none?: UserStageHistoryFilterInput | null;
  some?: UserStageHistoryFilterInput | null;
}

export interface ListFilterInputTypeOfWalletFilterInput {
  all?: WalletFilterInput | null;
  any?: boolean | null;
  none?: WalletFilterInput | null;
  some?: WalletFilterInput | null;
}

export interface ListFilterInputTypeOfWalletHistoryFilterInput {
  all?: WalletHistoryFilterInput | null;
  any?: boolean | null;
  none?: WalletHistoryFilterInput | null;
  some?: WalletHistoryFilterInput | null;
}

export interface ListFilterInputTypeOfWatchCurrencyFilterInput {
  all?: WatchCurrencyFilterInput | null;
  any?: boolean | null;
  none?: WatchCurrencyFilterInput | null;
  some?: WatchCurrencyFilterInput | null;
}

export interface ListStringOperationFilterInput {
  all?: StringOperationFilterInput | null;
  any?: boolean | null;
  none?: StringOperationFilterInput | null;
  some?: StringOperationFilterInput | null;
}

export interface ListTourTypeOperationFilterInput {
  all?: TourTypeOperationFilterInput | null;
  any?: boolean | null;
  none?: TourTypeOperationFilterInput | null;
  some?: TourTypeOperationFilterInput | null;
}

export interface MarketOrderFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  and?: MarketOrderFilterInput[] | null;
  conditionalTransaction?: ConditionalTransactionFilterInput | null;
  conditionalTransactionId?: IntOperationFilterInput | null;
  correlationId?: StringOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  custodialTransactionId?: StringOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  deviceInfo?: StringOperationFilterInput | null;
  displayAmount?: DecimalOperationFilterInput | null;
  displayFee?: DecimalOperationFilterInput | null;
  displayFeeCredit?: DecimalOperationFilterInput | null;
  displayQuantity?: DecimalOperationFilterInput | null;
  displayTotal?: DecimalOperationFilterInput | null;
  displayUnitPrice?: DecimalOperationFilterInput | null;
  executedAt?: DateTimeOperationFilterInput | null;
  executedUnitPrice?: DecimalOperationFilterInput | null;
  failedAt?: DateTimeOperationFilterInput | null;
  failureReason?: StringOperationFilterInput | null;
  feeAmount?: DecimalOperationFilterInput | null;
  feeBasisPoints?: IntOperationFilterInput | null;
  feeCredit?: DecimalOperationFilterInput | null;
  feeInitialAmount?: DecimalOperationFilterInput | null;
  feeStatus?: StringOperationFilterInput | null;
  feeTransactionId?: UuidOperationFilterInput | null;
  fortressFeeDiff?: DecimalOperationFilterInput | null;
  fortressFeeTotal?: DecimalOperationFilterInput | null;
  fortressItcFeeAmount?: DecimalOperationFilterInput | null;
  fortressTradeXAmount?: DecimalOperationFilterInput | null;
  fromAmountExecuted?: DecimalOperationFilterInput | null;
  fromAmountInput?: DecimalOperationFilterInput | null;
  fromAmountRequested?: DecimalOperationFilterInput | null;
  fromCurrency?: CurrencyFilterInput | null;
  fromCurrencyId?: CurrencyTypeOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  integratedFee?: BooleanOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  kitcoFeeAudit?: KitcoFeeAuditFilterInput | null;
  kitcoSettlementTransactionId?: UuidOperationFilterInput | null;
  kitcoSettlementTransactionStatus?: StringOperationFilterInput | null;
  kitcoTransactionId?: UuidOperationFilterInput | null;
  kitcoTransactionStatus?: StringOperationFilterInput | null;
  liquidityProvider?: LiquidityProviderFilterInput | null;
  liquidityProviderId?: IntOperationFilterInput | null;
  liquidityRequests?: ListFilterInputTypeOfLiquidityRequestFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: MarketOrderFilterInput[] | null;
  orderType?: OrderTypeOperationFilterInput | null;
  quotePrice?: DecimalOperationFilterInput | null;
  recurringPurchaseHistory?: RecurringPurchaseHistoryFilterInput | null;
  recurringPurchaseHistoryId?: IntOperationFilterInput | null;
  status?: MarketOrderStatusOperationFilterInput | null;
  toAmountExecuted?: DecimalOperationFilterInput | null;
  toAmountOutput?: DecimalOperationFilterInput | null;
  toCurrency?: CurrencyFilterInput | null;
  toCurrencyId?: CurrencyTypeOperationFilterInput | null;
  tradeXAmount?: DecimalOperationFilterInput | null;
  tradeXBasisPoints?: IntOperationFilterInput | null;
  trigger?: MarketOrderTriggerOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface MarketOrderSortInput {
  account?: AccountSortInput | null;
  accountId?: SortEnumType | null;
  conditionalTransaction?: ConditionalTransactionSortInput | null;
  conditionalTransactionId?: SortEnumType | null;
  correlationId?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  custodialTransactionId?: SortEnumType | null;
  device?: SortEnumType | null;
  deviceInfo?: SortEnumType | null;
  displayAmount?: SortEnumType | null;
  displayFee?: SortEnumType | null;
  displayFeeCredit?: SortEnumType | null;
  displayQuantity?: SortEnumType | null;
  displayTotal?: SortEnumType | null;
  displayUnitPrice?: SortEnumType | null;
  executedAt?: SortEnumType | null;
  executedUnitPrice?: SortEnumType | null;
  failedAt?: SortEnumType | null;
  failureReason?: SortEnumType | null;
  feeAmount?: SortEnumType | null;
  feeBasisPoints?: SortEnumType | null;
  feeCredit?: SortEnumType | null;
  feeInitialAmount?: SortEnumType | null;
  feeStatus?: SortEnumType | null;
  feeTransactionId?: SortEnumType | null;
  fortressFeeDiff?: SortEnumType | null;
  fortressFeeTotal?: SortEnumType | null;
  fortressItcFeeAmount?: SortEnumType | null;
  fortressTradeXAmount?: SortEnumType | null;
  fromAmountExecuted?: SortEnumType | null;
  fromAmountInput?: SortEnumType | null;
  fromAmountRequested?: SortEnumType | null;
  fromCurrency?: CurrencySortInput | null;
  fromCurrencyId?: SortEnumType | null;
  id?: SortEnumType | null;
  integratedFee?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  kitcoFeeAudit?: KitcoFeeAuditSortInput | null;
  kitcoSettlementTransactionId?: SortEnumType | null;
  kitcoSettlementTransactionStatus?: SortEnumType | null;
  kitcoTransactionId?: SortEnumType | null;
  kitcoTransactionStatus?: SortEnumType | null;
  liquidityProvider?: LiquidityProviderSortInput | null;
  liquidityProviderId?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  orderType?: SortEnumType | null;
  quotePrice?: SortEnumType | null;
  recurringPurchaseHistory?: RecurringPurchaseHistorySortInput | null;
  recurringPurchaseHistoryId?: SortEnumType | null;
  status?: SortEnumType | null;
  toAmountExecuted?: SortEnumType | null;
  toAmountOutput?: SortEnumType | null;
  toCurrency?: CurrencySortInput | null;
  toCurrencyId?: SortEnumType | null;
  tradeXAmount?: SortEnumType | null;
  tradeXBasisPoints?: SortEnumType | null;
  trigger?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface MarketOrderStatusOperationFilterInput {
  eq?: MarketOrderStatus | null;
  in?: MarketOrderStatus[] | null;
  neq?: MarketOrderStatus | null;
  nin?: MarketOrderStatus[] | null;
}

export interface MarketOrderTriggerOperationFilterInput {
  eq?: MarketOrderTrigger | null;
  in?: MarketOrderTrigger[] | null;
  neq?: MarketOrderTrigger | null;
  nin?: MarketOrderTrigger[] | null;
}

export interface MarketingAttributeFilterInput {
  affiliateId?: StringOperationFilterInput | null;
  and?: MarketingAttributeFilterInput[] | null;
  campaign?: StringOperationFilterInput | null;
  cjAffiliateId?: StringOperationFilterInput | null;
  content?: StringOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  everflowTransactionId?: StringOperationFilterInput | null;
  googleClickId?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  medium?: StringOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  offerId?: StringOperationFilterInput | null;
  or?: MarketingAttributeFilterInput[] | null;
  promoCode?: StringOperationFilterInput | null;
  referralClicks?: IntOperationFilterInput | null;
  referralId?: StringOperationFilterInput | null;
  source?: StringOperationFilterInput | null;
  term?: StringOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface MarketingAttributeSortInput {
  affiliateId?: SortEnumType | null;
  campaign?: SortEnumType | null;
  cjAffiliateId?: SortEnumType | null;
  content?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  everflowTransactionId?: SortEnumType | null;
  googleClickId?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  medium?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  offerId?: SortEnumType | null;
  promoCode?: SortEnumType | null;
  referralClicks?: SortEnumType | null;
  referralId?: SortEnumType | null;
  source?: SortEnumType | null;
  term?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
  user?: UserSortInput | null;
  userId?: SortEnumType | null;
}

export interface MarketingEventFilterInput {
  and?: MarketingEventFilterInput[] | null;
  campaign?: StringOperationFilterInput | null;
  content?: StringOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  funding?: FundingFilterInput | null;
  fundingId?: IntOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  marketingEventType?: MarketingEventTypeOperationFilterInput | null;
  medium?: StringOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: MarketingEventFilterInput[] | null;
  source?: StringOperationFilterInput | null;
  term?: StringOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface MarketingEventSortInput {
  campaign?: SortEnumType | null;
  content?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  funding?: FundingSortInput | null;
  fundingId?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  marketingEventType?: SortEnumType | null;
  medium?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  source?: SortEnumType | null;
  term?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
  user?: UserSortInput | null;
  userId?: SortEnumType | null;
}

export interface MarketingEventTypeOperationFilterInput {
  eq?: MarketingEventType | null;
  in?: MarketingEventType[] | null;
  neq?: MarketingEventType | null;
  nin?: MarketingEventType[] | null;
}

export interface NoteFilterInput {
  accountId?: IntOperationFilterInput | null;
  and?: NoteFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  createdByUser?: CrmUserFilterInput | null;
  createdByUserId?: IntOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  fundingId?: IntOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: NoteFilterInput[] | null;
  status?: NoteStatusOperationFilterInput | null;
  subject?: StringOperationFilterInput | null;
  text?: StringOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface NoteSortInput {
  accountId?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  createdByUser?: CrmUserSortInput | null;
  createdByUserId?: SortEnumType | null;
  device?: SortEnumType | null;
  fundingId?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  status?: SortEnumType | null;
  subject?: SortEnumType | null;
  text?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
  userId?: SortEnumType | null;
}

export interface NoteStatusOperationFilterInput {
  eq?: NoteStatus | null;
  in?: NoteStatus[] | null;
  neq?: NoteStatus | null;
  nin?: NoteStatus[] | null;
}

export interface NotificationSettingFilterInput {
  and?: NotificationSettingFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  notificationType?: NotificationTypeOperationFilterInput | null;
  or?: NotificationSettingFilterInput[] | null;
  type?: NotificationSettingTypeOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface NotificationSettingTypeOperationFilterInput {
  eq?: NotificationSettingType | null;
  in?: NotificationSettingType[] | null;
  neq?: NotificationSettingType | null;
  nin?: NotificationSettingType[] | null;
}

export interface NotificationTypeOperationFilterInput {
  eq?: NotificationType | null;
  in?: NotificationType[] | null;
  neq?: NotificationType | null;
  nin?: NotificationType[] | null;
}

export interface NullableOfBeneficiaryDirectiveOperationFilterInput {
  eq?: BeneficiaryDirective | null;
  in?: (BeneficiaryDirective | null)[] | null;
  neq?: BeneficiaryDirective | null;
  nin?: (BeneficiaryDirective | null)[] | null;
}

export interface NullableOfChurnReasonOperationFilterInput {
  eq?: ChurnReason | null;
  in?: (ChurnReason | null)[] | null;
  neq?: ChurnReason | null;
  nin?: (ChurnReason | null)[] | null;
}

export interface NullableOfContributionTypeOperationFilterInput {
  eq?: ContributionType | null;
  in?: (ContributionType | null)[] | null;
  neq?: ContributionType | null;
  nin?: (ContributionType | null)[] | null;
}

export interface NullableOfCustodianTypeOperationFilterInput {
  eq?: CustodianType | null;
  in?: (CustodianType | null)[] | null;
  neq?: CustodianType | null;
  nin?: (CustodianType | null)[] | null;
}

export interface NullableOfDocuSignTemplateTypeOperationFilterInput {
  eq?: DocuSignTemplateType | null;
  in?: (DocuSignTemplateType | null)[] | null;
  neq?: DocuSignTemplateType | null;
  nin?: (DocuSignTemplateType | null)[] | null;
}

export interface NullableOfEmploymentStatusOperationFilterInput {
  eq?: EmploymentStatus | null;
  in?: (EmploymentStatus | null)[] | null;
  neq?: EmploymentStatus | null;
  nin?: (EmploymentStatus | null)[] | null;
}

export interface NullableOfFileTemplateTypeOperationFilterInput {
  eq?: FileTemplateType | null;
  in?: (FileTemplateType | null)[] | null;
  neq?: FileTemplateType | null;
  nin?: (FileTemplateType | null)[] | null;
}

export interface NullableOfIraTransferInstructionOperationFilterInput {
  eq?: IraTransferInstruction | null;
  in?: (IraTransferInstruction | null)[] | null;
  neq?: IraTransferInstruction | null;
  nin?: (IraTransferInstruction | null)[] | null;
}

export interface NullableOfPaymentTypeOperationFilterInput {
  eq?: PaymentType | null;
  in?: (PaymentType | null)[] | null;
  neq?: PaymentType | null;
  nin?: (PaymentType | null)[] | null;
}

export interface NullableOfReferralSourceOperationFilterInput {
  eq?: ReferralSource | null;
  in?: (ReferralSource | null)[] | null;
  neq?: ReferralSource | null;
  nin?: (ReferralSource | null)[] | null;
}

export interface NullableOfRolloverFromTypeOperationFilterInput {
  eq?: RolloverFromType | null;
  in?: (RolloverFromType | null)[] | null;
  neq?: RolloverFromType | null;
  nin?: (RolloverFromType | null)[] | null;
}

export interface NullableOfRolloverTypeOperationFilterInput {
  eq?: RolloverType | null;
  in?: (RolloverType | null)[] | null;
  neq?: RolloverType | null;
  nin?: (RolloverType | null)[] | null;
}

export interface NullableOfSpousalConsentOperationFilterInput {
  eq?: SpousalConsent | null;
  in?: (SpousalConsent | null)[] | null;
  neq?: SpousalConsent | null;
  nin?: (SpousalConsent | null)[] | null;
}

export interface NullableOfSuffixOperationFilterInput {
  eq?: Suffix | null;
  in?: (Suffix | null)[] | null;
  neq?: Suffix | null;
  nin?: (Suffix | null)[] | null;
}

export interface NullableOfSystemRoleOperationFilterInput {
  eq?: SystemRole | null;
  in?: (SystemRole | null)[] | null;
  neq?: SystemRole | null;
  nin?: (SystemRole | null)[] | null;
}

export interface NullableOfWalletUpdateTypeOperationFilterInput {
  eq?: WalletUpdateType | null;
  in?: (WalletUpdateType | null)[] | null;
  neq?: WalletUpdateType | null;
  nin?: (WalletUpdateType | null)[] | null;
}

export interface OrderTypeOperationFilterInput {
  eq?: OrderType | null;
  in?: OrderType[] | null;
  neq?: OrderType | null;
  nin?: OrderType[] | null;
}

export interface PaymentTypeOperationFilterInput {
  eq?: PaymentType | null;
  in?: PaymentType[] | null;
  neq?: PaymentType | null;
  nin?: PaymentType[] | null;
}

export interface PriceSpreadFilterInput {
  actualPricePoint?: DecimalOperationFilterInput | null;
  and?: PriceSpreadFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  currency?: StringOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  liquidityProvider?: LiquidityProviderFilterInput | null;
  liquidityProviderId?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: PriceSpreadFilterInput[] | null;
  orderType?: OrderTypeOperationFilterInput | null;
  price?: DecimalOperationFilterInput | null;
  requestedPricePoint?: DecimalOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface PromoFilterInput {
  and?: PromoFilterInput[] | null;
  code?: StringOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  endDate?: DateTimeOperationFilterInput | null;
  fundings?: ListFilterInputTypeOfFundingFilterInput | null;
  id?: IntOperationFilterInput | null;
  incentive?: StringOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  isDeleted?: BooleanOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  or?: PromoFilterInput[] | null;
  startDate?: DateTimeOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface PromoSortInput {
  code?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  endDate?: SortEnumType | null;
  id?: SortEnumType | null;
  incentive?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  isDeleted?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  name?: SortEnumType | null;
  startDate?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface RecurringPurchaseFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  amount?: DecimalOperationFilterInput | null;
  and?: RecurringPurchaseFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  currency?: CurrencyFilterInput | null;
  currencyId?: CurrencyTypeOperationFilterInput | null;
  currencyV2?: CurrencyV2FilterInput | null;
  currencyV2Id?: IntOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: RecurringPurchaseFilterInput[] | null;
  recurringPurchaseHistories?: ListFilterInputTypeOfRecurringPurchaseHistoryFilterInput | null;
  scheduledTrigger?: RecurringPurchaseScheduledTriggerOperationFilterInput | null;
  status?: RecurringPurchaseStatusOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface RecurringPurchaseHistoryFilterInput {
  and?: RecurringPurchaseHistoryFilterInput[] | null;
  attempts?: IntOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  failureReason?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  marketOrders?: ListFilterInputTypeOfMarketOrderFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: RecurringPurchaseHistoryFilterInput[] | null;
  recurringPurchase?: RecurringPurchaseFilterInput | null;
  recurringPurchaseId?: IntOperationFilterInput | null;
  status?: RecurringPurchaseHistoryStatusOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface RecurringPurchaseHistorySortInput {
  attempts?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  failureReason?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  recurringPurchase?: RecurringPurchaseSortInput | null;
  recurringPurchaseId?: SortEnumType | null;
  status?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface RecurringPurchaseHistoryStatusOperationFilterInput {
  eq?: RecurringPurchaseHistoryStatus | null;
  in?: RecurringPurchaseHistoryStatus[] | null;
  neq?: RecurringPurchaseHistoryStatus | null;
  nin?: RecurringPurchaseHistoryStatus[] | null;
}

export interface RecurringPurchaseScheduledTriggerOperationFilterInput {
  eq?: RecurringPurchaseScheduledTrigger | null;
  in?: RecurringPurchaseScheduledTrigger[] | null;
  neq?: RecurringPurchaseScheduledTrigger | null;
  nin?: RecurringPurchaseScheduledTrigger[] | null;
}

export interface RecurringPurchaseSortInput {
  account?: AccountSortInput | null;
  accountId?: SortEnumType | null;
  amount?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  currency?: CurrencySortInput | null;
  currencyId?: SortEnumType | null;
  currencyV2?: CurrencyV2SortInput | null;
  currencyV2Id?: SortEnumType | null;
  device?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  scheduledTrigger?: SortEnumType | null;
  status?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface RecurringPurchaseStatusOperationFilterInput {
  eq?: RecurringPurchaseStatus | null;
  in?: RecurringPurchaseStatus[] | null;
  neq?: RecurringPurchaseStatus | null;
  nin?: RecurringPurchaseStatus[] | null;
}

export interface ReferralFilterInput {
  and?: ReferralFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: ReferralFilterInput[] | null;
  referralSource?: NullableOfReferralSourceOperationFilterInput | null;
  referredArchivedUserId?: IntOperationFilterInput | null;
  referredEmail?: StringOperationFilterInput | null;
  referredUser?: UserFilterInput | null;
  referredUserId?: IntOperationFilterInput | null;
  sentByUser?: UserFilterInput | null;
  sentByUserId?: IntOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface ReferralSortInput {
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  referralSource?: SortEnumType | null;
  referredArchivedUserId?: SortEnumType | null;
  referredEmail?: SortEnumType | null;
  referredUser?: UserSortInput | null;
  referredUserId?: SortEnumType | null;
  sentByUser?: UserSortInput | null;
  sentByUserId?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface RiaInstitutionFilterInput {
  and?: RiaInstitutionFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  institutionCode?: StringOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  or?: RiaInstitutionFilterInput[] | null;
  riaAdvisors?: ListFilterInputTypeOfUserFilterInput | null;
  riaRelationships?: ListFilterInputTypeOfRiaRelationshipFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface RiaInstitutionSortInput {
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  id?: SortEnumType | null;
  institutionCode?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  name?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface RiaRelationshipFilterInput {
  and?: RiaRelationshipFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: RiaRelationshipFilterInput[] | null;
  riaAdvisor?: UserFilterInput | null;
  riaAdvisorId?: IntOperationFilterInput | null;
  riaClientAccount?: AccountFilterInput | null;
  riaClientAccountId?: IntOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface SecurityAnswerFilterInput {
  and?: SecurityAnswerFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: SecurityAnswerFilterInput[] | null;
  securityQuestion?: SecurityQuestionFilterInput | null;
  securityQuestionId?: IntOperationFilterInput | null;
  text?: StringOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface SecurityQuestionFilterInput {
  and?: SecurityQuestionFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  group?: IntOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: SecurityQuestionFilterInput[] | null;
  text?: StringOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface SendDocumentInput {
  id: number;
}

export interface SendEmailInput {
  accountId?: number | null;
  body?: string | null;
  emailAddresses: (EmailAddressInput | null)[];
  fundingId?: number | null;
  subject?: string | null;
  template: EmailTemplateType;
  userId: number;
}

export interface SendTrustPilotInvitationInput {
  accountId: number;
  accountType: AccountType;
  userEmail?: string | null;
  userId: number;
  userName?: string | null;
}

export interface SmsMessageFilterInput {
  accountId?: IntOperationFilterInput | null;
  and?: SmsMessageFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  fundingId?: IntOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  message?: StringOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: SmsMessageFilterInput[] | null;
  phoneNumbers?: ListFilterInputTypeOfSmsPhoneNumberFilterInput | null;
  sentByUser?: CrmUserFilterInput | null;
  sentByUserId?: IntOperationFilterInput | null;
  sentDate?: DateTimeOperationFilterInput | null;
  template?: SmsTemplateTypeOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface SmsPhoneNumberFilterInput {
  and?: SmsPhoneNumberFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: SmsPhoneNumberFilterInput[] | null;
  phoneNumber?: StringOperationFilterInput | null;
  smsMessage?: SmsMessageFilterInput | null;
  smsMessageId?: IntOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface SmsTemplateTypeOperationFilterInput {
  eq?: SmsTemplateType | null;
  in?: SmsTemplateType[] | null;
  neq?: SmsTemplateType | null;
  nin?: SmsTemplateType[] | null;
}

export interface StakingEnrollmentFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  and?: StakingEnrollmentFilterInput[] | null;
  canceledAt?: DateTimeOperationFilterInput | null;
  completedAt?: DateTimeOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: StakingEnrollmentFilterInput[] | null;
  principle?: DecimalOperationFilterInput | null;
  reward?: DecimalOperationFilterInput | null;
  stakedAt?: DateTimeOperationFilterInput | null;
  stakingMovements?: ListFilterInputTypeOfStakingMovementFilterInput | null;
  stakingTerm?: StakingTermFilterInput | null;
  stakingTermId?: IntOperationFilterInput | null;
  status?: StakingEnrollmentStatusOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface StakingEnrollmentSortInput {
  account?: AccountSortInput | null;
  accountId?: SortEnumType | null;
  canceledAt?: SortEnumType | null;
  completedAt?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  principle?: SortEnumType | null;
  reward?: SortEnumType | null;
  stakedAt?: SortEnumType | null;
  stakingTerm?: StakingTermSortInput | null;
  stakingTermId?: SortEnumType | null;
  status?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface StakingEnrollmentStatusOperationFilterInput {
  eq?: StakingEnrollmentStatus | null;
  in?: StakingEnrollmentStatus[] | null;
  neq?: StakingEnrollmentStatus | null;
  nin?: StakingEnrollmentStatus[] | null;
}

export interface StakingMovementDirectionOperationFilterInput {
  eq?: StakingMovementDirection | null;
  in?: StakingMovementDirection[] | null;
  neq?: StakingMovementDirection | null;
  nin?: StakingMovementDirection[] | null;
}

export interface StakingMovementFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  amount?: DecimalOperationFilterInput | null;
  and?: StakingMovementFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  currencyType?: CurrencyTypeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  direction?: StakingMovementDirectionOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: StakingMovementFilterInput[] | null;
  stakingEnrollment?: StakingEnrollmentFilterInput | null;
  stakingEnrollmentId?: IntOperationFilterInput | null;
  stakingTerm?: StakingTermFilterInput | null;
  stakingTermId?: IntOperationFilterInput | null;
  type?: StakingMovementTypeOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface StakingMovementSortInput {
  account?: AccountSortInput | null;
  accountId?: SortEnumType | null;
  amount?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  currencyType?: SortEnumType | null;
  device?: SortEnumType | null;
  direction?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  stakingEnrollment?: StakingEnrollmentSortInput | null;
  stakingEnrollmentId?: SortEnumType | null;
  stakingTerm?: StakingTermSortInput | null;
  stakingTermId?: SortEnumType | null;
  type?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface StakingMovementTypeOperationFilterInput {
  eq?: StakingMovementType | null;
  in?: StakingMovementType[] | null;
  neq?: StakingMovementType | null;
  nin?: StakingMovementType[] | null;
}

export interface StakingSourceOperationFilterInput {
  eq?: StakingSource | null;
  in?: StakingSource[] | null;
  neq?: StakingSource | null;
  nin?: StakingSource[] | null;
}

export interface StakingTermFilterInput {
  adminDays?: IntOperationFilterInput | null;
  and?: StakingTermFilterInput[] | null;
  apy?: DecimalOperationFilterInput | null;
  bondingDays?: IntOperationFilterInput | null;
  completedAt?: DateTimeOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  currencyType?: CurrencyTypeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  durationDays?: IntOperationFilterInput | null;
  endAt?: DateTimeOperationFilterInput | null;
  enrollmentEndAt?: DateTimeOperationFilterInput | null;
  enrollmentStartAt?: DateTimeOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  minimumPrinciple?: DecimalOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: StakingTermFilterInput[] | null;
  source?: StakingSourceOperationFilterInput | null;
  stakingEnrollments?: ListFilterInputTypeOfStakingEnrollmentFilterInput | null;
  stakingMovements?: ListFilterInputTypeOfStakingMovementFilterInput | null;
  startAt?: DateTimeOperationFilterInput | null;
  status?: StakingTermStatusOperationFilterInput | null;
  totalPrinciple?: DecimalOperationFilterInput | null;
  totalRewards?: DecimalOperationFilterInput | null;
  unbondingDays?: IntOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface StakingTermSortInput {
  adminDays?: SortEnumType | null;
  apy?: SortEnumType | null;
  bondingDays?: SortEnumType | null;
  completedAt?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  currencyType?: SortEnumType | null;
  device?: SortEnumType | null;
  durationDays?: SortEnumType | null;
  endAt?: SortEnumType | null;
  enrollmentEndAt?: SortEnumType | null;
  enrollmentStartAt?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  minimumPrinciple?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  source?: SortEnumType | null;
  startAt?: SortEnumType | null;
  status?: SortEnumType | null;
  totalPrinciple?: SortEnumType | null;
  totalRewards?: SortEnumType | null;
  unbondingDays?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface StakingTermStatusOperationFilterInput {
  eq?: StakingTermStatus | null;
  in?: StakingTermStatus[] | null;
  neq?: StakingTermStatus | null;
  nin?: StakingTermStatus[] | null;
}

export interface StringOperationFilterInput {
  and?: StringOperationFilterInput[] | null;
  contains?: string | null;
  endsWith?: string | null;
  eq?: string | null;
  in?: (string | null)[] | null;
  ncontains?: string | null;
  nendsWith?: string | null;
  neq?: string | null;
  nin?: (string | null)[] | null;
  nstartsWith?: string | null;
  or?: StringOperationFilterInput[] | null;
  startsWith?: string | null;
}

export interface SubscriptionBillingChargeFilterInput {
  and?: SubscriptionBillingChargeFilterInput[] | null;
  chargeBeeInvoiceId?: StringOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  invoiceStatus?: StringOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: SubscriptionBillingChargeFilterInput[] | null;
  paymentAmount?: DecimalOperationFilterInput | null;
  subscriptionBilling?: SubscriptionBillingFilterInput | null;
  subscriptionBillingId?: IntOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface SubscriptionBillingFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  amount?: DecimalOperationFilterInput | null;
  and?: SubscriptionBillingFilterInput[] | null;
  billingDate?: DateTimeOperationFilterInput | null;
  billingFrequency?: SubscriptionBillingFrequencyOperationFilterInput | null;
  billingMethod?: SubscriptionBillingMethodOperationFilterInput | null;
  cancellationDate?: DateTimeOperationFilterInput | null;
  chargeBeeSubscriptionId?: StringOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: SubscriptionBillingFilterInput[] | null;
  status?: SubscriptionBillingStatusOperationFilterInput | null;
  subscriptionBillingCharges?: ListFilterInputTypeOfSubscriptionBillingChargeFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface SubscriptionBillingFrequencyOperationFilterInput {
  eq?: SubscriptionBillingFrequency | null;
  in?: SubscriptionBillingFrequency[] | null;
  neq?: SubscriptionBillingFrequency | null;
  nin?: SubscriptionBillingFrequency[] | null;
}

export interface SubscriptionBillingMethodOperationFilterInput {
  eq?: SubscriptionBillingMethod | null;
  in?: SubscriptionBillingMethod[] | null;
  neq?: SubscriptionBillingMethod | null;
  nin?: SubscriptionBillingMethod[] | null;
}

export interface SubscriptionBillingStatusOperationFilterInput {
  eq?: SubscriptionBillingStatus | null;
  in?: SubscriptionBillingStatus[] | null;
  neq?: SubscriptionBillingStatus | null;
  nin?: SubscriptionBillingStatus[] | null;
}

export interface SystemRoleOperationFilterInput {
  eq?: SystemRole | null;
  in?: SystemRole[] | null;
  neq?: SystemRole | null;
  nin?: SystemRole[] | null;
}

export interface ThemeOperationFilterInput {
  eq?: Theme | null;
  in?: Theme[] | null;
  neq?: Theme | null;
  nin?: Theme[] | null;
}

export interface TourTypeOperationFilterInput {
  eq?: TourType | null;
  in?: TourType[] | null;
  neq?: TourType | null;
  nin?: TourType[] | null;
}

export interface TradeFeeCreditFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  and?: TradeFeeCreditFilterInput[] | null;
  availableBalance?: DecimalOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  lockedBalance?: DecimalOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: TradeFeeCreditFilterInput[] | null;
  totalBalance?: DecimalOperationFilterInput | null;
  tradeFeeCreditHistories?: ListFilterInputTypeOfTradeFeeCreditHistoryFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface TradeFeeCreditHistoryFilterInput {
  and?: TradeFeeCreditHistoryFilterInput[] | null;
  availableBalance?: DecimalOperationFilterInput | null;
  availableBalanceDelta?: DecimalOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  lockedBalance?: DecimalOperationFilterInput | null;
  lockedBalanceDelta?: DecimalOperationFilterInput | null;
  marketOrder?: MarketOrderFilterInput | null;
  marketOrderId?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: TradeFeeCreditHistoryFilterInput[] | null;
  reason?: StringOperationFilterInput | null;
  totalBalance?: DecimalOperationFilterInput | null;
  totalBalanceDelta?: DecimalOperationFilterInput | null;
  tradeFeeCredit?: TradeFeeCreditFilterInput | null;
  tradeFeeCreditId?: IntOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
}

export interface TradeFeeCreditSortInput {
  account?: AccountSortInput | null;
  accountId?: SortEnumType | null;
  availableBalance?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  lockedBalance?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  totalBalance?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
}

export interface TrustPilotInvitationFilterInput {
  accountId?: IntOperationFilterInput | null;
  accountType?: AccountTypeOperationFilterInput | null;
  and?: TrustPilotInvitationFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: TrustPilotInvitationFilterInput[] | null;
  referenceId?: StringOperationFilterInput | null;
  sentByUser?: CrmUserFilterInput | null;
  sentByUserId?: IntOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  userEmail?: StringOperationFilterInput | null;
  userId?: IntOperationFilterInput | null;
  userName?: StringOperationFilterInput | null;
}

export interface TrustPilotInvitationSortInput {
  accountId?: SortEnumType | null;
  accountType?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  device?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  referenceId?: SortEnumType | null;
  sentByUser?: CrmUserSortInput | null;
  sentByUserId?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
  userEmail?: SortEnumType | null;
  userId?: SortEnumType | null;
  userName?: SortEnumType | null;
}

export interface UpdateAccountBeneficiaryAddressInput {
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  country?: string | null;
  state?: string | null;
  zipCode?: string | null;
}

export interface UpdateAccountBeneficiaryInput {
  accountHolderRelationship?: string | null;
  addresses?: (UpdateAccountBeneficiaryAddressInput | null)[] | null;
  directive?: BeneficiaryDirective | null;
  email?: string | null;
  firstName?: string | null;
  id: number;
  lastName?: string | null;
  percentage?: any | null;
  phoneNumber?: string | null;
  relationship?: BeneficiaryRelationshipType | null;
  spousalConsent?: SpousalConsent | null;
  spousalConsentFormDocumentId?: number | null;
  type?: BeneficiaryType | null;
}

export interface UpdateAccountInput {
  accountPurpose?: string | null;
  displayName?: string | null;
  id: number;
  needsUpdatedIraAgreement?: boolean | null;
  sourceOfFunds?: string | null;
  stage?: AccountStage | null;
  status?: AccountStatus | null;
  type?: AccountType | null;
  userId?: number | null;
}

export interface UpdateBeneficiaryAddressInput {
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  country?: string | null;
  state?: string | null;
  zipCode?: string | null;
}

export interface UpdateBeneficiaryInput {
  accountHolderRelationship?: string | null;
  addresses?: (UpdateBeneficiaryAddressInput | null)[] | null;
  directive?: BeneficiaryDirective | null;
  email?: string | null;
  firstName?: string | null;
  id: number;
  lastName?: string | null;
  percentage?: any | null;
  phoneNumber?: string | null;
  relationship?: BeneficiaryRelationshipType | null;
  spousalConsent?: SpousalConsent | null;
  spousalConsentFormDocumentId?: number | null;
  type?: BeneficiaryType | null;
}

export interface UpdateCrmUserInput {
  email?: string | null;
  firstName?: string | null;
  id?: number | null;
  lastName?: string | null;
  role?: SystemRole | null;
  status?: CrmUserStatus | null;
}

export interface UpdateCrmUserPasswordInput {
  currentPassword?: string | null;
  password?: string | null;
}

export interface UpdateDocumentInput {
  displayName?: string | null;
  docuSignTemplate?: DocuSignTemplateType | null;
  fileTemplate?: FileTemplateType | null;
  id: number;
  name?: string | null;
  stage?: DocumentStage | null;
  status?: DocumentStatus | null;
  type?: DocumentType | null;
  url?: string | null;
}

export interface UpdateExternalAccountTempInput {
  accountNumber?: string | null;
  accountType?: AccountType | null;
  createdAt?: any | null;
  id: number;
  institutionId?: string | null;
  institutionName?: string | null;
  isPrimary?: boolean | null;
  otherAccountType?: string | null;
  userId?: number | null;
}

export interface UpdateFundingAssetTempInput {
  amount?: any | null;
  currency?: string | null;
  currencyType?: CurrencyType | null;
  id: number;
  originalBalance?: any | null;
  paymentType?: PaymentType | null;
  requestedAmount?: any | null;
  status?: FundingStatus | null;
  transferEntireBalance?: boolean | null;
}

export interface UpdateFundingInput {
  associatedFundingId?: number | null;
  contributionType?: ContributionType | null;
  contributionYear?: number | null;
  createdAt?: any | null;
  direction?: FundingDirection | null;
  distributedToClient?: any | null;
  distributionCode?: string | null;
  federalWithholdings?: any | null;
  federalWithholdingsTransactionId?: string | null;
  fromAccountId?: number | null;
  fromExternalAccount?: UpdateExternalAccountTempInput | null;
  fromExternalAccountId?: number | null;
  fundingAssets?: (UpdateFundingAssetTempInput | null)[] | null;
  id: number;
  microTestAmount?: any | null;
  microTestAmountConfirmed?: boolean | null;
  microTestTransactionId?: string | null;
  paymentType?: PaymentType | null;
  preventInternalAccount?: boolean | null;
  promoCode?: string | null;
  requestedAmount?: any | null;
  rolloverFrom?: RolloverFromType | null;
  rolloverType?: RolloverType | null;
  stage?: FundingStage | null;
  stateWithholdings?: any | null;
  stateWithholdingsTransactionId?: string | null;
  toAccountId?: number | null;
  toExternalAccount?: UpdateExternalAccountTempInput | null;
  toExternalAccountId?: number | null;
  transactionCode?: string | null;
  transactionId?: string | null;
  type?: FundingType | null;
}

export interface UpdateNoteInput {
  id: number;
  status?: NoteStatus | null;
  subject?: string | null;
  text?: string | null;
}

export interface UpdateNotificationSettingInput {
  id: number;
  notificationType?: NotificationType[] | null;
}

export interface UpdateUserInput {
  addresses?: (UserAddressInput | null)[] | null;
  dateOfBirth?: any | null;
  email?: string | null;
  employerName?: string | null;
  employmentStatus?: EmploymentStatus | null;
  enableNotifications?: boolean | null;
  firstName?: string | null;
  fortressIdentityId?: any | null;
  id?: number | null;
  isVip?: boolean | null;
  jobTitle?: string | null;
  kycStatus?: KycStatus | null;
  lastName?: string | null;
  middleName?: string | null;
  phone?: string | null;
  priorNames?: string | null;
  promoCode?: string | null;
  socialSecurityNumber?: string | null;
  status?: UserStatus | null;
  suffix?: Suffix | null;
}

export interface UpdateUserKycInput {
  id?: number | null;
}

export interface UserAddressFilterInput {
  address?: StringOperationFilterInput | null;
  address2?: StringOperationFilterInput | null;
  and?: UserAddressFilterInput[] | null;
  city?: StringOperationFilterInput | null;
  country?: StringOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: UserAddressFilterInput[] | null;
  state?: StringOperationFilterInput | null;
  type?: AddressTypeOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
  zipCode?: StringOperationFilterInput | null;
}

export interface UserAddressInput {
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  country?: string | null;
  state?: string | null;
  type: AddressType;
  zipCode?: string | null;
}

export interface UserFilterInput {
  accounts?: ListFilterInputTypeOfAccountFilterInput | null;
  activatedAt?: DateTimeOperationFilterInput | null;
  activeTours?: ListTourTypeOperationFilterInput | null;
  addresses?: ListFilterInputTypeOfUserAddressFilterInput | null;
  age?: IntOperationFilterInput | null;
  alloyEntityToken?: StringOperationFilterInput | null;
  alloyId?: StringOperationFilterInput | null;
  and?: UserFilterInput[] | null;
  astraUserId?: StringOperationFilterInput | null;
  astraUserIntentId?: StringOperationFilterInput | null;
  astraUserIntentStatus?: StringOperationFilterInput | null;
  beneficiaries?: ListFilterInputTypeOfBeneficiaryFilterInput | null;
  chargeBeeId?: StringOperationFilterInput | null;
  contractSigned?: DateTimeOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  custodianAccounts?: ListFilterInputTypeOfCustodianAccountFilterInput | null;
  dateOfBirth?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  documents?: ListFilterInputTypeOfDocumentFilterInput | null;
  email?: StringOperationFilterInput | null;
  emailVerifiedAt?: DateTimeOperationFilterInput | null;
  employerName?: StringOperationFilterInput | null;
  employmentStatus?: NullableOfEmploymentStatusOperationFilterInput | null;
  enableNotifications?: BooleanOperationFilterInput | null;
  externalAccounts?: ListFilterInputTypeOfExternalAccountFilterInput | null;
  firstName?: StringOperationFilterInput | null;
  fortressIdentityId?: UuidOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  identification?: IdentificationFilterInput | null;
  identificationId?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  isVip?: BooleanOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  jobTitle?: StringOperationFilterInput | null;
  kycEvaluations?: ListFilterInputTypeOfKycEvaluationFilterInput | null;
  kycStatus?: KycStatusOperationFilterInput | null;
  lastIpAddress?: StringOperationFilterInput | null;
  lastLogin?: DateTimeOperationFilterInput | null;
  lastName?: StringOperationFilterInput | null;
  marketingAttribute?: MarketingAttributeFilterInput | null;
  marketingEvents?: ListFilterInputTypeOfMarketingEventFilterInput | null;
  middleName?: StringOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  notificationSettings?: ListFilterInputTypeOfNotificationSettingFilterInput | null;
  or?: UserFilterInput[] | null;
  passwordChangedOn?: DateTimeOperationFilterInput | null;
  phone?: StringOperationFilterInput | null;
  priorNames?: StringOperationFilterInput | null;
  referralCode?: StringOperationFilterInput | null;
  referralCodeCount?: IntOperationFilterInput | null;
  referrals?: ListFilterInputTypeOfReferralFilterInput | null;
  referredReferral?: ReferralFilterInput | null;
  riaInstitution?: RiaInstitutionFilterInput | null;
  riaInstitutionId?: IntOperationFilterInput | null;
  role?: SystemRoleOperationFilterInput | null;
  salesForceAccountId?: StringOperationFilterInput | null;
  salesForceLeadId?: StringOperationFilterInput | null;
  securityAnswers?: ListFilterInputTypeOfSecurityAnswerFilterInput | null;
  securityQuestionsSkipCount?: IntOperationFilterInput | null;
  servicePin?: StringOperationFilterInput | null;
  servicePinGeneratedAt?: DateTimeOperationFilterInput | null;
  socialSecurityNumber?: StringOperationFilterInput | null;
  stage?: UserStageOperationFilterInput | null;
  status?: UserStatusOperationFilterInput | null;
  subscriptionBillings?: ListFilterInputTypeOfSubscriptionBillingFilterInput | null;
  suffix?: NullableOfSuffixOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  userPreferences?: UserPreferencesFilterInput | null;
  userPreferencesId?: IntOperationFilterInput | null;
  userStageHistories?: ListFilterInputTypeOfUserStageHistoryFilterInput | null;
  watchCurrencies?: ListFilterInputTypeOfWatchCurrencyFilterInput | null;
}

export interface UserPreferencesFilterInput {
  accountsOrder?: ListAccountOrderOperationFilterInput | null;
  and?: UserPreferencesFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  defaultAccount?: AccountFilterInput | null;
  defaultAccountId?: IntOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  hideDust?: BooleanOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: UserPreferencesFilterInput[] | null;
  pcaReminderDate?: DateTimeOperationFilterInput | null;
  showSats?: BooleanOperationFilterInput | null;
  theme?: ThemeOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface UserPreferencesSortInput {
  createdAt?: SortEnumType | null;
  defaultAccount?: AccountSortInput | null;
  defaultAccountId?: SortEnumType | null;
  device?: SortEnumType | null;
  hideDust?: SortEnumType | null;
  id?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  iteration?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  pcaReminderDate?: SortEnumType | null;
  showSats?: SortEnumType | null;
  theme?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
  user?: UserSortInput | null;
  userId?: SortEnumType | null;
}

export interface UserSortInput {
  activatedAt?: SortEnumType | null;
  age?: SortEnumType | null;
  alloyEntityToken?: SortEnumType | null;
  alloyId?: SortEnumType | null;
  astraUserId?: SortEnumType | null;
  astraUserIntentId?: SortEnumType | null;
  astraUserIntentStatus?: SortEnumType | null;
  chargeBeeId?: SortEnumType | null;
  contractSigned?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  dateOfBirth?: SortEnumType | null;
  device?: SortEnumType | null;
  email?: SortEnumType | null;
  emailVerifiedAt?: SortEnumType | null;
  employerName?: SortEnumType | null;
  employmentStatus?: SortEnumType | null;
  enableNotifications?: SortEnumType | null;
  firstName?: SortEnumType | null;
  fortressIdentityId?: SortEnumType | null;
  id?: SortEnumType | null;
  identification?: IdentificationSortInput | null;
  identificationId?: SortEnumType | null;
  ipAddress?: SortEnumType | null;
  isVip?: SortEnumType | null;
  iteration?: SortEnumType | null;
  jobTitle?: SortEnumType | null;
  kycStatus?: SortEnumType | null;
  lastIpAddress?: SortEnumType | null;
  lastLogin?: SortEnumType | null;
  lastName?: SortEnumType | null;
  marketingAttribute?: MarketingAttributeSortInput | null;
  middleName?: SortEnumType | null;
  modifiedByRole?: SortEnumType | null;
  modifiedByUserId?: SortEnumType | null;
  passwordChangedOn?: SortEnumType | null;
  phone?: SortEnumType | null;
  priorNames?: SortEnumType | null;
  referralCode?: SortEnumType | null;
  referralCodeCount?: SortEnumType | null;
  referredReferral?: ReferralSortInput | null;
  riaInstitution?: RiaInstitutionSortInput | null;
  riaInstitutionId?: SortEnumType | null;
  role?: SortEnumType | null;
  salesForceAccountId?: SortEnumType | null;
  salesForceLeadId?: SortEnumType | null;
  securityQuestionsSkipCount?: SortEnumType | null;
  servicePin?: SortEnumType | null;
  servicePinGeneratedAt?: SortEnumType | null;
  socialSecurityNumber?: SortEnumType | null;
  stage?: SortEnumType | null;
  status?: SortEnumType | null;
  suffix?: SortEnumType | null;
  updatedAt?: SortEnumType | null;
  userPreferences?: UserPreferencesSortInput | null;
  userPreferencesId?: SortEnumType | null;
}

export interface UserStageHistoryFilterInput {
  and?: UserStageHistoryFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: UserStageHistoryFilterInput[] | null;
  stage?: UserStageOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

export interface UserStageOperationFilterInput {
  eq?: UserStage | null;
  in?: UserStage[] | null;
  neq?: UserStage | null;
  nin?: UserStage[] | null;
}

export interface UserStatusOperationFilterInput {
  eq?: UserStatus | null;
  in?: UserStatus[] | null;
  neq?: UserStatus | null;
  nin?: UserStatus[] | null;
}

export interface UuidOperationFilterInput {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: (any | null)[] | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: (any | null)[] | null;
  nlt?: any | null;
  nlte?: any | null;
}

export interface WalletFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  and?: WalletFilterInput[] | null;
  availableBalance?: DecimalOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  currencyType?: CurrencyTypeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  lockedBalance?: DecimalOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: WalletFilterInput[] | null;
  totalBalance?: DecimalOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  walletHistories?: ListFilterInputTypeOfWalletHistoryFilterInput | null;
}

export interface WalletHistoryFilterInput {
  account?: AccountFilterInput | null;
  accountId?: IntOperationFilterInput | null;
  and?: WalletHistoryFilterInput[] | null;
  availableBalance?: DecimalOperationFilterInput | null;
  availableBalanceDelta?: DecimalOperationFilterInput | null;
  createdAt?: DateTimeOperationFilterInput | null;
  currencyType?: CurrencyTypeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  lockedBalance?: DecimalOperationFilterInput | null;
  lockedBalanceDelta?: DecimalOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: WalletHistoryFilterInput[] | null;
  reason?: StringOperationFilterInput | null;
  totalBalance?: DecimalOperationFilterInput | null;
  totalBalanceDelta?: DecimalOperationFilterInput | null;
  unitPrice?: DecimalOperationFilterInput | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  updatedById?: IntOperationFilterInput | null;
  updatedByType?: NullableOfWalletUpdateTypeOperationFilterInput | null;
  usdEquivalent?: DecimalOperationFilterInput | null;
  wallet?: WalletFilterInput | null;
  walletId?: IntOperationFilterInput | null;
}

export interface WatchCurrencyFilterInput {
  and?: WatchCurrencyFilterInput[] | null;
  createdAt?: DateTimeOperationFilterInput | null;
  currencyId?: CurrencyTypeOperationFilterInput | null;
  device?: StringOperationFilterInput | null;
  id?: IntOperationFilterInput | null;
  ipAddress?: StringOperationFilterInput | null;
  iteration?: IntOperationFilterInput | null;
  modifiedByRole?: StringOperationFilterInput | null;
  modifiedByUserId?: IntOperationFilterInput | null;
  or?: WatchCurrencyFilterInput[] | null;
  updatedAt?: DateTimeOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: IntOperationFilterInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

import {
	useQuery,
	gql,
	MutationTuple,
	useMutation,
	QueryResult,
	QueryHookOptions,
} from '@apollo/client';
import {
	AccountsScreen_GetAccounts,
	AccountsScreen_GetAccountsVariables,
	AccountsScreen_UpdateAccount,
	AccountsScreen_UpdateAccountVariables,
} from '@app/codegen';

export const ACCOUNTS_SCREEN_GET_ACCOUNTS = gql`
	query AccountsScreen_GetAccounts(
		$input: GetAccountsInput
		$where: AccountFilterInput
		$order: [AccountSortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
	) {
		accounts(
			input: $input
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			before: $before
		) {
			nodes {
				id
				accountNumber
				createdAt
				type
				status
				documents {
					id
				}
				documentSentAt
				documentSignedAt
				user {
					fullName
					kycStatus
					phone
				}
				custodianAccount {
					custodianAccountId
				}
			}
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
			totalCount
		}
	}
`;

export const ACCOUNTS_SCREEN_UPDATE_ACCOUNT = gql`
	mutation AccountsScreen_UpdateAccount($input: UpdateAccountInput!) {
		updateAccount(input: $input) {
			success
			errorMessage
		}
	}
`;

export type GetAccountsQueryProps = QueryHookOptions<
	AccountsScreen_GetAccounts,
	AccountsScreen_GetAccountsVariables
>;

export type AccountsQueryResult = QueryResult<
	AccountsScreen_GetAccounts,
	AccountsScreen_GetAccountsVariables
>;

export type AccountUpdateResult = MutationTuple<
	AccountsScreen_UpdateAccount,
	AccountsScreen_UpdateAccountVariables
>;

export function useAccountsScreen_GetAccountsQuery(
	props: GetAccountsQueryProps
): AccountsQueryResult {
	return useQuery<AccountsScreen_GetAccounts, AccountsScreen_GetAccountsVariables>(
		ACCOUNTS_SCREEN_GET_ACCOUNTS,
		{
			...props,
			fetchPolicy: 'no-cache',
		}
	);
}

export function useAccountUpdateMutation(): AccountUpdateResult {
	return useMutation<AccountsScreen_UpdateAccount, AccountsScreen_UpdateAccountVariables>(
		ACCOUNTS_SCREEN_UPDATE_ACCOUNT
	);
}
